import { StripeTokens } from '@/domain/DITokens'
import { CannotEditOrganizationException } from '@/domain/organization/exception/CannotEditOrganizationException'
import { CustomerNotFoundException } from '@/domain/stripe/customer/repository/contract/exception/CustomerNotFoundException'
import type { IQuoteApiClient } from '@/domain/stripe/quote/client/contract/IQuoteApiClient'
import type { IQuoteDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { ILineItemDto } from '@/domain/stripe/quote/client/dto/editing/Dtos'
import { CancelQuoteException } from '@/domain/stripe/quote/exception/CancelQuoteException'
import { CouldNotConvertQuoteException } from '@/domain/stripe/quote/exception/CouldNotConvertQuoteException'
import { CouldNotCreateLineItemLicenseException } from '@/domain/stripe/quote/exception/CouldNotCreateLineItemLicenseException'
import { CouldNotCreateQuoteException } from '@/domain/stripe/quote/exception/CouldNotCreateQuoteException'
import { CouldNotGetQuoteException } from '@/domain/stripe/quote/exception/CouldNotGetQuoteException'
import { CouldNotReadQuoteInfoException } from '@/domain/stripe/quote/exception/CouldNotReadQuoteInfoException'
import { CouldNotSaveQuoteInfoException } from '@/domain/stripe/quote/exception/CouldNotSaveQuoteInfoException'
import { CouldNotUpdateInvoiceException } from '@/domain/stripe/quote/exception/CouldNotUpdateInvoiceException'
import { CouldNotUpdateQuoteException } from '@/domain/stripe/quote/exception/CouldNotUpdateQuoteException'
import { DuplicatedProductApplicationIdException } from '@/domain/stripe/quote/exception/DuplicatedProductApplicationIdException'
import { InvalidQuoteItemException } from '@/domain/stripe/quote/exception/InvalidQuoteItemException'
import { NoLineItemSelectedException } from '@/domain/stripe/quote/exception/NoLineItemSelectedException'
import { NoQuoteFoundForOrganizationException } from '@/domain/stripe/quote/exception/NoQuoteFoundForOrganizationException'
import { ProductApplicationIdNotSetException } from '@/domain/stripe/quote/exception/ProductApplicationIdNotSetException'
import { ProductApplicationIdUnknownException } from '@/domain/stripe/quote/exception/ProductApplicationIdUnknownException'
import { UnknownQuoteErrorException } from '@/domain/stripe/quote/exception/UnknownQuoteErrorException'
import type { IQuoteMapper } from '@/domain/stripe/quote/mapper/contract/IQuoteMapper'
import type { IQuoteWithMessageMapper } from '@/domain/stripe/quote/mapper/contract/IQuoteWithMessageMapper'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import { Quote } from '@/domain/stripe/quote/model/Quote'
import { QuoteWithMessage } from '@/domain/stripe/quote/model/QuoteWithMessage'
import type { IQuoteRepository } from '@/domain/stripe/quote/repository/contract/IQuoteRepository'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'

export type QuoteMemoryCache = { [id: string]: Quote }

const DRAFT_QUOTE_CACHE_KEY = 'draft'

@injectable()
export class QuoteRepository implements IQuoteRepository {

  constructor(
    @inject(StripeTokens.QuoteTokens.apiClient.IQuoteApiClient)
    private readonly quoteApiClient: IQuoteApiClient,
    @inject(StripeTokens.QuoteTokens.mapper.IQuoteMapper)
    private readonly quoteMapper: IQuoteMapper,
    @inject(StripeTokens.QuoteTokens.mapper.IQuoteWithMessageMapper)
    private readonly quoteWithMessageMapper: IQuoteWithMessageMapper,
    @inject(StripeTokens.QuoteTokens.repository.IMemoryCache)
    private readonly cachedQuote?: QuoteMemoryCache,
  ) {
  }

  private static handleException(error: HttpError): void {
    switch (error.message) {
      case 'COULD_NOT_CREATE_QUOTE':
        throw new CouldNotCreateQuoteException()
      case 'COULD_NOT_SAVE_QUOTE_INFO':
        throw new CouldNotSaveQuoteInfoException()
      case 'COULD_NOT_GET_QUOTE':
        throw new CouldNotGetQuoteException()
      case 'NO_QUOTE_FOUND_FOR_ORGANIZATION':
        throw new NoQuoteFoundForOrganizationException()
      case 'COULD_NOT_READ_QUOTE_INFO':
        throw new CouldNotReadQuoteInfoException()
      case 'COULD_NOT_UPDATE_QUOTE':
        throw new CouldNotUpdateQuoteException()
      case 'INVALID_QUOTE_ITEM':
        throw new InvalidQuoteItemException()
      case 'COULD_NOT_CONVERT_QUOTE':
        throw new CouldNotConvertQuoteException()
      case 'CUSTOMER_NOT_FOUND':
        throw new CustomerNotFoundException()
      case 'DUPLICATED_PRODUCT_APPLICATION_ID':
        throw new DuplicatedProductApplicationIdException()
      case 'NO_LINE_ITEM_SELECTED':
        throw new NoLineItemSelectedException()
      case 'PRODUCT_APPLICATION_ID_NOT_SET':
        throw new ProductApplicationIdNotSetException()
      case 'PRODUCT_APPLICATION_ID_UNKNOWN':
        throw new ProductApplicationIdUnknownException()
      case 'COULD_NOT_CREATE_LINE_ITEM_LICENSE':
        throw new CouldNotCreateLineItemLicenseException()
      case 'COULD_NOT_UPDATE_INVOICE':
        throw new CouldNotUpdateInvoiceException()
      case 'CAN_NOT_EDIT_ORGANIZATION':
        throw new CannotEditOrganizationException()
      case 'CANCEL_QUOTE_EXCEPTION':
        throw new CancelQuoteException()
      default:
        throw new UnknownQuoteErrorException()
    }
  }

  public async create(productType: ProductType, copySubscriptionId?: string): Promise<QuoteWithMessage | Quote> {
    const cachedQuote = this.readFromCache(productType)

    if (cachedQuote && !copySubscriptionId) {
      return cachedQuote
    }

    try {
      const quoteDto = await this.quoteApiClient.createQuote(productType, copySubscriptionId)

      const model = this.quoteWithMessageMapper.toModel(quoteDto)
      this.writeToCache(productType, model.quote)
      return model
    } catch (error) {
      if (error instanceof HttpError) {
        QuoteRepository.handleException(error)
      }

      throw error
    }
  }

  public async load(productType: ProductType): Promise<Quote> {

    const cachedQuote = this.readFromCache(productType)
    if (cachedQuote) {
      return cachedQuote
    }

    try {
      const quoteDto: IQuoteDto = await this.quoteApiClient.getQuote(productType)
      return this.mapToModelAndCache(productType, quoteDto)
    } catch (error) {
      if (error instanceof HttpError) {
        QuoteRepository.handleException(error)
      }

      throw error
    }
  }

  public async convert(productType: ProductType, couponCode?: string): Promise<Quote> {
    try {
      const quoteDto = await this.quoteApiClient.convertQuote(productType, couponCode)
      this.clearCache(productType)

      return this.quoteMapper.toModel(quoteDto)
    } catch (error) {
      if (error instanceof HttpError) {
        QuoteRepository.handleException(error)
      }

      throw error
    }
  }

  public async save(productType: ProductType, quote: Quote): Promise<Quote> {

    const lineItemDtos = quote.lineItems.map((lineItem) => {
      const lineItemDto: ILineItemDto = {
        id: lineItem.id,
        quantity: lineItem.quantity,
      }
      return lineItemDto
    })

    try {
      const quoteDto: IQuoteDto = await this.quoteApiClient.editAllQuoteLineItems(productType, lineItemDtos)
      return this.mapToModelAndCache(productType, quoteDto)
    } catch (error) {
      if (error instanceof HttpError) {
        QuoteRepository.handleException(error)
      }

      throw error
    }
  }

  public async cancel(productType: ProductType, copySubscriptionId?: string): Promise<void> {
    try {
      await this.quoteApiClient.cancelQuote(productType, copySubscriptionId)
    } catch (error) {
      if (error instanceof HttpError) {
        QuoteRepository.handleException(error)
      }

      throw error
    }
  }

  private mapToModelAndCache(productType: ProductType, dto: IQuoteDto) {

    const model = this.quoteMapper.toModel(dto)
    this.writeToCache(productType, model)
    return model
  }

  private writeToCache(productType: ProductType, model: Quote) {
    if (this.cachedQuote) {
      this.cachedQuote[this.getQuoteKey(productType)] = model
    }
  }

  public clearCache(productType: ProductType): void {
    if (this.cachedQuote) {
      delete this.cachedQuote[this.getQuoteKey(productType)]
    }
  }

  private readFromCache(productType: ProductType): Quote | null {
    if (this.cachedQuote) {
      return this.cachedQuote[this.getQuoteKey(productType)]
    }
    return null
  }

  private getQuoteKey(productType: ProductType): string {
    return `${productType}-${DRAFT_QUOTE_CACHE_KEY}`
  }
}
