import { couponContainer } from '@/domain/stripe/couponCode/DIContainer'
import { customerContainer } from '@/domain/stripe/customer/DIContainer'
import { invoiceContainer } from '@/domain/stripe/invoice/DIContainer'
import { paymentMethodContainer } from '@/domain/stripe/customer/sub-domains/paymentMethod/DIContainer'
import { quoteContainer } from '@/domain/stripe/quote/DIContainer'
import { Container } from 'inversify'

export function stripeContainer(container: Container): Container {
  customerContainer(container)
  invoiceContainer(container)
  quoteContainer(container)
  paymentMethodContainer(container)
  couponContainer(container)

  return container
}
