import { StripeTokens } from '@/domain/DITokens'
import type { ISubscriptionApiClient } from '@/domain/stripe/customer/sub-domains/subscription/client/contract/ISubscriptionApiClient'
import type { IProductMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/IProductMapper'
import type { IPriceMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/IPriceMapper'
import type { ISubscriptionItemMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/ISubscriptionItemMapper'
import type { ISubscriptionMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/ISubscriptionMapper'
import type { IValidityMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/IValidityMapper'
import { ProductMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/ProductMapper'
import { PriceMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/PriceMapper'
import { SubscriptionItemMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/SubscriptionItemMapper'
import { SubscriptionMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/SubscriptionMapper'
import { ValidityMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/ValidityMapper'
import type { IMySubscriptionsPresenter } from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/IMySubscriptionsPresenter'
import type { IRetrySubscriptionPaymentPresenter } from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/IRetrySubscriptionPaymentPresenter'
import type { ISubscriptionPresenter } from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/ISubscriptionPresenter'
import { MySubscriptionsPresenter } from '@/domain/stripe/customer/sub-domains/subscription/presenter/MySubscriptionsPresenter'
import { RetrySubscriptionPaymentPresenter } from '@/domain/stripe/customer/sub-domains/subscription/presenter/RetrySubscriptionPaymentPresenter'
import { SubscriptionPresenter } from '@/domain/stripe/customer/sub-domains/subscription/presenter/SubscriptionPresenter'
import type { ISubscriptionRepository } from '@/domain/stripe/customer/sub-domains/subscription/repository/contract/ISubscriptionRepository'
import { SubscriptionRepository, SubscriptionsMemoryCache } from '@/domain/stripe/customer/sub-domains/subscription/repository/SubscriptionRepository'
import { GetSubscriptionUseCase } from '@/domain/stripe/customer/sub-domains/subscription/useCase/GetSubscriptionUseCase'
import { ListMySubscriptionsUseCase } from '@/domain/stripe/customer/sub-domains/subscription/useCase/ListMySubscriptionsUseCase'
import { ToggleSubscriptionAutomaticRenewalUseCase } from '@/domain/stripe/customer/sub-domains/subscription/useCase/ToggleSubscriptionAutomaticRenewalUseCase'
import { SubscriptionApiClient } from '@/infrastructure/api/stripe/customer/SubscriptionApiClient'
import { Container } from 'inversify'

export function initializeSubscriptionContainer(container: Container): Container {
  initializeApiClients(container)
  initializeMappers(container)
  initializeRepositories(container)
  initializePresenters(container)
  initializeUseCases(container)

  return container
}

function initializeApiClients(container: Container): void {
  container
    .bind<ISubscriptionApiClient>(StripeTokens.CustomerTokens.SubscriptionsTokens.apiClient.ISubscriptionApiClient)
    .to(SubscriptionApiClient)
}

function initializeMappers(container: Container): void {
  container
    .bind<ISubscriptionMapper>(StripeTokens.CustomerTokens.SubscriptionsTokens.mapper.ISubscriptionMapper)
    .to(SubscriptionMapper)

  container
    .bind<ISubscriptionItemMapper>(StripeTokens.CustomerTokens.SubscriptionsTokens.mapper.ISubscriptionItemMapper)
    .to(SubscriptionItemMapper)

  container
    .bind<IProductMapper>(StripeTokens.CustomerTokens.SubscriptionsTokens.mapper.IProductMapper)
    .to(ProductMapper)

  container
    .bind<IPriceMapper>(StripeTokens.CustomerTokens.SubscriptionsTokens.mapper.IProductPriceMapper)
    .to(PriceMapper)

  container
    .bind<IValidityMapper>(StripeTokens.CustomerTokens.SubscriptionsTokens.mapper.IValidityMapper)
    .to(ValidityMapper)
}

function initializeRepositories(container: Container) {
  container
    .bind<SubscriptionsMemoryCache>(StripeTokens.CustomerTokens.SubscriptionsTokens.repository.IMemoryCache)
    .toConstantValue({})

  container
    .bind<ISubscriptionRepository>(StripeTokens.CustomerTokens.SubscriptionsTokens.repository.ISubscriptionRepository)
    .to(SubscriptionRepository)
}

function initializePresenters(container: Container) {
  container
    .bind<IMySubscriptionsPresenter>(StripeTokens.CustomerTokens.SubscriptionsTokens.presenter.IMySubscriptionsPresenter)
    .to(MySubscriptionsPresenter)

  container
    .bind<ISubscriptionPresenter>(StripeTokens.CustomerTokens.SubscriptionsTokens.presenter.ISubscriptionPresenter)
    .to(SubscriptionPresenter)

  container
    .bind<IRetrySubscriptionPaymentPresenter>(StripeTokens.CustomerTokens.SubscriptionsTokens.presenter.IRetrySubscriptionPaymentPresenter)
    .to(RetrySubscriptionPaymentPresenter)
}

function initializeUseCases(container: Container) {
  container
    .bind<ListMySubscriptionsUseCase>(StripeTokens.CustomerTokens.SubscriptionsTokens.useCase.ListMySubscriptionsUseCase)
    .to(ListMySubscriptionsUseCase)

  container
    .bind<ToggleSubscriptionAutomaticRenewalUseCase>(StripeTokens.CustomerTokens.SubscriptionsTokens.useCase.ToggleSubscriptionAutomaticRenewalUseCase)
    .to(ToggleSubscriptionAutomaticRenewalUseCase)

  container
    .bind<GetSubscriptionUseCase>(StripeTokens.CustomerTokens.SubscriptionsTokens.useCase.GetSubscriptionUseCase)
    .to(GetSubscriptionUseCase)
}
