import { ILoadingIndicatorService } from '@/infrastructure/services/contract/ILoadingIndicatorService'
import { IToastService } from '@/infrastructure/services/contract/IToastService'
import { ServicesTokens } from '@/infrastructure/services/DITokens'
import LoadingIndicatorService from '@/infrastructure/services/LoadingIndicatorService'
import ToastService from '@/infrastructure/services/ToastService'
import { Container } from 'inversify'

export function servicesContainer(container: Container): Container {
  container
    .bind<IToastService>(ServicesTokens.IToastService)
    .to(ToastService)

  container
    .bind<ILoadingIndicatorService>(ServicesTokens.ILoadingIndicatorService)
    .to(LoadingIndicatorService)

  return container
}
