import { Upfront } from '@/domain/stripe/quote/model/Upfront'
import { UpfrontAmountPresentation } from '@/domain/stripe/quote/presentation/UpfrontAmountPresentation'
import type { IUpfrontAmountPresenter } from '@/domain/stripe/quote/presenter/contract/IUpfrontAmountPresenter'
import { injectable } from 'inversify'

@injectable()
export class UpfrontAmountPresenter implements IUpfrontAmountPresenter {
  public present(upfront: Upfront): UpfrontAmountPresentation {
    return new UpfrontAmountPresentation(
      upfront.currency,
      upfront.amount,
    )
  }
}
