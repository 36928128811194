import { StripeTokens } from '@/domain/DITokens'
import {
  CustomerPaymentMethodsException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CustomerPaymentMethodsException'
import { CustomerPaymentMethods } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/CustomerPaymentMethods'
import {
  ICustomerPaymentMethodsPresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/contract/ICustomerPaymentMethodsPresentation'
import type {
  ICustomerPaymentMethodsPresenter,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ICustomerPaymentMethodsPresenter'
import type {
  ICustomerPaymentMethodsRepository,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/ICustomerPaymentMethodsRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class GetCustomerPaymentMethodsUseCase {
  constructor(
    @inject(StripeTokens.PaymentTokens.repository.IPaymentMethodRepository)
    private readonly paymentMethodRepository: ICustomerPaymentMethodsRepository,
    @inject(StripeTokens.PaymentTokens.presenter.ICustomerPaymentMethodsPresenter)
    private readonly paymentMethodPresenter: ICustomerPaymentMethodsPresenter,
  ) {
  }

  async execute(): Promise<ICustomerPaymentMethodsPresentation> {
    let paymentMethod: CustomerPaymentMethods | CustomerPaymentMethodsException

    try {
      paymentMethod = await this.paymentMethodRepository.load()
    } catch (error) {
      if (error instanceof CustomerPaymentMethodsException) {
        paymentMethod = error
      } else {
        throw error
      }
    }

    return this.paymentMethodPresenter.present(paymentMethod)
  }
}
