import { StripeTokens } from '@/domain/DITokens'
import type { IInvoiceApiClient } from '@/domain/stripe/invoice/client/contract/IInvoiceApiClient'
import type { IInvoiceMapper } from '@/domain/stripe/invoice/mapper/contract/IInvoiceMapper'
import { Invoice } from '@/domain/stripe/invoice/model/Invoice'
import { CouldNotGetCustomerInvoicesException } from '@/domain/stripe/invoice/repository/contract/exception/CouldNotGetCustomerInvoicesException'
import { CouldNotReadOrganizationInfoException } from '@/domain/stripe/invoice/repository/contract/exception/CouldNotReadOrganizationInfoException'
import { CustomerNotFoundException } from '@/domain/stripe/invoice/repository/contract/exception/CustomerNotFoundException'
import { NoInvoiceFoundForCustomerException } from '@/domain/stripe/invoice/repository/contract/exception/NoInvoiceFoundForCustomerException'
import type { IInvoiceRepository } from '@/domain/stripe/invoice/repository/contract/IInvoiceRepository'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'

@injectable()
export class InvoiceRepository implements IInvoiceRepository {
  constructor(
    @inject(StripeTokens.InvoiceTokens.apiClient.IInvoiceApiClient)
    private readonly invoiceApiClient: IInvoiceApiClient,
    @inject(StripeTokens.InvoiceTokens.mapper.IInvoiceMapper)
    private readonly invoiceMapper: IInvoiceMapper,
  ) {
  }

  private static handleGetCustomerInvoiceError(error: HttpError): void {
    switch (error.message) {
      case 'CUSTOMER_NOT_FOUND':
        throw new CustomerNotFoundException()
      case 'COULD_NOT_GET_CUSTOMER_INVOICE':
        throw new NoInvoiceFoundForCustomerException()
      case 'COULD_NOT_GET_CUSTOMER_INVOICES':
        throw new CouldNotGetCustomerInvoicesException()
      case 'COULD_NOT_READ_ORGANIZATION_INFO':
      default:
        throw new CouldNotReadOrganizationInfoException
    }
  }

  public async getCustomerInvoice(invoiceId: string, type: ProductType): Promise<Invoice> {
    try {
      const invoiceDto = await this.invoiceApiClient.getCustomerInvoice(invoiceId, type)
      return this.invoiceMapper.toModel(invoiceDto)
    } catch (error) {
      if (error instanceof HttpError) {
        InvoiceRepository.handleGetCustomerInvoiceError(error)
      }

      throw error
    }
  }

  public async getCustomerInvoices(): Promise<Invoice[]> {
    try {
      const invoices = await this.invoiceApiClient.getCustomerInvoices()

      return invoices.map(dto => this.invoiceMapper.toModel(dto))
    } catch (error) {
      if (error instanceof HttpError) {
        InvoiceRepository.handleGetCustomerInvoiceError(error)
      }

      throw error
    }
  }
}
