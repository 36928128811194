import {
  SubscriptionPresentationInput,
} from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/SubscriptionPresentationInput'

export class MySubscriptionsPresentationInput {

  constructor(
    public readonly subscriptions: SubscriptionPresentationInput[],
  ) {
  }
}
