import { LicensingTokens } from '@/domain/DITokens'
import { Activation } from '@/domain/licensing/model/Activation'
import type { ILicenseRepository } from '@/domain/licensing/repository/contract/ILicenseRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class ListLicenseActivationsUseCase {
  constructor(
    @inject(LicensingTokens.repository.ILicenseRepository)
    private readonly licenseRepository: ILicenseRepository,
  ) {
  }

  public async execute(licenseId: string): Promise<Activation[]> {
    return await this.licenseRepository.getLicenseActivations(licenseId)
  }
}
