import type { IPointsDto } from '@/domain/points/accounting/client/response/IBalanceDto'
import type { IPointsMapper } from '@/domain/points/accounting/mapper/contract/IPointsMapper'
import { Points } from '@/domain/points/accounting/model/Points'
import { injectable } from 'inversify'

@injectable()
export class PointsMapper implements IPointsMapper {
  public toModel(dto: IPointsDto): Points {
    return new Points(
      dto.expiring,
      dto.perpetual,
      dto.total,
    )
  }
}
