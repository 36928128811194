<template>
  <div class="md-text-small footer">
    <TermsOfServices />
    <span class="mx-6">|</span>
    <a class='SupportWidget'>{{ $t('shared.support') }}</a>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import TermsOfServices from '@/infrastructure/components/TermsOfServices/TermsOfServices.vue'

@Component({
  components: { TermsOfServices },
})
export default class Footer extends Vue {
}
</script>

<style lang="scss" scoped>
@import "src/infrastructure/scss/colors";

.footer {
  width: 100%;

  ::v-deep > * {
    color: #7A8B97 !important;
    text-decoration: none;
  }
}
</style>
