const apiClient = {
  IDownloadApiClient: Symbol('IDownloadApiClient'),
}

const mapper = {
  IApplicationDownloadMapper: Symbol('IApplicationDownloadMapper'),
}

const repository = {
  IApplicationDownloadRepository: Symbol('IApplicationDownloadRepository'),
}

const presenter = {
  IApplicationDownloadPresenter: Symbol('IApplicationDownloadPresenter'),
}

const useCase = {
  GetLatestVersionDownloadLinkUseCase: Symbol('GetLatestVersionDownloadLinkUseCase'),
  GetAvailableApplicationsDownloadLinkUseCase: Symbol('GetAvailableApplicationsDownloadLinkUseCase'),
}

export default {
  apiClient,
  mapper,
  repository,
  presenter,
  useCase,
}
