import { IClientDto } from '@/domain/points/accounting/client/response/ITransactionDto'
import { IClientMapper } from '@/domain/points/accounting/mapper/contract/IClientMapper'
import { Client } from '@/domain/points/accounting/model/Client'
import { injectable } from 'inversify'

@injectable()
export class ClientMapper implements IClientMapper {
  public toModel(dto: IClientDto): Client {
    return new Client(
      dto.version,
      dto.name,
    )
  }
}
