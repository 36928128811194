import type { IOrganizationApiClient } from '@/domain/organization/client/contract/IOrganizationApiClient'
import type { IOrganizationRequestDto } from '@/domain/organization/client/request/Dto'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { VersionedApiClient } from '@/infrastructure/api/VersionedApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class OrganizationApiClient extends VersionedApiClient implements IOrganizationApiClient {
  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  protected get baseUrl(): string {
    return `${process.env.VUE_APP_BACKEND_SCHEME}://${process.env.VUE_APP_BACKEND_HOST}`
  }

  public async edit(id: string, dto: IOrganizationRequestDto): Promise<void> {
    return await this.httpClient.patch(`${this.baseUrl}/organization/${id}`, dto)
  }
}
