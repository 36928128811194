import { LicensingTokens } from '@/domain/DITokens'
import type { ApplicationAccessMap } from '@/domain/licensing/model/ApplicationsAccess'
import type { ILicenseRepository } from '@/domain/licensing/repository/contract/ILicenseRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class CheckApplicationsAccessUseCase {
  constructor(
    @inject(LicensingTokens.repository.ILicenseRepository)
    private readonly licenceRepository: ILicenseRepository,
  ) {
  }

  public async execute(): Promise<ApplicationAccessMap> {
    return await this.licenceRepository.checkMyAccess()
  }
}
