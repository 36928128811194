import { CardPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/CardPresentation'

export class PaymentMethodPresentation {

  constructor(
    public readonly id: string,
    public readonly card: CardPresentation,
  ) {
  }
}
