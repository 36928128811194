import { PointsPresentation } from '@/domain/points/accounting/presentation/PointsPresentation'

export class BalancePresentation {
  public static readonly EMPTY = new BalancePresentation(
    PointsPresentation.EMPTY,
    '',
  )

  constructor(
    public readonly points: PointsPresentation,
    public readonly organizationId: string,
  ) {
  }
}

export class BalanceExceptionPresentation extends Error {
}
