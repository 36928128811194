import type {
  IMySubscriptionsPresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/IMySubscriptionsPresentation'
import {
  SubscriptionPresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/SubscriptionPresentation'

export class MySubscriptionsPresentation implements IMySubscriptionsPresentation {

  public static readonly EMPTY = new MySubscriptionsPresentation([])

  constructor(
    public readonly subscriptions: SubscriptionPresentation[],
  ) {
  }
}
