export abstract class Item {

  protected constructor(
    public readonly id: string,
    quantity: number,
  ) {
    this._quantity = quantity
  }

  private _quantity: number

  get quantity(): number {
    return this._quantity
  }

  set quantity(value: number) {
    this._quantity = value
  }
}
