/* reflect-metadata import needs to be on top of the file */
import 'reflect-metadata'
import { domainContainer } from '@/domain/DIContainer'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import { sharedContainer } from '@/domain/shared/DIContainer'
import { infrastructureContainer } from '@/infrastructure/DIContainer'
import { Container } from 'inversify'

export function initializeContainer(localizedStringProvider: ILocalizedStringProvider): Container {
  const container = new Container()

  sharedContainer(container, localizedStringProvider)
  domainContainer(container)
  infrastructureContainer(container)

  return container
}
