import { StripeTokens } from '@/domain/DITokens'
import type { ICustomerPaymentMethodsDto } from '@/domain/stripe/customer/sub-domains/paymentMethod/client/dto/Dtos'
import type { ICustomerPaymentMethodsMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/ICustomerPaymentMethodsMapper'
import type { IPaymentMethodMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/IPaymentMethodMapper'
import { CustomerPaymentMethods } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/CustomerPaymentMethods'
import { inject, injectable } from 'inversify'

@injectable()
export class CustomerPaymentMethodsMapper implements ICustomerPaymentMethodsMapper {
  constructor(
    @inject(StripeTokens.PaymentTokens.mapper.IPaymentMethodsMapper)
    private readonly paymentMethodsMapper: IPaymentMethodMapper,
  ) {
  }

  public toModel(paymentMethod: ICustomerPaymentMethodsDto): CustomerPaymentMethods {
    const allPaymentMethods = paymentMethod.payment_methods.map((pm) =>
      this.paymentMethodsMapper.toModel(pm),
    )
    return new CustomerPaymentMethods(
      paymentMethod.customer_id,
      allPaymentMethods,
    )
  }
}
