import type { ILicenseApiClient } from '@/domain/licensing/client/contract/ILicenseApiClient'
import type { IManageLicenseActivationRequestDto } from '@/domain/licensing/client/request/Dtos'
import type { ICheckMyAccessDto, IManageLicenseActivationsResponseDto } from '@/domain/licensing/client/response/Dtos'
import type { IActivationDto, ILicenseDto } from '@/domain/licensing/client/response/Dtos'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { inject, injectable } from 'inversify'

@injectable()
export class LicenseApiClient implements ILicenseApiClient {

  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
  }

  protected get version(): string {
    return 'v1'
  }

  protected get baseUrl(): string {
    const domain = 'licenses'
    return `${process.env.VUE_APP_BACKEND_SCHEME}://${process.env.VUE_APP_BACKEND_HOST}/${domain}/${this.version}`
  }

  protected get cloudBackendUrl(): string {
    return `${process.env.VUE_APP_LP360_CLOUD_BACKEND_URL}/applications`
  }

  public async getAll(): Promise<ILicenseDto[]> {
    return await this.httpClient.get<ILicenseDto[]>(this.baseUrl)
  }

  public async getLicenseActivations(licenseId: string): Promise<IActivationDto[]> {
    return await this.httpClient.get<IActivationDto[]>(`${this.baseUrl}/${licenseId}/activations`)
  }

  public async getLicenseActivationsByUserId(userId: string): Promise<ILicenseDto[]> {
    return await this.httpClient.get<ILicenseDto[]>(`${this.baseUrl}/user/${userId}/activations`)
  }

  public async manageLicenseActivations(licenseId: string, request: IManageLicenseActivationRequestDto[]): Promise<IManageLicenseActivationsResponseDto> {
    return await this.httpClient.patch(`${this.baseUrl}/${licenseId}/activations`, request)
  }

  public async checkMyAccess(): Promise<ICheckMyAccessDto> {
    return { applications: await this.httpClient.get(`${this.cloudBackendUrl}/access`) }
  }
}
