import { IStatusDto } from '@/domain/organization/client/response/Dtos'
import { IStatusMapper } from '@/domain/organization/mapper/contract/IStatusMapper'
import { Status } from '@/domain/organization/model/Status'
import { injectable } from 'inversify'

@injectable()
export class StatusMapper implements IStatusMapper {
  public toModel(dto: IStatusDto): Status {
    return new Status(
      dto.enabled,
    )
  }
}
