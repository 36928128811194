import type { IIsSeatEmpty } from '@/infrastructure/store/modules/contract/shop/IIsSeatEmpty'
import type { ILicensesStore } from '@/infrastructure/store/modules/contract/shop/ILicenses.store'
import Vue from 'vue'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'ShopLicenses',
  namespaced: true,
})
export default class LicensesStore extends VuexModule implements ILicensesStore {

  private _areAnySeatSelected = false

  public get areAnySeatSelected(): boolean {
    return this._areAnySeatSelected
  }

  private _isDataTableEmpty = false

  public get isDataTableEmpty(): boolean {
    return this._isDataTableEmpty
  }

  private _areAnySeatsEmptyList: IIsSeatEmpty[] = []

  public get areAnySeatsEmptyList(): IIsSeatEmpty[] {
    return this._areAnySeatsEmptyList
  }

  private _areAnySeatsEmpty = false

  public get areAnySeatsEmpty(): boolean {
    return this._areAnySeatsEmpty
  }

  private _isSeatSelectionBeingChanged = false

  public get isSeatSelectionBeingChanged(): boolean {
    return this._isSeatSelectionBeingChanged
  }

  @Mutation
  public updateAreAnySeatSelected(value: boolean): void {
    this._areAnySeatSelected = value
  }

  @Mutation
  public updateIsDataTableEmpty(value: boolean): void {
    this._isDataTableEmpty = value
  }

  @Mutation
  public updateAreAnySeatsEmptyList(value: IIsSeatEmpty): void {

    const idIndex = this._areAnySeatsEmptyList.findIndex((element: IIsSeatEmpty) => element.id == value.id)

    if(idIndex > -1) {
      Vue.set(this._areAnySeatsEmptyList[idIndex], 'isSeatEmpty', value.isSeatEmpty)
    } else {
      this._areAnySeatsEmptyList.push(value)
    }
  }

  @Mutation
  public updateAreAnySeatsEmpty(value: boolean): void {
    this._areAnySeatsEmpty = value
  }

  @Mutation
  public updateSeatSelectionBeingChanged(value: boolean) {
    this._isSeatSelectionBeingChanged = value
  }
}
