import DITokens from '@/domain/stripe/customer/sub-domains/subscription/DITokens'
import type { ISubscription } from '@/domain/stripe/customer/sub-domains/subscription/model/contract/ISubscription'
import { SubscriptionException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/SubscriptionException'
import type { IMySubscriptionsPresentation } from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/IMySubscriptionsPresentation'
import type { IMySubscriptionsPresenter } from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/IMySubscriptionsPresenter'
import { MySubscriptionsPresentationInput } from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/MySubscriptionsPresentationInput'
import type { ISubscriptionRepository } from '@/domain/stripe/customer/sub-domains/subscription/repository/contract/ISubscriptionRepository'
import { createSubscriptionPresentationInput } from '@/domain/stripe/customer/sub-domains/subscription/useCase/SubscriptionUseCase'
import { inject, injectable } from 'inversify'

@injectable()
export class ListMySubscriptionsUseCase {

  constructor(
    @inject(DITokens.repository.ISubscriptionRepository)
    private readonly subscriptionRepository: ISubscriptionRepository,
    @inject(DITokens.presenter.IMySubscriptionsPresenter)
    private readonly mySubscriptionsPresenter: IMySubscriptionsPresenter,
  ) {
  }

  async execute(): Promise<IMySubscriptionsPresentation> {

    let subscriptions: ISubscription[]

    try {
      subscriptions = await this.subscriptionRepository.loadAll()
    } catch (e) {
      if (e instanceof SubscriptionException) {
        return this.mySubscriptionsPresenter.present(e)
      }
      throw e
    }
    const subscriptionPresentationInputs = subscriptions
      .map((model) => createSubscriptionPresentationInput(model))

    const mySubscriptionsPresentationInput = new MySubscriptionsPresentationInput(subscriptionPresentationInputs)

    return this.mySubscriptionsPresenter.present(mySubscriptionsPresentationInput)
  }
}
