import { LicensingTokens } from '@/domain/DITokens'
import type { ILicenseApiClient } from '@/domain/licensing/client/contract/ILicenseApiClient'
import type { IManageLicenseActivationRequestDto } from '@/domain/licensing/client/request/Dtos'
import type { IManageLicenseActivationsResponseDto } from '@/domain/licensing/client/response/Dtos'
import { ActivationAlreadyExistException } from '@/domain/licensing/exception/ActivationAlreadyExistException'
import { CannotReleaseException } from '@/domain/licensing/exception/CannotReleaseException'
import { CouldNotRetrieveLicenseLinkedRegistrationException } from '@/domain/licensing/exception/CouldNotRetrieveLicenseLinkedRegistrationException'
import { ForbiddenException } from '@/domain/licensing/exception/ForbiddenException'
import { InvalidApplicationException } from '@/domain/licensing/exception/InvalidApplicationException'
import { InvalidLicenseIdException } from '@/domain/licensing/exception/InvalidLicenseException'
import { InvalidUserException } from '@/domain/licensing/exception/InvalidUserException'
import { LicenseException } from '@/domain/licensing/exception/LicenseException'
import { LicenseMaximumActivationReachedException } from '@/domain/licensing/exception/LicenseMaximumActivationReachedException'
import { LicenseNotFoundException } from '@/domain/licensing/exception/LicenseNotFoundException'
import { UnauthorizedException } from '@/domain/licensing/exception/UnauthorizedException'
import { UserNotFoundException } from '@/domain/licensing/exception/UserNotFoundException'
import type { IActivationMapper } from '@/domain/licensing/mapper/contract/IActivationMapper'
import type { IApplicationAccessMapper } from '@/domain/licensing/mapper/contract/IApplicationAccessMapper'
import type { ILicenseMapper } from '@/domain/licensing/mapper/contract/ILicenseMapper'
import { Activation } from '@/domain/licensing/model/Activation'
import { ApplicationAccessMap } from '@/domain/licensing/model/ApplicationsAccess'
import { License } from '@/domain/licensing/model/License'
import type { ILicenseRepository } from '@/domain/licensing/repository/contract/ILicenseRepository'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'

@injectable()
export class LicenseRepository implements ILicenseRepository {
  constructor(
    @inject(LicensingTokens.apiClient.ILicenseApiClient)
    private readonly licenseApiClient: ILicenseApiClient,
    @inject(LicensingTokens.mapper.ILicenseMapper)
    private readonly licenseMapper: ILicenseMapper,
    @inject(LicensingTokens.mapper.IActivationMapper)
    private readonly activationMapper: IActivationMapper,
    @inject(LicensingTokens.mapper.IApplicationAccessMapper)
    private readonly accessMapper: IApplicationAccessMapper,
  ) {
  }

  private static handleListLicenseActivationsUser(error: HttpError): never {
    switch (error.message) {
      case 'USER_NOT_FOUND':
        throw new UserNotFoundException()
      case 'COULD_NOT_RETRIEVE_LICENSE_LINKED_REGISTRATION':
        throw new CouldNotRetrieveLicenseLinkedRegistrationException()
      case 'FORBIDDEN':
        throw new ForbiddenException()
      default:
        throw new LicenseException()
    }
  }

  private static handleManageLicenseUsage(error: HttpError): never {
    switch (error.message) {
      case 'INVALID_USER':
        throw new InvalidUserException()
      case 'INVALID_APPLICATION':
        throw new InvalidApplicationException()
      case 'INVALID_LICENSE':
        throw new InvalidLicenseIdException()
      case 'LICENSE_NOT_FOUND':
        throw new LicenseNotFoundException()
      case 'LICENSE_MAXIMUM_ACTIVATION_REACHED':
        throw new LicenseMaximumActivationReachedException()
      case 'ACTIVATION_ALREADY_EXISTS':
        throw new ActivationAlreadyExistException()
      case 'CANNOT_RELEASE':
        throw new CannotReleaseException()
      default:
        throw new LicenseException()
    }
  }

  public async getAll(): Promise<License[]> {
    const licenseDtos = await this.licenseApiClient.getAll()

    return licenseDtos.map((licenseDto) =>
      this.licenseMapper.toModel(licenseDto),
    )
  }

  public async getLicenseActivations(licenseId: string): Promise<Activation[]> {
    try {
      const activationDtos = await this.licenseApiClient.getLicenseActivations(licenseId)

      return activationDtos.map((activation) => this.activationMapper.toModel(activation))
    } catch (error) {
      if (error instanceof HttpError) {
        if (error.message === 'INVALID_LICENSE_ID') throw new InvalidLicenseIdException()
      }

      throw error
    }
  }

  public async getLicenseActivationsByUserId(userId: string): Promise<License[]> {
    try {
      const licensesDto = await this.licenseApiClient.getLicenseActivationsByUserId(userId)

      return licensesDto.map(license => this.licenseMapper.toModel(license))
    } catch (error) {
      if (error instanceof HttpError) {
        LicenseRepository.handleListLicenseActivationsUser(error)
      }

      throw error
    }
  }

  public async manageLicenseActivations(licenseId: string, activations: IManageLicenseActivationRequestDto[]): Promise<IManageLicenseActivationsResponseDto> {
    try {
      return await this.licenseApiClient.manageLicenseActivations(licenseId, activations)
    } catch (error) {
      if (error instanceof HttpError) {
        LicenseRepository.handleManageLicenseUsage(error)
      }

      throw error
    }
  }

  public async checkMyAccess(): Promise<ApplicationAccessMap> {
    try {
      const response = await this.licenseApiClient.checkMyAccess()

      return this.accessMapper.toModel(response)
    } catch (error) {
      if (error instanceof HttpError) {
        throw new UnauthorizedException()
      }

      throw error
    }
  }
}
