const apiClient = {
  IQuoteApiClient: Symbol('IQuoteApiClient'),
}

const repository = {
  IQuoteRepository: Symbol('IQuoteRepository'),
  IMemoryCache: Symbol('IMemoryCache'),
}

const mapper = {
  IUpfrontAmountMapper: Symbol('IUpfrontAmountMapper'),
  IUnitAmountMapper: Symbol('IUnitAmountMapper'),
  ITotalAmountMapper: Symbol('ITotalAmountMapper'),
  IRecurringAmountMapper: Symbol('IRecurringAmountMapper'),
  ILineItemMapper: Symbol('ILineItemMapper'),
  IQuoteMapper: Symbol('IQuoteMapper'),
  IQuoteWithMessageMapper: Symbol('IQuoteWithMessageMapper'),
  IProductMapper: Symbol('IProductMapper'),
}

const presenter = {
  ILineItemPresenter: Symbol('ILineItemPresenter'),
  IQuotePresenter: Symbol('IQuotePresenter'),
  IRecurringAmountPresenter: Symbol('IRecurringAmountPresenter'),
  ITotalAmountPresenter: Symbol('ITotalAmountPresenter'),
  IUnitAmountPresenter: Symbol('IUnitAmountPresenter'),
  IUpfrontAmountPresenter: Symbol('IUpfrontAmountPresenter'),
  IProductPresenter: Symbol('IProductPresenter'),
}

const useCase = {
  FindOrCreateQuoteUseCase: Symbol('FindOrCreateQuoteUseCase'),
  EditQuoteLineItemUseCase: Symbol('EditQuoteLineItemUseCase'),
  ConvertQuoteUseCase: Symbol('ConvertQuoteUseCase'),
  ClearQuoteCacheUseCase: Symbol('ClearQuoteCacheUseCase'),
  SelectPointPackUseCase: Symbol('SelectPointPackUseCase'),
  CancelQuoteUseCase: Symbol('CancelQuoteUseCase'),
}

export default {
  apiClient,
  repository,
  mapper,
  presenter,
  useCase,
}
