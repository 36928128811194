import { SharedTokens, StripeTokens } from '@/domain/DITokens'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import { CustomerNotFoundException } from '@/domain/stripe/customer/repository/contract/exception/CustomerNotFoundException'
import { InvoicePresentation } from '@/domain/stripe/invoice/presentation/InvoicePresentation'
import type { IInvoicePresenter } from '@/domain/stripe/invoice/presenter/contract/IInvoicePresenter'
import { Presentation } from '@/domain/stripe/invoice/useCase/Presentation'
import { CouldNotConvertQuoteException } from '@/domain/stripe/quote/exception/CouldNotConvertQuoteException'
import { CouldNotCreateLineItemLicenseException } from '@/domain/stripe/quote/exception/CouldNotCreateLineItemLicenseException'
import { CouldNotCreateQuoteException } from '@/domain/stripe/quote/exception/CouldNotCreateQuoteException'
import { CouldNotGetQuoteException } from '@/domain/stripe/quote/exception/CouldNotGetQuoteException'
import { CouldNotReadQuoteInfoException } from '@/domain/stripe/quote/exception/CouldNotReadQuoteInfoException'
import { CouldNotSaveQuoteInfoException } from '@/domain/stripe/quote/exception/CouldNotSaveQuoteInfoException'
import { CouldNotUpdateInvoiceException } from '@/domain/stripe/quote/exception/CouldNotUpdateInvoiceException'
import { CouldNotUpdateQuoteException } from '@/domain/stripe/quote/exception/CouldNotUpdateQuoteException'
import { DuplicatedProductApplicationIdException } from '@/domain/stripe/quote/exception/DuplicatedProductApplicationIdException'
import { InvalidQuoteItemException } from '@/domain/stripe/quote/exception/InvalidQuoteItemException'
import { NoLineItemSelectedException } from '@/domain/stripe/quote/exception/NoLineItemSelectedException'
import { NoQuoteFoundForOrganizationException } from '@/domain/stripe/quote/exception/NoQuoteFoundForOrganizationException'
import { ProductApplicationIdNotSetException } from '@/domain/stripe/quote/exception/ProductApplicationIdNotSetException'
import { ProductApplicationIdUnknownException } from '@/domain/stripe/quote/exception/ProductApplicationIdUnknownException'
import { QuoteException } from '@/domain/stripe/quote/exception/QuoteException'
import { Quote } from '@/domain/stripe/quote/model/Quote'
import { QuoteWithMessage } from '@/domain/stripe/quote/model/QuoteWithMessage'
import type { IQuotePresentation } from '@/domain/stripe/quote/presentation/contract/IQuotePresentation'
import { QuoteFailurePresentation } from '@/domain/stripe/quote/presentation/QuoteFailurePresentation'
import { QuoteSuccessPresentation } from '@/domain/stripe/quote/presentation/QuoteSuccessPresentation'
import type { ILineItemPresenter } from '@/domain/stripe/quote/presenter/contract/ILineItemPresenter'
import type { IQuotePresenter } from '@/domain/stripe/quote/presenter/contract/IQuotePresenter'
import type { IRecurringAmountPresenter } from '@/domain/stripe/quote/presenter/contract/IRecurringAmountPresenter'
import type { IUpfrontAmountPresenter } from '@/domain/stripe/quote/presenter/contract/IUpfrontAmountPresenter'
import { inject, injectable } from 'inversify'

@injectable()
export class QuotePresenter implements IQuotePresenter {
  constructor(
    @inject(StripeTokens.QuoteTokens.presenter.IUpfrontAmountPresenter)
    private readonly upfrontAmountPresenter: IUpfrontAmountPresenter,
    @inject(StripeTokens.QuoteTokens.presenter.ILineItemPresenter)
    private readonly lineItemPresenter: ILineItemPresenter,
    @inject(StripeTokens.QuoteTokens.presenter.IRecurringAmountPresenter)
    private readonly recurringAmountPresenter: IRecurringAmountPresenter,
    @inject(StripeTokens.InvoiceTokens.presenter.IInvoicePresenter)
    private readonly invoicePresenter: IInvoicePresenter,
    @inject(SharedTokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
  ) {
  }

  public present(outcome: QuoteWithMessage | Quote | QuoteException): IQuotePresentation {
    let message = ''
    if (outcome instanceof QuoteWithMessage) {
      if (outcome.message !== '') {
        message = this.localizedStringProvider.getText(`quote.response.message.${outcome.message}`)
      }
      outcome = outcome.quote
    }

    if (outcome instanceof Quote) {
      let invoicePresentationInput

      if (outcome.invoice != null) {
        invoicePresentationInput = Presentation.convertToPresentationInput(outcome.invoice)
      }
      return new QuoteSuccessPresentation(
        outcome.id,
        outcome.status,
        this.upfrontAmountPresenter.present(outcome.upfront),
        this.lineItemPresenter.present(outcome.lineItems),
        outcome.recurringAmount ? this.recurringAmountPresenter.present(outcome.recurringAmount) : undefined,
        invoicePresentationInput ? this.invoicePresenter.present(invoicePresentationInput) as InvoicePresentation : undefined,
        message,
      )
    } else {
      if (outcome instanceof CouldNotCreateQuoteException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.couldNotCreateQuote'))
      } else if (outcome instanceof CouldNotSaveQuoteInfoException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.couldNotSaveQuoteInfo'))
      } else if (outcome instanceof CouldNotUpdateQuoteException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.couldNotUpdateQuote'))
      } else if (outcome instanceof InvalidQuoteItemException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.invalidQuoteItem'))
      } else if (outcome instanceof CouldNotReadQuoteInfoException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.couldNotReadQuoteInfo'))
      } else if (outcome instanceof CouldNotGetQuoteException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.couldNotGetQuote'))
      } else if (outcome instanceof NoQuoteFoundForOrganizationException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.noQuoteFoundForOrganization'))
      } else if (outcome instanceof CouldNotConvertQuoteException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.couldNotConvertQuote'))
      } else if (outcome instanceof CustomerNotFoundException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.customerNotFound'))
      } else if (outcome instanceof DuplicatedProductApplicationIdException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.duplicatedProductApplicationId'))
      } else if (outcome instanceof NoLineItemSelectedException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.noLineItemSelected'))
      } else if (outcome instanceof ProductApplicationIdNotSetException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.productApplicationIdNotSet'))
      } else if (outcome instanceof ProductApplicationIdUnknownException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.productApplicationIdUnknown'))
      } else if (outcome instanceof CouldNotCreateLineItemLicenseException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.couldNotCreateLineItemLicense'))
      } else if (outcome instanceof CouldNotUpdateInvoiceException) {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.couldNotUpdateInvoice'))
      } else {
        return new QuoteFailurePresentation(this.localizedStringProvider.getText('quote.response.error.unexpectedError'))
      }
    }
  }
}
