import { Point } from '@/domain/points/accounting/client/response/IBalanceDto'

export class Points {
  constructor(
    public readonly expiring: Point,
    public readonly perpetual: Point,
    public readonly total: Point,
  ) {
  }
}
