import type { IStaticsApiClient } from '@/domain/statics/client/contract/IStaticsApiClient'
import type { ICountryDto } from '@/domain/statics/client/dto/Dtos'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { inject, injectable } from 'inversify'

@injectable()
export class StaticsApiClient implements IStaticsApiClient {
  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    private readonly httpClient: IHttpClient,
  ) {
  }

  protected get version(): string {
    return 'v1'
  }

  protected get baseUrl(): string {
    const domain = 'statics'
    return `${process.env.VUE_APP_BACKEND_SCHEME}://${process.env.VUE_APP_BACKEND_HOST}/${domain}/${this.version}`
  }

  public async getAll(): Promise<{ countries: ICountryDto[] }> {
    const requestUrl = `${this.baseUrl}/country`
    return await this.httpClient.get(requestUrl)
  }
}
