import type { ISubscriptionItemDto } from '@/domain/stripe/customer/sub-domains/subscription/client/dto/Dtos'
import SubscriptionDITokens from '@/domain/stripe/customer/sub-domains/subscription/DITokens'
import type { IProductMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/IProductMapper'
import type { ISubscriptionItemMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/ISubscriptionItemMapper'
import { SubscriptionItem } from '@/domain/stripe/customer/sub-domains/subscription/model/SubscriptionItem'
import { inject, injectable } from 'inversify'
import { isEmpty } from 'lodash'

@injectable()
export class SubscriptionItemMapper implements ISubscriptionItemMapper {
  constructor(
    @inject(SubscriptionDITokens.mapper.IProductMapper)
    private readonly productMapper: IProductMapper,
  ) {
  }

  public toModel(dto: ISubscriptionItemDto): SubscriptionItem {
    const product = !isEmpty(dto.product) && dto.product
      ? this.productMapper.toModel(dto.product)
      : undefined

    return new SubscriptionItem(
      dto.id,
      dto.quantity,
      product,
    )
  }

  public toDto(model: SubscriptionItem): ISubscriptionItemDto {
    const product = !isEmpty(model.product) && model.product
      ? this.productMapper.toDto(model.product)
      : undefined

    return {
      id: model.id,
      quantity: model.quantity,
      product,
    }
  }

}
