import {
  SubscriptionStatus,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/SubscriptionStatus'

export class SubscriptionStatusPresentation {
  constructor(
    public readonly value: SubscriptionStatus,
    public readonly label: string,
  ) {
  }
}
