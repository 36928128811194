import { authenticatingContainer } from '@/domain/authenticating/DIContainer'
import { licensingContainer } from '@/domain/licensing/DIContainer'
import { organizationContainer } from '@/domain/organization/DIContainer'
import { pointsAccountingContainer } from '@/domain/points/accounting/DIContainer'
import { staticsContainer } from '@/domain/statics/DIContainer'
import { downloadContainer } from '@/domain/download/DIContainer'
import { stripeContainer } from '@/domain/stripe/DIContainer'
import { userContainer } from '@/domain/user/DIContainer'
import { Container } from 'inversify'

export function domainContainer(container: Container): void {
  authenticatingContainer(container)
  licensingContainer(container)
  organizationContainer(container)
  userContainer(container)
  pointsAccountingContainer(container)
  staticsContainer(container)
  stripeContainer(container)
  downloadContainer(container)
}
