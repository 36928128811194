import { UserTokens } from '@/domain/DITokens'
import { User } from '@/domain/user/model/User'
import type { IUserRepository } from '@/domain/user/repository/contract/IUserRepository'
import { inject, injectable } from 'inversify'

export class EditUserRequest {
  constructor(
    public readonly user: User,
  ) {
  }
}

@injectable()
export class EditUserUseCase {
  constructor(
    @inject(UserTokens.repository.IUserRepository)
    private readonly userRepository: IUserRepository,
  ) {
  }

  public async execute(request: EditUserRequest) {
    return await this.userRepository.edit(request.user)
  }
}
