import { RouteConfig } from 'vue-router'

// Match any file with `.routes.js` or `.routes.ts` extension
const requireModule = require.context(
  '.', true, /\w+.routes.ts$/i,
)
const modules: RouteConfig[][] = []

for (const fileName of requireModule.keys()) {
  const routeModule: RouteConfig[] = requireModule(fileName).routes

  modules.push(routeModule)
}

export default modules
