import type { IUpfrontDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { IUpfrontAmountMapper } from '@/domain/stripe/quote/mapper/contract/IUpfrontAmountMapper'
import { Upfront } from '@/domain/stripe/quote/model/Upfront'
import { injectable } from 'inversify'

@injectable()
export class UpfrontAmountMapper implements IUpfrontAmountMapper {
  public toModel(upfront: IUpfrontDto): Upfront {
    return new Upfront(
      upfront.currency,
      upfront.amount,
    )
  }
}
