export class CardPresentation {

  constructor(
    public readonly brand: string,
    public readonly fingerprint: string,
    public readonly lastFourDigits: string,
    public readonly expirationMonth: number,
    public readonly expirationYear: number,
  ) {
  }
}
