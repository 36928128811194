import { StripeTokens } from '@/domain/DITokens'
import type { ICouponCodeDto } from '@/domain/stripe/couponCode/client/dto/Dtos'
import type { ICouponCodeMapper } from '@/domain/stripe/couponCode/mapper/contract/ICouponCodeMapper'
import type { IUnitAmountMapper } from '@/domain/stripe/couponCode/mapper/contract/IUnitAmountMapper'
import { CouponCode } from '@/domain/stripe/couponCode/model/CouponCode'
import { inject, injectable } from 'inversify'

@injectable()
export class CouponCodeMapper implements ICouponCodeMapper {
  constructor(
    @inject(StripeTokens.CouponTokens.mapper.IUnitAmountMapper)
    private readonly unitAmountMapper: IUnitAmountMapper,
  ) {
  }

  public toModel(couponCode: ICouponCodeDto): CouponCode {
    return new CouponCode(
      couponCode.id,
      couponCode.coupon_name,
      couponCode.code,
      this.unitAmountMapper.toModel(couponCode.amount_off),
      couponCode.coupon_product_name,
      Number(couponCode.percent_off),
      couponCode.is_valid,
    )
  }
}
