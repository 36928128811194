import { RecurringAmount } from '@/domain/stripe/quote/model/RecurringAmount'
import { RecurringAmountPresentation } from '@/domain/stripe/quote/presentation/RecurringAmountPresentation'
import type { IRecurringAmountPresenter } from '@/domain/stripe/quote/presenter/contract/IRecurringAmountPresenter'
import { injectable } from 'inversify'

@injectable()
export class RecurringAmountPresenter implements IRecurringAmountPresenter {
  public present(recurring: RecurringAmount): RecurringAmountPresentation {
    return new RecurringAmountPresentation(
      recurring.currency,
      recurring.amount,
      recurring.interval,
    )
  }
}
