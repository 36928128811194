import type { ICountryDto } from '@/domain/user/client/response/Dtos'
import type { ICountryMapper } from '@/domain/user/mapper/contract/ICountryMapper'
import { Country } from '@/domain/user/model/Country'
import { injectable } from 'inversify'

@injectable()
export class CountryMapper implements ICountryMapper {
  public toModel(dto: ICountryDto): Country {
    return new Country(
      dto.id,
      dto.name,
    )
  }
}
