const unexpectedError = 'Something unexpected happened, please contact the support team.'
const geocuePospacCompatibleLink = 'https://support.geocue.com/updating-local-pospac-uav-installation/?highlight=pospac#:~:text=Users%20Guide.-,Compatibility%20Chart,-TrueView%20EVO/LP360'
const geocueTrueViewLink = 'https://support.geocue.com/true-view-3dis-field-data-checking-methods'
const getMoreInfoAboutLicenseHref = 'https://support.geocue.com/lp360-portal-license-management/'

export default {
  unexpectedError,
  components: {
    'TermsOfServices': 'TermsOfServicesEn',
  },
  app: {
    account: {
      portalTourGuide: 'Portal tour guide',
      goToCloud: 'Go to Cloud',
      logout: 'Log out',
    },
    sidebar: {
      dashboard: 'Dashboard',
      store: {
        title: 'Purchase',
        licenses: 'Licenses',
        points: 'Points',
      },
      billing: 'Billing',
      download: 'Downloads',
      admin: {
        title: 'Admin',
        organization: 'Organization',
        users: 'Users',
        pointsUsage: 'Points usage',
      },
      goToCloud: {
        title: 'LP360 Cloud',
        description: 'Explore the full potential of your data by streaming it instead of downloading it.',
        buttonGoToCloud: 'Go to LP360 Cloud',
        noLicense: 'Requires Cloud Access License',
        openInNew: 'A new browser tab will be open.',
      },
    },
    pointsBalance: {
      title: 'Points',
      available: 'Available points',
      perpetual: 'Perpetual',
      expiring: 'Expiring',
      expiringPoints: '[POINTS] points will expire this month',
      buy: 'Buy points',
    },
    welcomeTo: 'Welcome to',
    slogan: {
      title: 'Put your data to work',
      description: 'Makes easy work of extracting information and deriving products.',
    },
    notFound: 'The page you have requested was not found.',
    goToDashboard: 'Go to dashboard',
  },
  dashboard: {
    title: 'Dashboard',
    noLicenses: 'Seems like you don\'t have any license yet',
    noLicensesRedirect: 'Are you looking for your perpetual license? Click on the following {link} to consult it in the admin section.',
    getALicenseNow: 'Get a license now',
    licenseTable: {
      headers: {
        product: 'Product',
        platform: 'Platform',
        quantity: 'Qty',
        status: 'Status',
        expirationDate: 'Expiration date',
      },
      searchInputFieldLabel: 'Search product',
      myLicenses: 'My licenses',
      licenseType: {
        desktop: 'LP360 Desktop',
        online: 'LP360 Cloud',
        unknown: 'Unknown',
      },
      expirationDate: {
        unlimited: 'Unlimited',
      },
      changePlanButton: 'Change plan',
      status: {
        active: 'Active',
        disabled: 'Disabled',
        pending: 'Pending',
      },
      statusTooltip: {
        active: 'Active since {date}',
        disabled: 'Your license has expired',
        pending: 'Awaiting approval, go to billing for more info',
      },
    },
    limitedAccessDialog: {
      title: 'Limited Access',
      text: 'Only the administrator of your organization can manage the subscriptions and licenses. You will only access the dashboard and download section.',
      button: {
        okLabel: 'OK',
        logoutLabel: 'Logout',
      },
    },
  },
  shop: {
    title: 'Purchase',
    defaultLicenseType: 'Subscription',
    requiresProduct: 'Requires',
    defaultPeriod: 'Yearly',
    licenses: {
      title: 'Licenses',
      headers: {
        product: 'Product',
        licenseType: 'License type',
        period: 'Period',
        quantity: 'Quantity',
        expirationDate: 'Expiration date',
        price: 'Price',
        subTotal: 'Sub total',
      },
      body: {
        seats: 'Seats',
        users: 'Users',
      },
    },
    points: {
      title: 'Points',
      headers: {
        product: 'Product',
        points: 'Points',
        saving: 'Saving',
        price: 'Price',
        subTotal: 'Sub total',
      },
      buyBtn: 'Buy',
    },
    summary: {
      title: 'Summary',
      emptyTable: 'Seems like you don\'t have any product selected.',
      goBackToStore: 'Go back to purchase',
    },
    paymentDetails: {
      title: 'Payment details',
      paymentMethod: {
        title: 'Payment method',
        useMyRegisteredInformationCheckbox: {
          label: 'Use my registered information',
        },
      },
      button: {
        processPayment: 'Process payment',
        checkout: 'Checkout',
        backToBillingDetails: 'Back to billing details',
      },
      termsOfServiceAgreementLabel: {
        partOne: 'I agree to the GeoCue  ',
      },
    },
    invoiceDetails: {
      title: 'Order details',
      orderNumber: 'Order number:',
      orderDate: 'Order date:',
      productSummary: 'Product summary:',
      total: 'Total:',
      discount: 'Discount:',
      subscription: {
        title: 'Subscription',
        startedAt: 'Started at',
        validAt: 'Valid at',
        paymentFailed: 'Your subscription will start when you have successfully completed the payment',
      },
    },
    paymentSummary: {
      title: 'Payment details',
      pointErrorMessage: 'We have encountered an error and could not process your payment. Please retry your purchase.',
      subscriptionErrorMessage: 'We have encountered an error and could not process your payment. Please return to the billing section to retry your payment.',
      button: {
        download: 'Download invoice',
        backToTheStore: 'Back to purchase',
      },
    },
    paymentMethod: {
      title: 'Payment method',
      noCreditCardRegistered: 'No credit card registered.',
      customerHasNoCardPaymentMethod: 'You have not registered any credit card yet.',
      response: {
        error: {
          cardError: 'Your card was declined.',
          couldNotGetCustomerPaymentMethods: 'Stripe API error.',
          couldNotReadOrganizationInfo: 'Failed to read organization metadata.',
          customerNotFound: 'Organization does not have a Stripe customer.',
          couldNotCreateSetupIntent: 'Something unexpected happened, please try again later.',
          unexpectedError,
        },
        success: 'Your payment was successful',
      },
    },
    creditCardElement: {
      dialog: {
        edition: {
          title: 'Edit Payment Method',
        },
        addition: {
          title: 'Add Payment Method',
        },
        cancelButtonLabel: 'Cancel',
        proceedButtonLabel: 'Proceed',
      },
    },
    couponCode: {
      title: 'Add coupon code',
      total: 'Total',
      subTotal: 'Subtotal',
      due: 'Total due',
      coupon: 'Coupon',
      couponCodeInput: {
        label: 'Coupon code',
      },
      buttons: {
        back: 'Back',
        apply: 'Apply',
      },
      response: {
        error: {
          couldNotGetCouponCodeException: 'Could not get your coupon code.',
          couponCodeNotFoundException: 'Coupon code not found.',
          invalidOrUsedCouponCodeException: 'Invalid or used coupon code',
          missMatchedCouponCode: {
            labelPartOne: 'This code is only valid for',
            labelPartTwo: 'in',
          },
          unexpectedError: unexpectedError,
        },
      },
    },
  },
  billing: {
    title: 'Billing',
    overview: {
      title: 'Overview',
      mySubscriptions: {
        title: 'My Subscriptions',
        loading: 'Loading subscriptions...',
        empty: 'Seems like you don\'t have any subscription yet',
        statuses: {
          pendingPayment: 'Pending payment',
          autoRenewal: 'Auto renewal',
          canceled: 'Canceled',
          expired: 'Expired',
        },
        headers: {
          products: 'Products',
          quantity: 'Quantity',
          price: 'Price',
          status: 'Status',
          renewalDate: 'Renewal date',
          actions: 'Actions',
        },
        actions: {
          renewAutomatically: 'Renew automatically',
          retry: {
            buttonLabel: 'Retry payment',
            outcome: {
              cardError: 'The card you have registered with us was declined.',
            },
          },
          cancel: 'Cancel renewal',
          renewNow: 'Renew now',
          none: '-',
        },
        domainError: {},
      },
      button: {
        viewAll: {
          label: 'View all',
        },
      },
    },
    subscriptionPaymentView: {
      title: 'Subscription payment details',
      headers: {
        productSummary: 'Product summary',
        seats: 'Seats',
        period: 'Period',
        subTotal: 'Sub total',
      },
      buttons: {
        backToBilling: 'Back to billing',
      },
    },
    invoiceView: {
      title: 'Billing history',
      total: 'Total',
      couldNotLoadBillingHistory: 'Could not load the billing history',
      actions: {
        back: 'Back',
        downloadInvoice: 'Download invoice',
        viewAll: 'View all',
      },
    },
    orderDetailsView: {
      buttons: {
        backToBilling: 'Back to billing',
      },
    },
    history: {
      title: 'Billing History',
    },
  },
  quote: {
    response: {
      message: {
        'product:deleted': 'One or more archived products were removed.',
      },
      error: {
        couldNotCreateQuote: 'Could not obtain a quote of the LP360 products, please try again later.',
        couldNotUpdateQuote: 'Could not update your quote of the LP360 products, please try again later.',
        couldNotGetQuote: 'Could not get your quote of the LP360 products, please try again later.',
        noQuoteFoundForOrganization: 'No product was selected, please select some products in the store before paying.',
        invalidQuoteItem: unexpectedError,
        couldNotReadQuoteInfo: unexpectedError,
        couldNotSaveQuoteInfo: unexpectedError,
        couldNotConvertQuote: unexpectedError,
        customerNotFound: 'No billing details was found, please enter your billing details information before paying.',
        cannotSellProductAlreadyPurchased: 'You already own one of the products you have selected, review your selection and try again.',
        duplicatedProductApplicationId: unexpectedError,
        noLineItemSelected: 'No product was selected, please select some products in the store before paying.',
        productApplicationIdNotSet: unexpectedError,
        productApplicationIdUnknown: unexpectedError,
        couldNotCreateLineItemLicense: unexpectedError,
        couldNotUpdateInvoice: unexpectedError,
        unexpectedError: unexpectedError,
        cancelQuote: unexpectedError,
      },
    },
  },
  invoice: {
    response: {
      error: {
        customerNotFound: 'Incomplete billing details, please provide your billing information.',
        noInvoicesFoundForCustomer: 'We did not find any invoices in this account.',
      },
    },
  },
  download: {
    title: 'Downloads',
    buttonLabel: 'Download',
    howToInstall: 'How to Install',
    lp360: {
      title: 'LP360',
    },
    applanix: {
      title: 'Applanix',
    },
    applications: {
      lp360Desktop: {
        title: 'LP360 Desktop',
        description: 'Start working on your projects. Download the latest version of the LP360 Desktop now.',
      },
      setupLP360Desktop: {
        title: 'HOW TO INSTALL AND CONFIGURE LP360 DESKTOP LICENSE',
        subtitle: 'Only 4 simple steps are needed to install and configure your desktop app.',
        needMoreInformation: 'Need more information?',
        btnClose: 'Close',
        step1: {
          title: 'INSTALL LP360 DESKTOP',
          description: 'Run the installer of LP360 Drone, then click “Next” to start installing. <br/> </br> <i><b>Notice</b>: Before installing LP360 Drone you should uninstall any versions of TrueView EVO or LP360 currently installed on the machine.</i>',
        },
        step2: {
          title: 'LAUNCH LP360',
          description: 'Start LP360 Drone and open the LP360 Drone License Manager.',
        },
        step3: {
          title: 'LICENSE MANAGER',
          description: `On LP360 Drone License Manager, click on “Credentials”, then select the type. For <b>Subscription</b>, enter your user credentials (email and password); for <b>Perpetual</b>, enter your license string and the password you received by email (this info is also available in Admin section of the LP360 Portal). After entering your credentials click on “Ok” to set it. <a href="${getMoreInfoAboutLicenseHref}" target="_blank">Get more info about licenses</a>`,
        },
        step4: {
          title: 'CHECK OUT LICENSE',
          description: 'After entering your credentials, check out one of the available LP360 Drone licenses. You may also check out any other available addon licenses.',
        },
      },
      lp360DesktopExp: {
        title: 'LP360 Desktop EXP',
        description: 'Download the latest EXP version of LP360 Desktop now to access the latest features and bug fixes.',
        note: '<b>Important note</b>: No fixes are available for defects found in the experimental release until the next experimental release is made.',
      },
      lp360LocalLicenseServer: {
        title: 'LP360 Local License Server',
        description: 'The LP360 licensing system uses a web hosted license server. Some clients, unfortunately, are unable to connect to their hosted license server due to their infrastructure being disconnected from the internet, security policies, or conflicts with security software. For those clients, a locally hosted server solution is available. The management and updating of the local license server are then the responsibility of the user.',
      },
      pospacQC: {
        title: 'POSPac QC',
        description: `Download the POSPac UAV QC module <a href="${geocueTrueViewLink}" target="_blank">compatible</a> with the latest version of LP360 Drone. This utility is used for performing the recommended field QC of the sensor trajectory for TrueView 3DIS and Microdrones payloads in an offline mode. Performing these checks with an internet connection does not require this utility.`,
      },
      pospacUAV: {
        title: 'POSPac UAV',
        description: `Download the POSPac UAV module <a href="${geocuePospacCompatibleLink}" target="_blank">compatible</a> with the latest version of LP360 Drone. This utility is used for performing the UAV sensor trajectory processing for TrueView 3DIS and microdrones payloads with a Classic business model.`,
      },
      pospacMMS: {
        title: 'POSPac MMS',
        description: `Download the POSPac MMS module <a href="${geocuePospacCompatibleLink}" target="_blank">compatible</a> with the latest version of LP360 Drone. This utility is used for performing the sensor trajectory processing for dual purpose (mobile/UAV) TrueView 3DIS with a Classic business model.`,
      },
      pospacSLU: {
        title: 'Applanix SLU',
        description: 'The standalone Applanix Software License Utility (SLU) is only required for customers with systems that have a Classic business model (were provided POSPac desktop licenses), and are installing the Applanix license server on a standalone machine where POSPac is not being installed. The POSPac installers include this module.',
      },
    },
  },
  shared: {
    licenses: {
      button: {
        goToSummary: {
          label: 'Get it now',
        },
      },
    },
    copy: 'Copy',
    link: 'link',
    points: {
      button: {
        resetPassword: 'Send Password Reset Link',
        goBack: 'Go Back',
        resendInvitation: 'Resend Invitation',
        continue: 'Continue',
      },
    },
    summary: {
      button: {
        backToLicenses: {
          label: 'Back to purchase',
        },
        goToBillingDetails: {
          label: 'Pay now',
        },
      },
    },
    billingDetails: {
      title: 'Billing details',
      companyName: 'Company name',
      dialog: {
        edition: {
          title: 'Edit billing details',
        },
      },
      form: {
        companyInput: {
          label: 'Company name*',
          placeholder: 'Please enter company name',
          requiredMessage: 'You must provide a company name.',
        },
        emailInput: {
          label: 'Email*',
          placeholder: 'Please enter your email',
          requiredMessage: 'You must provide an email.',
          invalidMessage: 'You must provide a valid email.',
        },
        addressInput: {
          label: 'Address*',
          placeholder: 'Please enter an address',
          requiredMessage: 'You must provide an address.',
        },
        cityInput: {
          label: 'City*',
          placeholder: 'Please enter a city',
          requiredMessage: 'You must provide a city.',
        },
        stateOrProvinceInput: {
          label: 'State or province*',
          placeholder: 'Please enter a state or province*',
          requiredMessage: 'You must provide a state or province.',
        },
        zipOrPostalCodeInput: {
          label: 'Zip or postal code*',
          placeholder: 'Please enter a zip or postal code*',
          requiredMessage: 'You must provide a zip or postal code.',
        },
        countryInput: {
          label: 'Country*',
          placeholder: 'Please enter a country',
          requiredMessage: 'You must provide a country.',
        },
        contactNumberInput: {
          label: 'Contact number*',
          placeholder: 'Please enter a contact number',
          requiredMessage: 'You must provide a contact number.',
          invalidMessage: 'You must provide a valid contact number.',
        },
        companyWebsiteInput: {
          label: 'Company website',
          placeholder: 'Please enter a company website',
          hint: 'Optional',
          invalidMessage: 'You must provide a valid website.',
        },
      },
      checkbox: {
        useMyRegisteredInformationCheckbox: {
          label: 'Use my registered information',
        },
      },
      button: {
        cancel: {
          label: 'Cancel',
        },
        save: {
          label: 'Save',
        },
        backToSummaryButton: {
          label: 'Back to summary',
        },
        continueButton: {
          label: 'Continue',
        },
      },
      noDetails: 'No billing details.',
      noRegisteredBillingDetails: 'No billing details could be found for your account, please enter your contact info.',
      couldNotSaveBillingDetails: 'Invalid billing details provided, please correct your information and try again.',
      domainError: {},
    },
    paymentMethod: {
      creditCard: 'Credit card',
      eCheck: 'eCheck',
      mailPayment: 'Mail payment',
    },
    language: {
      license: 'license',
      interval: {
        year: 'yearly',
        yearly: 'Yearly',
      },
      subscription: 'subscription',
      seat: 'seat | seats',
      user: 'user | users',
      add: 'Add',
      edit: 'Edit',
      save: 'Save',
      off: 'off',
    },
    domainError: {
      unknownError: unexpectedError,
      serverError: 'Something unexpected happened, please try again later.',
    },
    support: 'Support',
    recaptchaVerificationFailed: 'You have too many failed authentication attempts, please retry later.',
    yes: 'Yes',
    no: 'No',
  },
  cookie: {
    title: 'This website uses cookies',
    message: 'We use cookies to remember information like your account information (fullname, organization, etc.) and also for anonymously monitoring the stability of the site. You can consult our privacy policy for more information on our cookies policy. By clicking accept you agree to the use of these cookies.',
    acceptButtonLabel: 'Accept',
  },
  termsOfServices: {
    title: 'Terms of services',
  },
  points: {
    accounting: {
      error: {
        organizationNotFound: 'Organization could not be found in mdPLUM.',
        couldNotGetAccountId: unexpectedError,
        couldNotGetBalance: unexpectedError,
      },
    },
  },
  admin: {
    title: 'Admin',
    users: {
      title: 'Users',
      search: 'Search user',
      invite: {
        title: 'Invite user',
        btn: 'Invite user',
        resend: 'Email sent',
        dialog: {
          sendBtn: 'Send invitation',
        },
      },
      editUser: 'Edit User',
      saveUser: 'Save',
      cancel: 'Cancel',
      form: {
        firstNameInput: {
          label: 'First name',
          placeholder: 'Please enter a first name',
          requiredMessage: 'You must provide a first name.',
        },
        lastNameInput: {
          label: 'Last name',
          placeholder: 'Please enter a last name',
          requiredMessage: 'You must provide a last name.',
        },
        emailInput: {
          label: 'Email',
          placeholder: 'Please enter an email name',
          requiredMessage: 'You must provide a email.',
        },
        organizationInput: {
          label: 'Organization',
          placeholder: 'Please enter an organization name',
          requiredMessage: 'You must provide an organization name.',
        },
        countryInput: {
          label: 'Country',
          placeholder: 'Please enter a country',
          requiredMessage: 'You must provide a country.',
        },
        storeAdminInput: {
          label: 'Store Admin',
        },
      },
      table: {
        headers: {
          name: 'Name',
          organization: 'Organization',
          email: 'Email',
          permission: 'Permission',
          country: 'Country',
          seats: 'Seats',
          active: 'Active',
          delete: 'Delete',
          edit: 'Edit',
          details: 'Details',
        },
        noUsers: 'There is no users in this organization',
      },
      status: {
        enable: {
          yes: 'Yes',
          no: 'No',
        },
      },
      permission: {
        admin: 'Admin',
        user: 'User',
      },
      errors: {
        invalidUser: 'Invalid user ID',
        invalidParameters: 'Invalid JSON parameters given or missing',
        emailTaken: 'Email has already been taken',
        invalidEmail: 'Email is malformed',
        invalidFirstName: 'First name is not between 2-255 characters',
        invalidLastName: 'Last name is not between 2-255 characters',
        invalidCountryId: 'Country id is not a valid country',
        invalidInvitationContext: 'Invitation context is not valid.',
        InvitationAlreadyConfirmed: 'Invitation already confirmed',
        inactiveUser: 'A user with the specified email already exists, please contact the support team.',
        canNotEditEmail: 'Cannot edit user email because email is already confirmed',
        unauthorized: 'You do not seem to have the permission to perform this action. If you think you do, please logout, log back in and try again.',
        unexpectedError,
      },
      delete: {
        error: 'The user could not be deleted, please try again later.',
        success: 'The user has been deleted successfully.',
      },
      dialog: {
        edition: {
          title: 'Edit User',
          saveButton: 'Save',
        },
        deletion: {
          title: 'Delete User',
          message: 'Are you sure you want to delete "<b class="text-capitalize">{userName}</b>"? Deleting <span class="text-capitalize">{userName}</span> will permanently remove this user from your organization and this action cannot be undone.',
          buttons: {
            cancel: 'Cancel',
            delete: 'Delete',
          },
        },
      },
      invitationsSent: 'Invitation sent successfully',
      license: {
        title: 'Licenses in use',
        table: {
          headers: {
            licenseId: 'License ID',
            products: 'Products',
            device: 'Computer ID',
            expirationDate: 'Expiration date',
            status: 'Status',
            release: 'Release',
          },
        },
        errors: {
          userNotFound: 'User not Found',
          couldNotRetrieveLicenseLinkedRegistration: 'One or more registration linked to one or more licenses could not be retrieved',
          unauthorizedException: 'Unauthorized call',
          forbiddenException: 'You do not have license management permission',
        },
      },
    },
    organization: {
      title: 'Organization',
      license: {
        buyBtn: 'Buy licenses',
        type: {
          online: 'cloud',
          desktop: 'desktop',
        },
        releaseModal: {
          header: 'Reclaim license',
          body: 'Are you sure you want to reclaim "<b class="text-capitalize">{userName}</b>"\'s license to  <b class="text-capitalize">{applicationTitle}</b>? Doing so will free one seat of the license which can be claimed or assigned later.',
          cancelText: 'Cancel',
          confirmText: 'Reclaim',
        },
        cloud: {
          title: 'Cloud licenses',
          table: {
            licenseId: 'License ID',
            product: 'Product',
            expirationDate: 'Expiration date',
            users: 'Users',
            addUser: 'Add user',
            revokeUser: 'Revoke',
            userName: 'User name',
            email: 'Email',
          },
        },
        desktop: {
          title: 'Desktop licenses',
          table: {
            expirationDate: 'Expiration date',
            users: 'Users',
            status: 'Status',
            release: 'Release',
            seats: 'Seats',
            usage: 'Usage',
            computerId: 'Computer ID',
          },
        },
        manageActivations: {
          title: 'Manage {licenseName} License',
          seatsAvailable: '<b>{seats} Seats available </b> in your plan',
          seatsInUse: 'You currently have <b>{seats} seats</b> in use',
          noSeatsAvailable: 'You have {no-seats} available. {add-seats}',
          addSeats: 'Add seats',
          noSeats: 'no seats',
          search: 'Search user',
          table: {
            userName: 'User name',
            email: 'Email',
            action: 'Action',
            loading: 'Loading users...',
          },
          cancel: 'Cancel',
          apply: 'Apply',
        },
      },
      table: {
        headers: {
          name: 'Name',
          address: 'Address',
          city: 'City',
          country: 'Country',
          website: 'Website',
          phone: 'Phone',
          edit: 'Edit',
        },
        metadataInfo: {
          noInfo: 'There is no additional info for this organization',
        },
      },
      dialog: {
        edition: {
          title: 'Edit organization',
        },
      },
      form: {
        companyInput: {
          label: 'Company name*',
          placeholder: 'Please enter company name',
          requiredMessage: 'You must provide a company name.',
        },
        addressInput: {
          label: 'Address*',
          placeholder: 'Please enter an address',
          requiredMessage: 'You must provide an address.',
        },
        cityInput: {
          label: 'City*',
          placeholder: 'Please enter a city',
          requiredMessage: 'You must provide a city.',
        },
        countryInput: {
          label: 'Country*',
          placeholder: 'Please enter a country',
          requiredMessage: 'You must provide a country.',
        },
        contactNumberInput: {
          label: 'Contact number*',
          placeholder: 'Please enter a contact number',
          requiredMessage: 'You must provide a contact number.',
          invalidMessage: 'You must provide a valid contact number.',
        },
        companyWebsiteInput: {
          label: 'Company website',
          placeholder: 'Please enter a company website',
          hint: 'Optional',
          invalidMessage: 'You must provide a valid website.',
        },
        buttons: {
          save: 'Save',
          cancel: 'Cancel',
        },
      },
      errors: {
        nameTaken: 'The name has already been taken',
        invalidPhoneNumber: 'Invalid phone number',
        canNotEditOrganization: 'The organization cannot be edited',
        invalidLicenseId: 'License not found',
      },
    },
    points: {
      title: 'Points usage',
      history: 'Points usage history',
      latest: 'Latest transactions',
      table: {
        description: 'Description',
        date: 'Date',
        user: 'User',
        amount: 'Amount',
        balance: 'Balance',
        id: 'ID',
        dateTime: 'Date/Time',
        platform: 'Platform',
        version: 'Version',
        code: 'Code',
      },
      btnViewAllTransactions: 'View all transactions',
      pointsUsageTable: {
        headers: {
          available: 'Available points',
          perpetual: 'Perpetual',
          expiring: 'Expiring',
          expireOn: 'Expire on',
        },
        buyPoints: 'Buy points',
      },
    },
  },
  tour: {
    btn: {
      next: 'Next',
      previous: 'Previous',
      maybeLater: 'Maybe later',
      letsStart: 'Okay, let\'s start!',
      done: 'Done',
    },
    step1: {
      title: 'Welcome to LP360 Portal!',
      description: 'Before we start, <b>let’s do a quick tour</b> of the LP360 Portal, so you can familiarize yourself with all the features we have available to manage your licenses, users and points.',
    },
    step2: {
      title: 'Stay up to date',
      description: 'Your first view in LP360 Portal is <b>Dashboard section</b>, here you can visualize your organization licenses, their status and expiration date. Also, you have an overview of your available points.',
    },
    step3: {
      title: 'My licenses',
      description: 'Visualize your organization <b>subscription licenses</b>, the quantity of seats, their status and expiration date.',
    },
    step4: {
      title: 'My points',
      description: 'Visualize the <b>available points</b> of your organization in an easy graphic with the specific amount for perpetual or expiring points, so you can keep track of their consumption.',
    },
    step5: {
      title: 'Buy new licenses or points',
      description: 'The <b>Purchase section</b>, is where you can buy additional licenses, seats and points for additional processings or access to pay-as-you-go features, so you don’t have to go anywhere to add new seat for your organization or add points to spend in processings.',
    },
    step6: {
      title: 'Keep all your software up to date',
      description: '<b>Downloads</b>, it\'s the right place to find the latest version of all the software required to have the best experience using all our platforms.',
    },
    step7: {
      title: 'Manage your organization',
      description: 'You can manage your organization, subscription, user, and points in the <b>Admin</b> section. Here you can also consult your billing info and manage your payment information at any time.',
    },
    step8: {
      title: 'Quick access to LP360 Cloud',
      description: 'If you are an LP360 Cloud user, you will be able to use this <b>direct access to the Cloud</b>, so you can easily change the platform whenever you need.',
    },
    step9: {
      title: 'User section always available',
      description: 'Keep your user info and <b>quick access always available</b>. You can consult your info and switch platforms easily when you need it.',
    },
    step10: {
      title: 'Follow your points expenses on live',
      description: 'This <b>point widget</b> will keep you updated on your organization\'s points expenses for a better administration of this resource. Your team will never run out of points during those important processes.',
    },
  },
}
