import { Access } from '@/domain/licensing/model/Access'
import { Link } from '@/domain/licensing/model/Link'

export class Feature {
  constructor(
    public readonly id: string,
    public readonly access: Access,
    public readonly limited: boolean,
    public readonly limitedToSerialNumbers: string,
    public readonly links: Link[],
    public readonly expire: string,
  ) {
  }
}