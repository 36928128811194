import { GetTransactionsRequestDto } from '@/domain/points/accounting/client/request/ITransactionRquestDto'
import { IBalanceDto } from '@/domain/points/accounting/client/response/IBalanceDto'
import { IPointsAccountingApiClient } from '@/domain/points/accounting/client/contract/IPointsAccountingApiClient'
import { ITransactionDto } from '@/domain/points/accounting/client/response/ITransactionDto'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { StripeApiClient } from '@/infrastructure/api/stripe/StripeApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class PointsAccountingApiClient
  extends StripeApiClient
  implements IPointsAccountingApiClient {
  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  protected get baseUrl(): string {
    const domain = 'points'

    return `${super.baseUrl}/${domain}`
  }

  public async getBalance(): Promise<IBalanceDto> {
    const requestUrl = `${this.baseUrl}/account/balance`

    return await this.httpClient.get(requestUrl)
  }

  public async getTransactions(filters?: GetTransactionsRequestDto): Promise<{ data: ITransactionDto[], headers: any }> {
    const requestUrl = new URL(`${this.baseUrl}/transaction`)

    if (filters) {
      if (filters.page) requestUrl.searchParams.append('page', filters.page.toString())
      if (filters.per_page) requestUrl.searchParams.append('per_page', filters.per_page.toString())
      if (filters.hide_pending_transactions) requestUrl.searchParams.append('hide_pending_transactions', filters.hide_pending_transactions.toString())
      if (filters.type) requestUrl.searchParams.append('type', filters.type)
    }

    requestUrl.searchParams.append('hide_zero_amount_transactions', 'true')

    return await this.httpClient.getPaginated(requestUrl.toString())
  }
}
