import type { ISubscriptionApiClient } from '@/domain/stripe/customer/sub-domains/subscription/client/contract/ISubscriptionApiClient'
import type { ISubscriptionDto } from '@/domain/stripe/customer/sub-domains/subscription/client/dto/Dtos'
import DITokens from '@/domain/stripe/customer/sub-domains/subscription/DITokens'
import type { ISubscriptionMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/ISubscriptionMapper'
import type { ISubscription } from '@/domain/stripe/customer/sub-domains/subscription/model/contract/ISubscription'
import { CouldNotGetCustomerSubscriptionsException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/CouldNotGetCustomerSubscriptionsException'
import { CouldNotReadSubscriptionOrganizationInfoException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/CouldNotReadSubscriptionOrganizationInfoException'
import { InvalidSubscriptionException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/InvalidSubscriptionException'
import { InvalidSubscriptionStatusException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/InvalidSubscriptionStatusException'
import { SubscriptionCustomerNotFoundException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/SubscriptionCustomerNotFoundException'
import { UnknownSubscriptionException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/UnknownSubscriptionException'
import { Subscription } from '@/domain/stripe/customer/sub-domains/subscription/model/Subscription'
import type { ISubscriptionRepository } from '@/domain/stripe/customer/sub-domains/subscription/repository/contract/ISubscriptionRepository'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'

export type SubscriptionsMemoryCache = { [key: string]: ISubscription }

@injectable()
export class SubscriptionRepository implements ISubscriptionRepository {

  constructor(
    @inject(DITokens.apiClient.ISubscriptionApiClient)
    private readonly apiClient: ISubscriptionApiClient,
    @inject(DITokens.mapper.ISubscriptionMapper)
    private readonly subscriptionMapper: ISubscriptionMapper,
    @inject(DITokens.repository.IMemoryCache)
    private readonly subscriptions?: SubscriptionsMemoryCache,
  ) {
  }

  private static convertApiClientException(e: unknown): Error {
    if (e instanceof HttpError) {
      switch (e.message) {
        case 'CUSTOMER_NOT_FOUND':
          return new SubscriptionCustomerNotFoundException()
        case 'COULD_NOT_GET_CUSTOMER_SUBSCRIPTIONS':
          return new CouldNotGetCustomerSubscriptionsException()
        case 'COULD_NOT_READ_ORGANIZATION_INFO':
          return new CouldNotReadSubscriptionOrganizationInfoException()
        case 'INVALID_SUBSCRIPTION_STATUS':
          return new InvalidSubscriptionStatusException()
        case 'INVALID_SUBSCRIPTION':
          return new InvalidSubscriptionException()
        default:
          return new UnknownSubscriptionException(e.message)
      }
    }

    return e as Error
  }

  public async loadAll(): Promise<ISubscription[]> {

    try {
      const subscriptionDtos = await this.apiClient.list()

      return subscriptionDtos.map((dto) => this.mapToModelAndCache(dto))

    } catch (e) {
      throw SubscriptionRepository.convertApiClientException(e)
    }
  }

  public async load(id: string): Promise<ISubscription> {

    const subscription = this.readFromCache(id)
    if (subscription) {
      return Promise.resolve(subscription)
    }

    let subscriptionDto: ISubscriptionDto
    try {
      subscriptionDto = await this.apiClient.get(id)
    } catch (e) {
      throw SubscriptionRepository.convertApiClientException(e)
    }

    return this.mapToModelAndCache(subscriptionDto)
  }

  public async saveAutoRenewalChange(subscription: ISubscription): Promise<ISubscription> {

    const dto = this.subscriptionMapper.toDto(subscription)
    let updatedSubscriptionDto: ISubscriptionDto

    try {
      updatedSubscriptionDto = await this.apiClient.toggleAutoRenew(dto)
    } catch (e) {
      throw SubscriptionRepository.convertApiClientException(e)
    }

    return this.mapToModelAndCache(updatedSubscriptionDto)
  }

  private mapToModelAndCache(dto: ISubscriptionDto) {

    const model = this.subscriptionMapper.toModel(dto)
    if (model instanceof Subscription) {
      model.desynchronized = (model) => this.removeFromCache(model)
    }
    this.writeToCache(model)
    return model
  }

  private writeToCache(model: ISubscription) {

    if (this.subscriptions) {
      this.subscriptions[model.id] = model
    }
  }

  private removeFromCache(model: ISubscription) {

    if (this.subscriptions) {
      (<Subscription>this.subscriptions[model.id]).desynchronized = null
      delete this.subscriptions[model.id]
    }
  }

  private readFromCache(id: string): ISubscription | null {
    if (this.subscriptions) {
      return this.subscriptions[id]
    }
    return null
  }
}
