import type { IAuthorizationApiClient } from '@/domain/authenticating/authorization/client/contract/IAuthorizationApiClient'
import type { TokensDto } from '@/domain/authenticating/authorization/client/response/TokensDto'
import { InvalidTokenException } from '@/domain/authenticating/authorization/exception/InvalidTokenException'
import { RefreshTokenException } from '@/domain/authenticating/authorization/exception/RefreshTokenException'
import { TokenExpiredException } from '@/domain/authenticating/authorization/exception/TokenExpiredException'
import type { IAuthorizationRepository } from '@/domain/authenticating/authorization/repository/contract/IAuthorizationRepository'
import { AuthorizationTokens } from '@/domain/authenticating/DITokens'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'

export const ssoCookieName = '__LP360_SSO'

@injectable()
export class AuthorizationRepository implements IAuthorizationRepository {
  constructor(
    @inject(AuthorizationTokens.apiClient.IAuthorizationApiClient)
    private readonly authorizationApiClient: IAuthorizationApiClient,
  ) {
  }

  private static handleTokenException(error: HttpError): void {
    switch (error.message) {
      case 'INVALID_PARAMETERS':
      case 'INVALID_TOKEN':
        throw new InvalidTokenException()
      case 'TOKEN_EXPIRED':
        throw new TokenExpiredException()
    }
  }

  public async refresh(refreshToken: string): Promise<TokensDto> {
    try {
      return await this.authorizationApiClient.refresh(refreshToken)
    } catch (error) {
      if (error instanceof HttpError) {
        AuthorizationRepository.handleTokenException(error)
      }

      throw new RefreshTokenException()
    }
  }

  public async confirmAccess(): Promise<string> {
    try {
      return await this.authorizationApiClient.confirmAccess()
    } catch (error) {
      if (error instanceof HttpError) {
        AuthorizationRepository.handleTokenException(error)
      }

      throw error
    }
  }
}
