import type { IBillingDetailsPresentation } from '@/domain/stripe/customer/presentation/contract/IBillingDetailsPresentation'

export class CouldNotGetOrSaveBillingDetailsPresentation implements IBillingDetailsPresentation {
  constructor(
    public readonly message: string,
  ) {
  }

  public get registeredInfoAvailable(): boolean {
    return false
  }
}
