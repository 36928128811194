import { Action as VuexAction } from 'vuex-module-decorators'
import { ActionDecoratorParams } from 'vuex-module-decorators/dist/types/action'

function action(params?: ActionDecoratorParams): MethodDecorator {
  return VuexAction({
    commit: params?.commit,
    rawError: params?.rawError ?? true,
    ...params,
  })
}

export { action as HandledAction }
