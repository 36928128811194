import { PointsAccountingTokens } from '@/domain/DITokens'
import { ITransactionDto } from '@/domain/points/accounting/client/response/ITransactionDto'
import { IAuthorMapper } from '@/domain/points/accounting/mapper/contract/IAuthorMapper'
import { IClientMapper } from '@/domain/points/accounting/mapper/contract/IClientMapper'
import { ITransactionDetailsMapper } from '@/domain/points/accounting/mapper/contract/ITransactionDetailsMapper'
import { ITransactionMapper } from '@/domain/points/accounting/mapper/contract/ITransactionMapper'
import { Transaction } from '@/domain/points/accounting/model/Transaction'
import { inject, injectable } from 'inversify'
import { PayableService } from '@/domain/points/accounting/model/PayableService'

@injectable()
export class TransactionMapper implements ITransactionMapper {
  constructor(
    @inject(PointsAccountingTokens.mapper.ITransactionDetailsMapper)
    private readonly transactionDetailsMapper: ITransactionDetailsMapper,
    @inject(PointsAccountingTokens.mapper.IAuthorMapper)
    private readonly authorMapper: IAuthorMapper,
    @inject(PointsAccountingTokens.mapper.IClientMapper)
    private readonly clientMapper: IClientMapper,
  ) {
  }

  public toModel(dto: ITransactionDto): Transaction {
    return new Transaction(
      dto.$type,
      dto.id,
      new Date(dto.createdAt),
      this.transactionDetailsMapper.toModel(dto.details),
      this.clientMapper.toModel(dto.client),
      dto.updatedAt ? new Date(dto.updatedAt) : undefined,
      dto.author ? this.authorMapper.toModel(dto.author) : undefined,
      dto.billId ?? undefined,
      dto.service ? (dto.service as PayableService) : undefined,
    )
  }
}
