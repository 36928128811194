import type { Amount } from '@/domain/stripe/quote/model/Amount'

export class TotalAmount implements Amount {
  constructor(
    public readonly currency: string,
    public readonly amount: number,
    public readonly interval: string,
  ) {
  }
}
