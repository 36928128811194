import { UserTokens } from '@/domain/DITokens'
import { User } from '@/domain/user/model/User'
import type { IUserRepository } from '@/domain/user/repository/contract/IUserRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class InviteUserUseCase {
  constructor(
    @inject(UserTokens.repository.IUserRepository)
    private readonly userRepository: IUserRepository,
  ) {
  }

  public async execute(user: User) {
    return await this.userRepository.inviteUser(user)
  }
}
