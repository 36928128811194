import { Country } from '@/domain/organization/model/Country'
import { User } from '@/domain/organization/model/User'

export class Organization {
  public readonly additionalInformation: Record<string, unknown> = {}

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly address: string,
    public readonly city: string,
    public readonly country: Country,
    public readonly website: string,
    public readonly phoneNumber: string,
    public readonly users: User[] = [],
    public readonly metadata: Record<string, unknown> = {},
  ) {
    this.additionalInformation = this.metadata.info ?
      Object.keys(this.metadata.info as Record<string, unknown>).length > 0 ?
        this.metadata.info as Record<string, unknown> : {} : {}
  }
}
