import { LicensingTokens } from '@/domain/DITokens'
import type { ILinkDto } from '@/domain/licensing/client/response/Dtos'
import type { IAccessMapper } from '@/domain/licensing/mapper/contract/IAccessMapper'
import type { ILinkMapper } from '@/domain/licensing/mapper/contract/ILinkMapper'
import { Link } from '@/domain/licensing/model/Link'
import { inject, injectable } from 'inversify'

@injectable()
export class LinkMapper implements ILinkMapper {
  constructor(
    @inject(LicensingTokens.mapper.IAccessMapper)
    private readonly accessMapper: IAccessMapper,
  ) {
  }

  public toModel(dto: ILinkDto): Link {
    return new Link(
      dto.id,
      dto.registration_id,
      dto.linked_serial_numbers,
      this.accessMapper.toModel(dto.access),
      dto.expire,
    )
  }
}
