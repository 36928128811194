import { StripeTokens } from '@/domain/DITokens'
import type { IQuoteWithMessageDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { IQuoteMapper } from '@/domain/stripe/quote/mapper/contract/IQuoteMapper'
import type { IQuoteWithMessageMapper } from '@/domain/stripe/quote/mapper/contract/IQuoteWithMessageMapper'
import { QuoteWithMessage } from '@/domain/stripe/quote/model/QuoteWithMessage'
import { inject, injectable } from 'inversify'

@injectable()
export class QuoteWithMessageMapper implements IQuoteWithMessageMapper {
  constructor(
    @inject(StripeTokens.QuoteTokens.mapper.IQuoteMapper)
    private readonly quoteMapper: IQuoteMapper,
  ) {
  }

  public toModel(quoteWithMessage: IQuoteWithMessageDto): QuoteWithMessage {
    return new QuoteWithMessage(
      quoteWithMessage.message,
      this.quoteMapper.toModel(quoteWithMessage.quote),
    )
  }
}
