import type { IApplicationDto } from '@/domain/licensing/client/response/Dtos'
import type { IApplicationMapper } from '@/domain/licensing/mapper/contract/IApplicationMapper'
import { Application } from '@/domain/licensing/model/Application'
import { injectable } from 'inversify'

@injectable()
export class ApplicationMapper implements IApplicationMapper {
  public toModel(dto: IApplicationDto): Application {
    return new Application(
      dto.id,
      dto.title,
      dto.notes,
      dto.type,
    )
  }
}
