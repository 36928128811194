import { Access } from '@/domain/licensing/model/Access'

export class Link {
  constructor(
    public readonly id: string,
    public readonly registrationId: string,
    public readonly linkedSerialNumbers: string,
    public readonly access: Access,
    public readonly expire: string,
  ) {
  }
}