import { StripeTokens } from '@/domain/DITokens'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import type { IQuoteRepository } from '@/domain/stripe/quote/repository/contract/IQuoteRepository'
import { inject, injectable } from 'inversify'

export class CancelQuoteRequest {
  constructor(
    public readonly productType: ProductType,
    public readonly copySubscriptionId?: string,
  ) {
  }
}

@injectable()
export class CancelQuoteUseCase {
  constructor(
    @inject(StripeTokens.QuoteTokens.repository.IQuoteRepository)
    private readonly quoteRepository: IQuoteRepository,
  ) {
  }

  public async execute(request: CancelQuoteRequest): Promise<void> {
    return await this.quoteRepository.cancel(request.productType, request.copySubscriptionId)
  }
}
