import { StripeTokens } from '@/domain/DITokens'
import type { IInvoiceMapper } from '@/domain/stripe/invoice/mapper/contract/IInvoiceMapper'
import type { ILineItemDto, IQuoteDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { ILineItemMapper } from '@/domain/stripe/quote/mapper/contract/ILineItemMapper'
import type { IQuoteMapper } from '@/domain/stripe/quote/mapper/contract/IQuoteMapper'
import type { IRecurringAmountMapper } from '@/domain/stripe/quote/mapper/contract/IRecurringAmountMapper'
import type { IUpfrontAmountMapper } from '@/domain/stripe/quote/mapper/contract/IUpfrontAmountMapper'
import { Quote } from '@/domain/stripe/quote/model/Quote'
import { inject, injectable } from 'inversify'

@injectable()
export class QuoteMapper implements IQuoteMapper {
  constructor(
    @inject(StripeTokens.QuoteTokens.mapper.IUpfrontAmountMapper)
    private readonly upfrontMapper: IUpfrontAmountMapper,
    @inject(StripeTokens.QuoteTokens.mapper.ILineItemMapper)
    private readonly lineItemMapper: ILineItemMapper,
    @inject(StripeTokens.QuoteTokens.mapper.IRecurringAmountMapper)
    private readonly recurringAmountMapper: IRecurringAmountMapper,
    @inject(StripeTokens.InvoiceTokens.mapper.IInvoiceMapper)
    private readonly invoiceMapper: IInvoiceMapper,
  ) {
  }

  public toModel(quote: IQuoteDto): Quote {
    const quoteLineItems = quote.line_items
      .map((lineItem: ILineItemDto) => this.lineItemMapper.toModel(lineItem))

    return new Quote(
      quote.id,
      quote.status,
      this.upfrontMapper.toModel(quote.upfront),
      quoteLineItems,
      quote.recurring
        ? this.recurringAmountMapper.toModel(quote.recurring)
        : undefined,
      quote.invoice
        ? this.invoiceMapper.toModel(quote.invoice)
        : undefined,
    )
  }
}
