import { UnitAmount } from '@/domain/stripe/couponCode/model/UnitAmount'

export class CouponCode {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly code: string,
    public readonly amountOff: UnitAmount,
    public readonly couponProductName: string,
    public readonly percentOff: number,
    public readonly valid: boolean,
  ) {
  }
}
