import { VersionedApiClient } from '@/infrastructure/api/VersionedApiClient'
import { injectable } from 'inversify'

@injectable()
export abstract class MdCsaApiClient extends VersionedApiClient {
  protected get version(): string {
    return 'v3'
  }

  protected get baseUrl(): string {
    if (process.env.VUE_APP_MD_CSA_URL) {
      return process.env.VUE_APP_MD_CSA_URL
    } throw new Error('MdCSA Url not defined')
  }
}
