import { Card } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/Card'
import { CardPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/CardPresentation'
import type { ICardPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ICardPresenter'
import { injectable } from 'inversify'

@injectable()
export class CardPresenter implements ICardPresenter {
  public present(card: Card): CardPresentation {
    return new CardPresentation(
      card.brand,
      card.fingerprint,
      card.lastFourDigits,
      card.expirationMonth,
      card.expirationYear,
    )
  }
}
