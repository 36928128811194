import { UnitAmountPresentation } from '@/domain/stripe/couponCode/presentation/UnitAmountPresentation'

export class CouponCodePresentation {
  public static readonly EMPTY = new CouponCodePresentation(
    '',
    '',
    '',
    UnitAmountPresentation.EMPTY,
    '',
    0,
    false,
  )

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly code: string,
    public readonly amountOff: UnitAmountPresentation,
    public readonly couponProductName: string,
    public readonly percentOff: number,
    public readonly valid = true,
  ) {
  }
}

export class CouponCodeExceptionPresentation extends Error {
}

