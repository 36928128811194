import { default as SubscriptionsTokens } from '@/domain/stripe/customer/sub-domains/subscription/DITokens'

const apiClient = {
  ICustomerApiClient: Symbol(),
}

const repository = {
  ICustomerRepository: Symbol(),
  IMemoryCache: Symbol(),
}

const mapper = {
  ICustomerMapper: Symbol(),
}

const presenter = {
  IBillingDetailsPresenter: Symbol(),
}

const useCase = {
  GetBillingDetailsUseCase: Symbol(),
  SaveBillingDetailsUseCase: Symbol(),
  ClearCachedCustomerUseCase: Symbol(),
}

export default {
  apiClient,
  repository,
  mapper,
  presenter,
  useCase,
  SubscriptionsTokens,
}
