const apiClient = {
  ILicenseApiClient: Symbol('ILicenseApiClient'),
}

const mapper = {
  IAccessMapper: Symbol('IAccessMapper'),
  IActivationMapper: Symbol('IActivationMapper'),
  IApplicationMapper: Symbol('IApplicationMapper'),
  IDeviceMapper: Symbol('IDeviceMapper'),
  IFeatureMapper: Symbol('IFeatureMapper'),
  ILicenseMapper: Symbol('ILicenseMapper'),
  ILinkMapper: Symbol('ILinkMapper'),
  IOwnerMapper: Symbol('IOwnerMapper'),
  IUserMapper: Symbol('IUserMapper'),
  IApplicationAccessMapper: Symbol('IApplicationAccessMapper'),
}

const repository = {
  ILicenseRepository: Symbol('ILicenseRepository'),
}

const useCase = {
  ListLicensesUseCase: Symbol('ListLicensesUseCase'),
  ListLicenseActivationsUseCase: Symbol('ListLicenseActivationsUseCase'),
  ListLicenseActivationsUserUseCase: Symbol('ListLicenseActivationsUserUseCase'),
  ManageLicenseActivationsUseCase: Symbol('ManageLicenseActivationsUseCase'),
  CheckApplicationsAccessUseCase: Symbol('CheckApplicationsAccessUseCase'),
}

export default {
  apiClient,
  mapper,
  repository,
  useCase,
}
