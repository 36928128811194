import { StripeTokens } from '@/domain/DITokens'
import { PaymentMethod } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/PaymentMethod'
import { PaymentMethodPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/PaymentMethodPresentation'
import type { ICardPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ICardPresenter'
import type {
  IPaymentMethodPresenter,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/IPaymentMethodPresenter'
import { inject, injectable } from 'inversify'

@injectable()
export class PaymentMethodPresenter implements IPaymentMethodPresenter {
  constructor(
    @inject(StripeTokens.PaymentTokens.presenter.ICardPresenter)
    private readonly card: ICardPresenter,
  ) {
  }

  public present(input: PaymentMethod): PaymentMethodPresentation {
    return new PaymentMethodPresentation(
      input.id,
      this.card.present(input.card),
    )
  }
}
