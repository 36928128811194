import { SharedTokens, StripeTokens } from '@/domain/DITokens'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import type { IUnitAmountPresenter } from '@/domain/stripe/couponCode/presenter/contract/IUnitAmountPresenter'
import { CouponPresentation } from '@/domain/stripe/couponCode/presentation/CouponPresentation'
import type { ICouponPresenter } from '@/domain/stripe/invoice/presenter/contract/ICouponPresenter'
import { CouponInput } from '@/domain/stripe/couponCode/presenter/CouponInput'
import { inject, injectable } from 'inversify'

@injectable()
export class CouponPresenter implements ICouponPresenter {
  constructor(
    @inject(StripeTokens.CouponTokens.presenter.IUnitAmountPresenter)
    private readonly unitAmountPresenter: IUnitAmountPresenter,
    @inject(SharedTokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
  ) {
  }

  public present(coupon: CouponInput): CouponPresentation {
    return new CouponPresentation(
      coupon.id,
      coupon.name,
      this.unitAmountPresenter.present(coupon.amountOff),
    )
  }
}
