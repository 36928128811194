import { Price } from '@/domain/stripe/customer/sub-domains/subscription/model/Price'

export class Product {
  constructor(
    public readonly id: string,
    public readonly description: string,
    public readonly name: string,
    public readonly price: Price,
  ) {
  }
}
