import { UserTokens } from '@/domain/DITokens'
import type { IUserRequestDto } from '@/domain/user/client/request/Dtos'
import type { IUserDto } from '@/domain/user/client/response/Dtos'
import type { ICountryMapper } from '@/domain/user/mapper/contract/ICountryMapper'
import type { IOrganizationMapper } from '@/domain/user/mapper/contract/IOrganizationMapper'
import type { IStatusMapper } from '@/domain/user/mapper/contract/IStatusMapper'
import type { IUserMapper } from '@/domain/user/mapper/contract/IUserMapper'
import { User } from '@/domain/user/model/User'
import { inject, injectable } from 'inversify'

@injectable()
export class UserMapper implements IUserMapper {
  constructor(
    @inject(UserTokens.mapper.IStatusMapper)
    private readonly statusMapper: IStatusMapper,
    @inject(UserTokens.mapper.ICountryMapper)
    private readonly countryMapper: ICountryMapper,
    @inject(UserTokens.mapper.IOrganizationMapper)
    private readonly organizationMapper: IOrganizationMapper,
  ) {
  }

  public toModel(dto: IUserDto): User {
    return new User(
      dto.id,
      dto.first_name,
      dto.last_name,
      dto.email,
      this.statusMapper.toModel(dto.status),
      this.countryMapper.toModel(dto.country),
      this.organizationMapper.toModel(dto.organization),
      dto.permissions,
    )
  }

  public toDto(model: User): IUserRequestDto {
    return {
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      countryId: model.country.id,
      metadata: model.metadata,
    }
  }
}
