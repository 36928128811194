import type { IQuoteApiClient } from '@/domain/stripe/quote/client/contract/IQuoteApiClient'
import { IQuoteWithMessageDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { IQuoteDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { ILineItemDto } from '@/domain/stripe/quote/client/dto/editing/Dtos'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { StripeApiClient } from '@/infrastructure/api/stripe/StripeApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class QuoteApiClient extends StripeApiClient implements IQuoteApiClient {

  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  public async createQuote(productType: ProductType, copySubscriptionId?: string): Promise<IQuoteWithMessageDto> {
    const requestUrl = new URL(`${this.baseUrl}/quote`)
    requestUrl.searchParams.append('type', productType)

    return await this.httpClient.post(requestUrl.toString(), {
      copy_subscription_id: copySubscriptionId,
    })
  }

  public async getQuote(productType: ProductType): Promise<IQuoteDto> {
    const requestUrl = new URL(`${this.baseUrl}/quote`)
    requestUrl.searchParams.append('type', productType)

    return await this.httpClient.get(requestUrl.toString())
  }

  public async convertQuote(productType: ProductType, couponCode?: string): Promise<IQuoteDto> {
    const requestUrl = new URL(`${this.baseUrl}/quote/convert`)
    requestUrl.searchParams.append('type', productType)

    return await this.httpClient.post(requestUrl.toString(), { coupon_code: couponCode })
  }

  public async editAllQuoteLineItems(productType: ProductType, lineItems: ILineItemDto[]): Promise<IQuoteDto> {
    const requestUrl = new URL(`${this.baseUrl}/quote/all_line_items`)
    requestUrl.searchParams.append('type', productType)

    return await this.httpClient.put(requestUrl.toString(), lineItems)
  }

  public async cancelQuote(productType: ProductType, copySubscriptionId?: string): Promise<void> {
    const requestUrl = new URL(`${this.baseUrl}/quote/cancel`)
    requestUrl.searchParams.append('type', productType)

    return await this.httpClient.post(requestUrl.toString(), {
      copy_subscription_id: copySubscriptionId,
    })
  }
}
