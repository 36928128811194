import { LicensingTokens } from '@/domain/DITokens'
import type { IFeatureDto } from '@/domain/licensing/client/response/Dtos'
import type { IAccessMapper } from '@/domain/licensing/mapper/contract/IAccessMapper'
import type { IFeatureMapper } from '@/domain/licensing/mapper/contract/IFeatureMapper'
import type { ILinkMapper } from '@/domain/licensing/mapper/contract/ILinkMapper'
import { Feature } from '@/domain/licensing/model/Feature'
import { inject, injectable } from 'inversify'

@injectable()
export class FeatureMapper implements IFeatureMapper {
  constructor(
    @inject(LicensingTokens.mapper.IAccessMapper)
    private readonly accessMapper: IAccessMapper,
    @inject(LicensingTokens.mapper.ILinkMapper)
    private readonly linkMapper: ILinkMapper,
  ) {
  }

  public toModel(dto: IFeatureDto): Feature {
    const links = dto.links.map((link) =>
      this.linkMapper.toModel(link),
    )

    return new Feature(
      dto.id,
      this.accessMapper.toModel(dto.access),
      dto.limited,
      dto.limited_to_serial_numbers,
      links,
      dto.expire,
    )
  }
}
