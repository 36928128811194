import { actionLoadingIndicator } from '@/infrastructure/store/ActionLoadingIndicator'
import modules from '@/infrastructure/store/modules'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store<never>({
  modules,
  plugins: [
    actionLoadingIndicator,
  ],
})
