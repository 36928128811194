import { PointsAccountingTokens } from '@/domain/DITokens'
import type { IPointsAccountingApiClient } from '@/domain/points/accounting/client/contract/IPointsAccountingApiClient'
import { GetTransactionsRequestDto } from '@/domain/points/accounting/client/request/ITransactionRquestDto'
import { CouldNotGetBalanceException } from '@/domain/points/accounting/exception/CouldNotGetBalanceException'
import { CouldNotGetTransactionsException } from '@/domain/points/accounting/exception/CouldNotGetTransactionsException'
import { OrganizationNotFoundException } from '@/domain/points/accounting/exception/OrganizationNotFoundException'
import { UnknownPointsAccountingException } from '@/domain/points/accounting/exception/UnknownPointsAccountingException'
import type { IBalanceMapper } from '@/domain/points/accounting/mapper/contract/IBalanceMapper'
import { ITransactionMapper } from '@/domain/points/accounting/mapper/contract/ITransactionMapper'
import { Balance } from '@/domain/points/accounting/model/Balance'
import { Transaction } from '@/domain/points/accounting/model/Transaction'
import type { IPointsAccountingRepository } from '@/domain/points/accounting/repository/contract/IPointsAccountingRepository'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'

@injectable()
export class PointsAccountingRepository implements IPointsAccountingRepository {
  constructor(
    @inject(PointsAccountingTokens.apiClient.IPointsAccountingApiClient)
    private readonly apiClient: IPointsAccountingApiClient,
    @inject(PointsAccountingTokens.mapper.IBalanceMapper)
    private readonly balanceMapper: IBalanceMapper,
    @inject(PointsAccountingTokens.mapper.ITransactionMapper)
    private readonly transactionMapper: ITransactionMapper,
  ) {
  }

  private static handleGetBalanceException(error: HttpError): void {
    switch (error.message) {
      case 'ORGANIZATION_NOT_FOUND':
        throw new OrganizationNotFoundException()
      case 'COULD_NOT_GET_BALANCE':
        throw new CouldNotGetBalanceException()
      default:
        throw new UnknownPointsAccountingException()
    }
  }

  private static handleGetTransactionsException(error: HttpError): void {
    switch (error.message) {
      case 'ORGANIZATION_NOT_FOUND':
        throw new OrganizationNotFoundException()
      case 'COULD_NOT_GET_TRANSACTIONS':
        throw new CouldNotGetTransactionsException()
      default:
        throw new UnknownPointsAccountingException()
    }
  }

  public async getBalance(): Promise<Balance> {
    try {
      const dto = await this.apiClient.getBalance()

      return this.balanceMapper.toModel(dto)
    } catch (error) {
      if (error instanceof HttpError) {
        PointsAccountingRepository.handleGetBalanceException(error)
      }

      throw error
    }
  }

  public async getTransactions(filters?: GetTransactionsRequestDto): Promise<{ data: Transaction[], headers: any }> {
    try {
      const { data, headers } = await this.apiClient.getTransactions(filters)

      return {
        data: data.map((transaction) => this.transactionMapper.toModel(transaction)),
        headers,
      }
    } catch (error) {
      if (error instanceof HttpError) {
        PointsAccountingRepository.handleGetTransactionsException(error)
      }

      throw error
    }
  }
}
