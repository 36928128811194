import { DownloadType } from '@/domain/download/model/DownloadType'

export class ApplicationDownload {
  constructor(
    public readonly name: string,
    public readonly link: string,
    public readonly version: string,
    public readonly latest: boolean,
    public readonly type: DownloadType,
  ) {

  }
}
