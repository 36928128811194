import type { IAuthorizationRepository } from '@/domain/authenticating/authorization/repository/contract/IAuthorizationRepository'
import { AuthorizationTokens } from '@/domain/authenticating/DITokens'
import { inject, injectable } from 'inversify'

@injectable()
export class ConfirmAccessUseCase {
  constructor(
    @inject(AuthorizationTokens.repository.IAuthorizationRepository)
    private readonly authenticationRepository: IAuthorizationRepository,
  ) {
  }

  async execute(): Promise<string> {
    return await this.authenticationRepository.confirmAccess()
  }
}
