import { StaticsTokens } from '@/domain/DITokens'
import { IStaticsApiClient } from '@/domain/statics/client/contract/IStaticsApiClient'
import { ICountryPresenter } from '@/domain/statics/presenter/contract/ICountryPresenter'
import { CountryPresenter } from '@/domain/statics/presenter/CountryPresenter'
import { IStaticsRepository } from '@/domain/statics/repository/contract/IStaticsRepository'
import { StaticsRepository } from '@/domain/statics/repository/StaticsRepository'
import { GetCountriesUseCase } from '@/domain/statics/useCase/GetCountriesUseCase'
import { StaticsApiClient } from '@/infrastructure/api/statics/StaticsApiClient'
import { Container } from 'inversify'

export function staticsContainer(container: Container): Container {
  initializeHttpClients(container)
  initializeRepositories(container)
  initializePresenters(container)
  initializeUseCases(container)

  return container
}


function initializeHttpClients(container: Container): void {
  container
    .bind<IStaticsApiClient>(StaticsTokens.apiClient)
    .to(StaticsApiClient)
}

function initializeRepositories(container: Container): void {
  container
    .bind<IStaticsRepository>(StaticsTokens.repository)
    .to(StaticsRepository)
}

function initializePresenters(container: Container): void {
  container
    .bind<ICountryPresenter>(StaticsTokens.countryPresenter)
    .to(CountryPresenter)
}

function initializeUseCases(container: Container): void {
  container
    .bind<GetCountriesUseCase>(StaticsTokens.getCountriesUseCase)
    .to(GetCountriesUseCase)
}
