import DITokens from '@/domain/stripe/customer/DITokens'
import type { ICustomerRepository } from '@/domain/stripe/customer/repository/contract/ICustomerRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class ClearCachedCustomerUseCase {
  constructor(
    @inject(DITokens.repository.ICustomerRepository)
    private readonly repository: ICustomerRepository,
  ) {
  }

  public execute(): void {
    this.repository.clearCachedCustomer()
  }
}

