const apiClient = {
  IUserApiClient: Symbol('IUserApiClient'),
}

const mapper = {
  ICountryMapper: Symbol('ICountryMapper'),
  IOrganizationMapper: Symbol('IOrganizationMapper'),
  IStatusMapper: Symbol('IStatusMapper'),
  IUserMapper: Symbol('IUserMapper'),
}

const repository = {
  IUserRepository: Symbol('IUserRepository'),
}

const useCase = {
  GetUserByIdUseCase: Symbol('GetUserByIdUseCase'),
  EditUserUseCase: Symbol('EditUserUseCase'),
  DeleteUserByIdUseCase: Symbol('DeleteUserByIdUseCase'),
  InviteUserUseCase: Symbol('InviteUserUseCase'),
  ResetPasswordUserUseCase: Symbol('ResetPasswordUserUseCase'),
  ResendInvitationUserUseCase: Symbol('ResendInvitationUserUseCase'),
  GetUserProfileUseCase: Symbol('GetUserProfileUseCase'),
}

export default {
  apiClient,
  mapper,
  repository,
  useCase,
}
