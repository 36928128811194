import { IAuthorDto } from '@/domain/points/accounting/client/response/ITransactionDto'
import { IAuthorMapper } from '@/domain/points/accounting/mapper/contract/IAuthorMapper'
import { Author } from '@/domain/points/accounting/model/Author'
import { injectable } from 'inversify'

@injectable()
export class AuthorMapper implements IAuthorMapper {
  public toModel(dto: IAuthorDto): Author {
    return new Author(
      dto.id,
      dto.name,
    )
  }
}
