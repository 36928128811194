import { StripeTokens } from '@/domain/DITokens'
import type { ICustomerApiClient } from '@/domain/stripe/customer/client/contract/ICustomerApiClient'
import type { ICustomerMapper } from '@/domain/stripe/customer/mapper/contract/ICustomerMapper'
import { CustomerMapper } from '@/domain/stripe/customer/mapper/CustomerMapper'
import { BillingDetailsPresenter } from '@/domain/stripe/customer/presenter/BillingDetailsPresenter'
import type { IBillingDetailsPresenter } from '@/domain/stripe/customer/presenter/contract/IBillingDetailsPresenter'
import type { ICustomerRepository } from '@/domain/stripe/customer/repository/contract/ICustomerRepository'
import { CustomerMemoryCache, CustomerRepository } from '@/domain/stripe/customer/repository/CustomerRepository'
import { GetBillingDetailsUseCase } from '@/domain/stripe/customer/useCase/GetBillingDetailsUseCase'
import { SaveBillingDetailsUseCase } from '@/domain/stripe/customer/useCase/SaveBillingDetailsUseCase'
import { ClearCachedCustomerUseCase } from '@/domain/stripe/customer/useCase/ClearCachedCustomerUseCase'
import { CustomerApiClient } from '@/infrastructure/api/stripe/customer/CustomerApiClient'
import { Container } from 'inversify'
import { initializeSubscriptionContainer } from '@/domain/stripe/customer/sub-domains/subscription/DIContainer'

const sharedCustomerCache: CustomerMemoryCache = {}

export function customerContainer(container: Container): Container {
  initializeHttpClients(container)
  initializeMappers(container)
  initializeRepositories(container, sharedCustomerCache)
  initializePresenters(container)
  initializeUseCases(container)

  initializeSubscriptionContainer(container)

  return container
}

function initializeHttpClients(container: Container): void {
  container
    .bind<ICustomerApiClient>(StripeTokens.CustomerTokens.apiClient.ICustomerApiClient)
    .to(CustomerApiClient)
}

function initializeMappers(container: Container): void {
  container
    .bind<ICustomerMapper>(StripeTokens.CustomerTokens.mapper.ICustomerMapper)
    .to(CustomerMapper)
}

function initializeRepositories(container: Container, sharedCache: CustomerMemoryCache): void {
  container
    .bind<CustomerMemoryCache>(StripeTokens.CustomerTokens.repository.IMemoryCache)
    .toConstantValue(sharedCache)

  container
    .bind<ICustomerRepository>(StripeTokens.CustomerTokens.repository.ICustomerRepository)
    .to(CustomerRepository)
}

function initializePresenters(container: Container): void {
  container
    .bind<IBillingDetailsPresenter>(StripeTokens.CustomerTokens.presenter.IBillingDetailsPresenter)
    .to(BillingDetailsPresenter)
}

function initializeUseCases(container: Container): void {
  container
    .bind<GetBillingDetailsUseCase>(StripeTokens.CustomerTokens.useCase.GetBillingDetailsUseCase)
    .to(GetBillingDetailsUseCase)

  container
    .bind<SaveBillingDetailsUseCase>(StripeTokens.CustomerTokens.useCase.SaveBillingDetailsUseCase)
    .to(SaveBillingDetailsUseCase)

  container
    .bind<ClearCachedCustomerUseCase>(StripeTokens.CustomerTokens.useCase.ClearCachedCustomerUseCase)
    .to(ClearCachedCustomerUseCase)
}
