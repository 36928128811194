import { StripeTokens } from '@/domain/DITokens'
import { LineItem } from '@/domain/stripe/quote/model/LineItem'
import { LineItemPresentation } from '@/domain/stripe/quote/presentation/LineItemPresentation'
import type { ILineItemPresenter } from '@/domain/stripe/quote/presenter/contract/ILineItemPresenter'
import type { IProductPresenter } from '@/domain/stripe/quote/presenter/contract/IProductPresenter'
import type { ITotalAmountPresenter } from '@/domain/stripe/quote/presenter/contract/ITotalAmountPresenter'
import type { IUnitAmountPresenter } from '@/domain/stripe/quote/presenter/contract/IUnitAmountPresenter'
import { inject, injectable } from 'inversify'

@injectable()
export class LineItemPresenter implements ILineItemPresenter {
  constructor(
    @inject(StripeTokens.QuoteTokens.presenter.IUnitAmountPresenter)
    private readonly unitAmountPresenter: IUnitAmountPresenter,
    @inject(StripeTokens.QuoteTokens.presenter.ITotalAmountPresenter)
    private readonly totalAmountPresenter: ITotalAmountPresenter,
    @inject(StripeTokens.QuoteTokens.presenter.IProductPresenter)
    private readonly productPresenter: IProductPresenter,
  ) {
  }

  public present(lineItems: LineItem[]): LineItemPresentation[] {
    return lineItems.map((lineItem) => new LineItemPresentation(
      lineItem.id,
      lineItem.description,
      lineItem.quantity,
      this.productPresenter.present(lineItem.product),
      this.unitAmountPresenter.present(lineItem.unitAmount),
      this.totalAmountPresenter.present(lineItem.totalAmount),
    ))
  }
}
