import { StripeTokens } from '@/domain/DITokens'
import { InvalidQuoteItemException } from '@/domain/stripe/quote/exception/InvalidQuoteItemException'
import { QuoteException } from '@/domain/stripe/quote/exception/QuoteException'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import { Quote } from '@/domain/stripe/quote/model/Quote'
import type { IQuotePresentation } from '@/domain/stripe/quote/presentation/contract/IQuotePresentation'
import type { IQuotePresenter } from '@/domain/stripe/quote/presenter/contract/IQuotePresenter'
import type { IQuoteRepository } from '@/domain/stripe/quote/repository/contract/IQuoteRepository'
import { inject, injectable } from 'inversify'

export class SelectPointPackRequest {
  constructor(
    public readonly productType: ProductType,
    public readonly lineItemId: string,
  ) {
  }
}

@injectable()
export class SelectPointPackUseCase {
  constructor(
    @inject(StripeTokens.QuoteTokens.repository.IQuoteRepository)
    private readonly quoteRepository: IQuoteRepository,
    @inject(StripeTokens.QuoteTokens.presenter.IQuotePresenter)
    private readonly quotePresenter: IQuotePresenter,
  ) {
  }

  public async execute(request: SelectPointPackRequest): Promise<IQuotePresentation> {
    let quote: Quote | QuoteException

    try {
      quote = await this.quoteRepository.load(request.productType)

      const lineItem = quote.findLineItem(request.lineItemId)
      if (lineItem === undefined) {
        return this.quotePresenter.present(new InvalidQuoteItemException(request.lineItemId))
      }

      for (const lineItem of quote.lineItems) {
        const isCustomPack = lineItem.product.isCustomPointPack
        if (lineItem.id !== request.lineItemId) {
          lineItem.quantity = 0
        } else if (!isCustomPack) {
          lineItem.quantity = 1
        }
      }

      quote = await this.quoteRepository.save(request.productType, quote)
    } catch (error) {
      if (!(error instanceof QuoteException)) {
        throw error
      }

      quote = error
    }

    return this.quotePresenter.present(quote)
  }
}
