import { OrganizationTokens } from '@/domain/DITokens'
import { Organization } from '@/domain/organization/model/Organization'
import type { IOrganizationRepository } from '@/domain/organization/repository/contract/IOrganizationRepository'
import { inject, injectable } from 'inversify'

export class EditOrganizationRequest {
  constructor(
    public readonly organization: Organization,
  ) {
  }
}

@injectable()
export class EditOrganizationUseCase {
  constructor(
    @inject(OrganizationTokens.repository.IOrganizationRepository)
    private readonly organizationRepository: IOrganizationRepository,
  ) {
  }

  public async execute(request: EditOrganizationRequest) {
    return await this.organizationRepository.edit(request.organization)
  }
}
