import { StripeTokens } from '@/domain/DITokens'
import type { ICouponCodeApiClient } from '@/domain/stripe/couponCode/client/contract/ICouponCodeApiClient'
import type { ICouponCodeMapper } from '@/domain/stripe/couponCode/mapper/contract/ICouponCodeMapper'
import { CouponCode } from '@/domain/stripe/couponCode/model/CouponCode'
import { CouldNotGetCouponCodeException } from '@/domain/stripe/couponCode/repository/contract/exception/CouldNotGetCouponCodeException'
import { CouponCodeNotFoundException } from '@/domain/stripe/couponCode/repository/contract/exception/CouponCodeNotFoundException'
import { InvalidOrUsedCouponCodeException } from '@/domain/stripe/couponCode/repository/contract/exception/InvalidOrUsedCouponCodeException'
import { UnknownCouponCodeException } from '@/domain/stripe/couponCode/repository/contract/exception/UnknownCouponCodeException'
import type { ICouponCodeRepository } from '@/domain/stripe/couponCode/repository/contract/ICouponCodeRepository'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'

@injectable()
export class CouponCodeRepository implements ICouponCodeRepository {
  constructor(
    @inject(StripeTokens.CouponTokens.apiClient.ICouponCodeApiClient)
    private readonly couponApiClient: ICouponCodeApiClient,
    @inject(StripeTokens.CouponTokens.mapper.ICouponCodeMapper)
    private readonly couponMapper: ICouponCodeMapper,
  ) {
  }

  private static handleException(error: HttpError): void {
    switch (error.message) {
      case 'COULD_NOT_GET_COUPON_CODE':
        throw new CouldNotGetCouponCodeException()
      case 'COUPON_CODE_NOT_FOUND':
        throw new CouponCodeNotFoundException()
      case 'INVALID_OR_USED_COUPON_CODE':
        throw new InvalidOrUsedCouponCodeException()
    }
  }

  public async validateCouponCode(couponCode: string): Promise<CouponCode> {
    try {
      const couponDto = await this.couponApiClient.validateCoupon(couponCode)
      return this.couponMapper.toModel(couponDto)
    } catch (error) {
      if (error instanceof HttpError) {
        CouponCodeRepository.handleException(error)
      }

      throw new UnknownCouponCodeException()
    }
  }
}
