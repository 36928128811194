export class Validity {

  constructor(
    public readonly startedAt: Date,
    public readonly validUntil: Date,
    autoRenew: boolean,
  ) {
    this._autoRenew = autoRenew
  }

  private _autoRenew: boolean

  get autoRenew(): boolean {
    return this._autoRenew
  }

  set autoRenew(value: boolean) {
    this._autoRenew = value
  }
}
