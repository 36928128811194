import { IInvoicePresentation } from '@/domain/stripe/invoice/presentation/contract/IInvoicePresentation'

export class InvoiceFailurePresentation implements IInvoicePresentation {
  constructor(
    public readonly errorMessage: string,
  ) {
  }

  public get isFailure(): boolean {
    return true
  }
}