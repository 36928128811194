import { Country } from '@/domain/statics/model/Country'
import { CountryPresentation } from '@/domain/statics/presentation/CountryPresentation'
import type { ICountryPresenter } from '@/domain/statics/presenter/contract/ICountryPresenter'
import { injectable } from 'inversify'

@injectable()
export class CountryPresenter implements ICountryPresenter {
  public present(country: Country): CountryPresentation {
    return new CountryPresentation(
      country.id,
      country.name,
      country.code,
      country.currencyCode,
    )
  }
}
