import { CouponInput } from '@/domain/stripe/couponCode/presenter/CouponInput'
import { Invoice } from '@/domain/stripe/invoice/model/Invoice'
import { InvoicePresentationInput } from '@/domain/stripe/invoice/presenter/InvoicePresentationInput'

export class Presentation {

  public static convertToPresentationInput(invoice: Invoice): InvoicePresentationInput {
    return new InvoicePresentationInput(
      invoice.id,
      invoice.status,
      invoice.payment.intent.secret,
      invoice.payment.intent.id,
      invoice.lines,
      invoice.subtotal,
      invoice.total,
      invoice.number,
      invoice.link.download,
      invoice.date,
      invoice.coupon
        ? new CouponInput(invoice.coupon.id, invoice.coupon.name, invoice.coupon.amountOff)
        : undefined,
      invoice.subscriptionId,
    )
  }
}
