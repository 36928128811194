import '@mdi/font/css/materialdesignicons.min.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: '#061C27',
        'background-light': '#F9F9FA',
        primary: '#159AC0',
        secondary: '#145D82',
        accent: '#333D42',
        info: '#3BAAC9',
        error: '#E63A46',
        grey: '#D8D8D8',
        highlight: '#EC8601',
      },
    },
  },
})
