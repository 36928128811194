import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import SharedDITokens from '@/domain/shared/DITokens'
import DITokens from '@/domain/stripe/customer/sub-domains/subscription/DITokens'
import {
  CouldNotGetCustomerSubscriptionsException,
} from '@/domain/stripe/customer/sub-domains/subscription/model/exception/CouldNotGetCustomerSubscriptionsException'
import {
  CouldNotReadSubscriptionOrganizationInfoException,
} from '@/domain/stripe/customer/sub-domains/subscription/model/exception/CouldNotReadSubscriptionOrganizationInfoException'
import {
  InvalidSubscriptionStatusException,
} from '@/domain/stripe/customer/sub-domains/subscription/model/exception/InvalidSubscriptionStatusException'
import {
  SubscriptionCustomerNotFoundException,
} from '@/domain/stripe/customer/sub-domains/subscription/model/exception/SubscriptionCustomerNotFoundException'
import {
  SubscriptionException,
} from '@/domain/stripe/customer/sub-domains/subscription/model/exception/SubscriptionException'
import type {
  IMySubscriptionsPresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/IMySubscriptionsPresentation'
import {
  ListMySubscriptionsErrorPresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/ListMySubscriptionsErrorPresentation'
import {
  MySubscriptionsPresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/MySubscriptionsPresentation'
import {
  SubscriptionPresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/SubscriptionPresentation'
import type {
  IMySubscriptionsPresenter,
} from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/IMySubscriptionsPresenter'
import type {
  ISubscriptionPresenter,
} from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/ISubscriptionPresenter'
import {
  MySubscriptionsPresentationInput,
} from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/MySubscriptionsPresentationInput'
import { inject, injectable } from 'inversify'

@injectable()
export class MySubscriptionsPresenter implements IMySubscriptionsPresenter {

  constructor(
    @inject(DITokens.presenter.ISubscriptionPresenter)
    private readonly subscriptionPresenter: ISubscriptionPresenter,
    @inject(SharedDITokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
  ) {
  }

  public present(input: MySubscriptionsPresentationInput | SubscriptionException): IMySubscriptionsPresentation {
    if (input instanceof MySubscriptionsPresentationInput) {
      return new MySubscriptionsPresentation(
        input
          .subscriptions
          .map((subscription) =>
            this.subscriptionPresenter.present(subscription) as SubscriptionPresentation,
          ))
    } else if (input instanceof SubscriptionCustomerNotFoundException) {
      return new MySubscriptionsPresentation([])
    } else {
      let message
      if (input instanceof CouldNotReadSubscriptionOrganizationInfoException) {
        message = this.localizedStringProvider.getText('shared.domainError.unknownError')
      } else if (input instanceof InvalidSubscriptionStatusException) {
        message = this.localizedStringProvider.getText('shared.domainError.unknownError')
      } else if (input instanceof CouldNotGetCustomerSubscriptionsException) {
        message = this.localizedStringProvider.getText('shared.domainError.serverError')
      } else {
        message = this.localizedStringProvider.getText('shared.domainError.unknownError')
      }
      return new ListMySubscriptionsErrorPresentation(message)
    }
  }
}
