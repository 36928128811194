/* reflect-metadata import needs to be on top of the file */
import 'reflect-metadata'
import { UnauthorizedException } from '@/domain/authenticating/authorization/exception/UnauthorizedException'
import App from '@/infrastructure/App.vue'
import i18n from '@/infrastructure/plugins/i18n'
import vuetify from '@/infrastructure/plugins/Vuetify'
import router from '@/infrastructure/router'
import store from '@/infrastructure/store'
import { redirectToLP360Account } from '@/infrastructure/utils/Helpers'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

Vue.config.productionTip = false
Vue.config.errorHandler = async (err, _, info) => {
  if (process.env.NODE_ENV === 'development') {
    console.error('[DEBUG]', err, info)
  }

  if (err instanceof UnauthorizedException) {
    redirectToLP360Account('login')
  }
}

const sentrySamplingRate = process.env.SENTRY_SAMPLING_RATE
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  release: process.env.VUE_APP_VERSION ?? 'v0.0.0-a',
  attachStacktrace: true,
  environment: process.env.VUE_APP_ENVIRONMENT ?? 'local',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.HOST ?? 'localhost', /^\//],
    }),
  ],
  tracesSampleRate: sentrySamplingRate ? parseInt(sentrySamplingRate) : 1.0,
})

new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
