import { RouteConfig } from 'vue-router'

export const routes: RouteConfig[] = [
  {
    path: '/admin',
    name: 'admin.home',
    component: () => import('@/infrastructure/views/admin/IndexView.vue'),
    meta: {
      title: 'admin.title',
      breadcrumb: 'admin.title',
    },
    children: [
      {
        path: 'users',
        name: 'admin.users',
        component: () => import('@/infrastructure/views/admin/users/IndexView.vue'),
        meta: {
          title: 'admin.title',
          breadcrumb: 'admin.users.title',
        },
      },
      {
        path: 'users/:userId',
        name: 'admin.users.details',
        component: () =>  import('@/infrastructure/views/admin/users/DetailsView.vue'),
        meta: {
          title: 'admin.title',
          breadcrumb: 'admin.users.title',
        },
      },
      {
        path: 'organization',
        name: 'admin.organization',
        component: () => import('@/infrastructure/views/admin/organization/IndexView.vue'),
        meta: {
          title: 'admin.title',
          breadcrumb: 'admin.organization.title',
        },
      },
      {
        path: 'points-usage',
        name: 'admin.points-usage',
        component: () => import('@/infrastructure/views/admin/points/IndexView.vue'),
        meta: {
          title: 'admin.title',
          breadcrumb: 'admin.points.title',
        },
      },
      {
        path: 'points-usage-history',
        name: 'admin.points-usage-history',
        component: () => import('@/infrastructure/views/admin/points/TransactionsHistory.vue'),
        meta: {
          title: 'admin.title',
          breadcrumb: 'admin.points.history',
        },
      },
    ],
  },
]
