import { BalancePresentation } from '@/domain/points/accounting/presentation/BalancePresentation'
import { defaultLocale } from '@/infrastructure/plugins/i18n'
import type { IAppStore } from '@/infrastructure/store/modules/contract/IApp.store'
import type { IBreadcrumbItem } from '@/infrastructure/store/modules/contract/IBreadcrumbItem'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'App',
  namespaced: true,
})
export default class AppStore extends VuexModule implements IAppStore {
  private _breadcrumbs: IBreadcrumbItem[] = []

  public get breadcrumbs(): IBreadcrumbItem[] {
    return this._breadcrumbs
  }

  private _sideBarOpened = false

  public get sideBarOpened(): boolean {
    return this._sideBarOpened
  }

  private _locale = defaultLocale

  public get locale(): string {
    return this._locale
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public get version(): string {
    return process.env.VUE_APP_VERSION as string
  }

  private _balance: BalancePresentation = BalancePresentation.EMPTY

  public get balance(): BalancePresentation {
    return this._balance
  }

  @Mutation
  public updateBreadcrumbs(breadcrumbItems: IBreadcrumbItem[]) {
    this._breadcrumbs = breadcrumbItems
  }

  @Mutation
  public openSideBar(value: boolean): boolean {
    return this._sideBarOpened = value
  }

  @Mutation
  public changeLocale(locale: string): string {
    return this._locale = locale
  }

  @Mutation
  public updateBalance(balance: BalancePresentation): void {
    this._balance = balance
  }

  @Mutation
  public clear(): void {
    this._balance = BalancePresentation.EMPTY
  }

  @HandledAction({ commit: 'updateBalance' })
  public async fetchBalance<T = BalancePresentation>(callback: () => Promise<T | void>): Promise<T | void> {
    return await callback()
  }
}
