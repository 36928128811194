import { MiddlewareTokens } from '@/infrastructure/api/http/middleware/DITokens'
import { RetryRefreshToken } from '@/infrastructure/api/http/middleware/RetryRefreshToken'
import { Container } from 'inversify'

export function middlewareContainer(
  container: Container,
): Container {
  container
    .bind<RetryRefreshToken>(MiddlewareTokens.RetryRefreshToken)
    .to(RetryRefreshToken)

  return container
}