import { SharedTokens, StripeTokens } from '@/domain/DITokens'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import { CouponCode } from '@/domain/stripe/couponCode/model/CouponCode'
import { CouponCodeExceptionPresentation, CouponCodePresentation } from '@/domain/stripe/couponCode/presentation/CouponCodePresentation'
import type { ICouponCodePresenter } from '@/domain/stripe/couponCode/presenter/contract/ICouponCodePresenter'
import type { IUnitAmountPresenter } from '@/domain/stripe/couponCode/presenter/contract/IUnitAmountPresenter'
import { CouldNotGetCouponCodeException } from '@/domain/stripe/couponCode/repository/contract/exception/CouldNotGetCouponCodeException'
import { CouponCodeNotFoundException } from '@/domain/stripe/couponCode/repository/contract/exception/CouponCodeNotFoundException'
import { CouponCodeException } from '@/domain/stripe/couponCode/repository/contract/exception/CouponCodeException'
import { InvalidOrUsedCouponCodeException } from '@/domain/stripe/couponCode/repository/contract/exception/InvalidOrUsedCouponCodeException'
import { inject, injectable } from 'inversify'

@injectable()
export class CouponCodePresenter implements ICouponCodePresenter {
  constructor(
    @inject(SharedTokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
    @inject(StripeTokens.CouponTokens.presenter.IUnitAmountPresenter)
    private readonly unitAmountPresenter: IUnitAmountPresenter,
  ) {
  }

  public presentException(exception: CouponCodeException): CouponCodeExceptionPresentation {
    let messageKey: string

    if (exception instanceof CouldNotGetCouponCodeException) {
      messageKey = 'shop.couponCode.response.error.couldNotGetCouponCodeException'
    } else if (exception instanceof CouponCodeNotFoundException) {
      messageKey = 'shop.couponCode.response.error.couponCodeNotFoundException'
    } else if (exception instanceof InvalidOrUsedCouponCodeException) {
      messageKey = 'shop.couponCode.response.error.invalidOrUsedCouponCodeException'
    } else {
      messageKey = 'shop.couponCode.response.error.unexpectedError'
    }

    return new CouponCodeExceptionPresentation(this.localizedStringProvider.getText(messageKey))
  }

  public present(coupon: CouponCode) {
    return new CouponCodePresentation(
      coupon.id,
      coupon.name,
      coupon.code,
      this.unitAmountPresenter.present(coupon.amountOff),
      coupon.couponProductName,
      coupon.percentOff,
      coupon.valid,
    )
  }
}
