import { PointsAccountingTokens } from '@/domain/DITokens'
import { ITransactionDetailsDto } from '@/domain/points/accounting/client/response/ITransactionDto'
import type { IPointsMapper } from '@/domain/points/accounting/mapper/contract/IPointsMapper'
import { ITransactionDetailsMapper } from '@/domain/points/accounting/mapper/contract/ITransactionDetailsMapper'
import { TransactionDetails } from '@/domain/points/accounting/model/TransactionDetails'
import { inject, injectable } from 'inversify'

@injectable()
export class TransactionDetailsMapper implements ITransactionDetailsMapper {
  constructor(
    @inject(PointsAccountingTokens.mapper.IPointsMapper)
    private readonly pointsMapper: IPointsMapper,
  ) {
  }

  public toModel(dto: ITransactionDetailsDto): TransactionDetails {
    return new TransactionDetails(
      dto.amount,
      this.pointsMapper.toModel(dto.detailedAmount),
      dto.transactionCode,
      dto.operation,
      dto.description,
      dto.balance ? this.pointsMapper.toModel(dto.balance) : undefined,
    )
  }
}
