import { ISummaryStore } from '@/infrastructure/store/modules/contract/shop/ISummary.store'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'ShopSummary',
  namespaced: true,
})
export default class SummaryStore extends VuexModule implements ISummaryStore {
  private _areAnySeatSelected = false
  private _isDataTableEmpty = false

  public get areAnySeatSelected(): boolean {
    return this._areAnySeatSelected
  }

  private _isSeatSelectionBeingChanged = false

  public get isSeatSelectionBeingChanged(): boolean {
    return this._isSeatSelectionBeingChanged
  }

  @Mutation
  public updateAreAnySeatSelected(value: boolean): void {
    this._areAnySeatSelected = value
  }

  public get isDataTableEmpty(): boolean {
    return this._isDataTableEmpty
  }

  @Mutation
  public updateIsDataTableEmpty(value: boolean): void {
    this._isDataTableEmpty = value
  }

  @Mutation
  public updateSeatSelectionBeingChanged(value: boolean) {
    this._isSeatSelectionBeingChanged = value
  }
}
