import { StripeTokens } from '@/domain/DITokens'
import type { IPaymentMethodDto } from '@/domain/stripe/customer/sub-domains/paymentMethod/client/dto/Dtos'
import type { ICardMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/ICardMapper'
import type { IPaymentMethodMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/IPaymentMethodMapper'
import { PaymentMethod } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/PaymentMethod'
import { inject, injectable } from 'inversify'

@injectable()
export class PaymentMethodMapper implements IPaymentMethodMapper {
  constructor(
    @inject(StripeTokens.PaymentTokens.mapper.ICardMapper)
    private readonly cardMapper: ICardMapper,
  ) {
  }

  public toModel(paymentMethods: IPaymentMethodDto): PaymentMethod {
    return new PaymentMethod(
      paymentMethods.id,
      this.cardMapper.toModel(paymentMethods.card),
    )
  }
}
