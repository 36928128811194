import { SharedTokens, StripeTokens } from '@/domain/DITokens'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import { CouponInput } from '@/domain/stripe/couponCode/presenter/CouponInput'
import { InvoiceLine } from '@/domain/stripe/invoice/model/InvoiceLine'
import type { IInvoicePresentation } from '@/domain/stripe/invoice/presentation/contract/IInvoicePresentation'
import { InvoiceFailurePresentation } from '@/domain/stripe/invoice/presentation/InvoiceFailurePresentation'
import { InvoicePresentation } from '@/domain/stripe/invoice/presentation/InvoicePresentation'
import type { IInvoiceLinePresenter } from '@/domain/stripe/invoice/presenter/contract/IInvoiceLinePresenter'
import type { IInvoicePresenter } from '@/domain/stripe/invoice/presenter/contract/IInvoicePresenter'
import type { ITotalAmountPresenter } from '@/domain/stripe/invoice/presenter/contract/ITotalAmountPresenter'
import { InvoicePresentationInput } from '@/domain/stripe/invoice/presenter/InvoicePresentationInput'
import { CouldNotGetCustomerInvoicesException } from '@/domain/stripe/invoice/repository/contract/exception/CouldNotGetCustomerInvoicesException'
import { CouldNotReadOrganizationInfoException } from '@/domain/stripe/invoice/repository/contract/exception/CouldNotReadOrganizationInfoException'
import { CustomerNotFoundException } from '@/domain/stripe/invoice/repository/contract/exception/CustomerNotFoundException'
import { InvoiceException } from '@/domain/stripe/invoice/repository/contract/exception/InvoiceException'
import { NoInvoiceFoundForCustomerException } from '@/domain/stripe/invoice/repository/contract/exception/NoInvoiceFoundForCustomerException'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import { inject, injectable } from 'inversify'
import { DateTimeFormatOptions } from 'vue-i18n'

@injectable()
export class InvoicePresenter implements IInvoicePresenter {
  constructor(
    @inject(StripeTokens.InvoiceTokens.presenter.ITotalAmountPresenter)
    private readonly totalAmountPresenter: ITotalAmountPresenter,
    @inject(SharedTokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
    @inject(StripeTokens.InvoiceTokens.presenter.IInvoiceLinePresenter)
    private readonly invoiceLinePresenter: IInvoiceLinePresenter,
  ) {
  }

  private static generateProductNames(lines: InvoiceLine[]): string {
    return lines.map((line) => line.name).join(' + ')
  }

  public present(outcome: InvoicePresentationInput | InvoiceException): IInvoicePresentation {
    if (outcome instanceof InvoicePresentationInput) {

      const options: DateTimeFormatOptions = { day: 'numeric', year: 'numeric', month: 'long' }
      const invoiceLines = outcome.lines.map(line => this.invoiceLinePresenter.present(line))
      const date = new Date(outcome.date)

      return new InvoicePresentation(
        outcome.id,
        outcome.status,
        outcome.paymentIntentSecret,
        outcome.paymentIntentId,
        outcome.number,
        new Intl.DateTimeFormat('en-US', options).format(date),
        InvoicePresenter.generateProductNames(outcome.lines),
        invoiceLines,
        this.generateInvoiceSummaryHtml(outcome),
        this.totalAmountPresenter.present(outcome.subtotal),
        this.totalAmountPresenter.present(outcome.total),
        outcome.downloadLink,
        this.generateInvoiceCouponDiscountSummaryHtml(outcome.coupon),
        outcome.subscriptionId,
      )
    } else {
      if (outcome instanceof CouldNotGetCustomerInvoicesException) {
        return new InvoiceFailurePresentation(this.localizedStringProvider.getText('shared.domainError.serverError'))
      } else if (outcome instanceof CouldNotReadOrganizationInfoException) {
        return new InvoiceFailurePresentation(this.localizedStringProvider.getText('shared.domainError.unknownError'))
      } else if (outcome instanceof CustomerNotFoundException) {
        return new InvoiceFailurePresentation(this.localizedStringProvider.getText('invoice.response.error.customerNotFound'))
      } else if (outcome instanceof NoInvoiceFoundForCustomerException) {
        return new InvoiceFailurePresentation(this.localizedStringProvider.getText('invoice.response.error.noInvoicesFoundForCustomer'))
      } else {
        return new InvoiceFailurePresentation(this.localizedStringProvider.getText('shared.domainError.unknownError'))
      }
    }
  }

  private generateInvoiceSummaryHtml(invoice: InvoicePresentationInput): string {
    const license = this.localizedStringProvider.getText('shared.language.license')
    const interval = this.localizedStringProvider.getText(`shared.language.interval.${invoice.total.interval}`)
    const subscription = this.localizedStringProvider.getText('shared.language.subscription')

    return invoice.lines.map((line) => {
      const seatsKey = 'shared.language.seat'
      const usersKey = 'shared.language.user'
      const licenseTypeKey = line.platform === 'online' ? usersKey : seatsKey
      const licenseType = this.localizedStringProvider.getText(licenseTypeKey, line.quantity)

      if (line.productMetadata?.type === ProductType.Point) {
        const pointsKey = this.localizedStringProvider.getText('shop.points.title').toLowerCase()

        if (line.productMetadata?.custom === 'true') {
          return `${line.name}, ${line.quantity} ${pointsKey}`
        }

        return `${line.name}, ${line.productMetadata.points} ${pointsKey}`
      }

      return `${line.name} ${license}, ${interval} ${subscription}, ${line.quantity} ${licenseType}`
    }).join('<br/>')
  }

  private generateInvoiceCouponDiscountSummaryHtml(coupon: CouponInput | undefined): string {
    if (!coupon) {
      return ''
    }
    const formatter = new Intl.NumberFormat('en-US', {
      maximumSignificantDigits: 5,
    })
    const discount = coupon.name
    const amountOffSuffix = this.localizedStringProvider.getText('shared.language.off')
    const amountOffCurrency = coupon.amountOff.currency.toUpperCase()
    const amountOff = formatter.format(coupon.amountOff.amount)
    return `${discount} (${amountOffCurrency} ${amountOff} ${amountOffSuffix})`
  }
}
