import { StripeTokens } from '@/domain/DITokens'
import type {
  ICustomerPaymentMethodsApiClient,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/client/contract/ICustomerPaymentMethodsApiClient'
import type { ISetupIntentDto } from '@/domain/stripe/customer/sub-domains/paymentMethod/client/dto/Dtos'
import {
  CouldNotCreateSetupIntentException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CouldNotCreateSetupIntentException'
import {
  CouldNotGetCustomerPaymentMethodsException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CouldNotGetCustomerPaymentMethodsException'
import {
  CouldNotReadOrganizationInfoException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CouldNotReadOrganizationInfoException'
import {
  CustomerNotFoundException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CustomerNotFoundException'
import {
  UnknownPaymentMethodException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/UnknownPaymentMethodException'
import type {
  ICustomerPaymentMethodsMapper,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/ICustomerPaymentMethodsMapper'
import type {
  ISetupIntentMapper,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/ISetupIntentMapper'
import { CustomerPaymentMethods } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/CustomerPaymentMethods'
import { SetupIntent } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/SetupIntent'
import type {
  ICustomerPaymentMethodsRepository,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/ICustomerPaymentMethodsRepository'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'

@injectable()
export class CustomerPaymentMethodsRepository implements ICustomerPaymentMethodsRepository {
  constructor(
    @inject(StripeTokens.PaymentTokens.apiClient.IPaymentMethodApiClient)
    private readonly paymentMethodApiClient: ICustomerPaymentMethodsApiClient,
    @inject(StripeTokens.PaymentTokens.mapper.IPaymentMethodMapper)
    private readonly paymentMethodMapper: ICustomerPaymentMethodsMapper,
    @inject(StripeTokens.PaymentTokens.mapper.ISetupIntentMapper)
    private readonly setupIntentMapper: ISetupIntentMapper,
  ) {
  }

  private static handleException(error: HttpError): void {
    switch (error.message) {
      case 'COULD_NOT_GET_CUSTOMER_PAYMENT_METHODS':
        throw new CouldNotGetCustomerPaymentMethodsException()
      case 'COULD_NOT_READ_ORGANIZATION_INFO':
        throw new CouldNotReadOrganizationInfoException()
      case 'CUSTOMER_NOT_FOUND':
        throw new CustomerNotFoundException()
      case 'COULD_NOT_CREATE_SETUP_INTENT':
        throw new CouldNotCreateSetupIntentException()
      default:
        throw new UnknownPaymentMethodException()
    }
  }

  public async load(): Promise<CustomerPaymentMethods> {
    try {
      const paymentMethodDto = await this.paymentMethodApiClient.getAll()
      return this.paymentMethodMapper.toModel(paymentMethodDto)
    } catch (error) {
      if (error instanceof HttpError) {
        CustomerPaymentMethodsRepository.handleException(error)
      }

      throw error
    }
  }

  public async setupCreditCard(): Promise<SetupIntent> {

    try {
      const dto: ISetupIntentDto = await this.paymentMethodApiClient.setupCreditCard()
      return this.setupIntentMapper.toModel(dto)
    } catch (error) {
      if (error instanceof HttpError) {
        CustomerPaymentMethodsRepository.handleException(error)
      }

      throw error
    }
  }
}
