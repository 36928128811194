import { SharedTokens } from '@/domain/DITokens'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import { SetupIntent } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/SetupIntent'
import {
  ISetupIntentPresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/contract/ISetupIntentPresentation'
import {
  SetupIntentFailurePresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/SetupIntentFailurePresentation'
import {
  SetupIntentPresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/SetupIntentPresentation'
import {
  ISetupIntentPresenter,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ISetupIntentPresenter'
import {
  CouldNotCreateSetupIntentException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CouldNotCreateSetupIntentException'
import {
  CouldNotReadOrganizationInfoException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CouldNotReadOrganizationInfoException'
import {
  CustomerNotFoundException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CustomerNotFoundException'
import {
  CustomerPaymentMethodsException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CustomerPaymentMethodsException'
import { inject, injectable } from 'inversify'

@injectable()
export class SetupIntentPresenter implements ISetupIntentPresenter {
  constructor(
    @inject(SharedTokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
  ) {
  }

  public present(input: SetupIntent | CustomerPaymentMethodsException): ISetupIntentPresentation {
    if (input instanceof SetupIntent) {
      return new SetupIntentPresentation(
        input.id,
        input.secret,
        input.status,
      )
    }

    let errorMessage: string

    if (input instanceof CustomerNotFoundException) {
      errorMessage = this.localizedStringProvider.getText('shop.paymentMethod.response.error.customerNotFound')
    } else if (input instanceof CouldNotCreateSetupIntentException) {
      errorMessage = this.localizedStringProvider.getText('shop.paymentMethod.error.couldNotCreateSetupIntent')
    } else if (input instanceof CouldNotReadOrganizationInfoException) {
      errorMessage = this.localizedStringProvider.getText('shop.paymentMethod.error.couldNotReadOrganizationInfo')
    } else {
      errorMessage = this.localizedStringProvider.getText('shop.paymentMethod.response.error.unexpectedError')
    }

    return new SetupIntentFailurePresentation(errorMessage)
  }
}