export class ProductPresentation {
  private readonly _requiredProducts: string[]

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly description: string,
    public readonly metadata: Record<string, string>,
  ) {
    this._requiredProducts = this.metadata['required_products']?.split(',')?.map(p => p.trim()) ?? []
  }

  public get requiredProducts(): string[] {
    return this._requiredProducts
  }

  public get platform(): string {
    return this.metadata['platform'] ?? 'unknown'
  }

  public get isCustomPointPack(): boolean {
    return this.metadata['custom'] === 'true' ?? false
  }

  public get points(): string {
    return this.metadata['points'] ?? 'N/A'
  }

  public get notes(): string {
    return this.metadata['notes']
  }

  public get saving(): string {
    return this.metadata['saving'] ?? '0%'
  }

  public get type(): string {
    return this.metadata['type']
  }
}
