import { CountryPresentation } from '@/domain/statics/presentation/CountryPresentation'
import { User } from '@/domain/user/model/User'
import { trackActionExecutionProgress } from '@/infrastructure/store/ActionLoadingIndicator'
import type { IUserDetailsStore } from '@/infrastructure/store/modules/contract/admin/IUserDetails.store'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'AdminUserDetails',
  namespaced: true,
})
export default class UserDetailsStore extends VuexModule implements IUserDetailsStore {
  private _user = {} as User

  public get user(): User {
    return this._user
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  private _availableCountries: CountryPresentation[] = []

  public get availableCountries(): CountryPresentation[] {
    return this._availableCountries
  }

  @Mutation
  public updateIsLoading(value: boolean) {
    this._isLoading = value
  }

  @Mutation
  public updateUser(value: User): void {
    this._user = value
  }

  @Mutation
  public updateAvailableCountries(countries: CountryPresentation[]): void {
    this._availableCountries = countries
  }

  @Mutation
  public reset() {
    this._user = {} as User
    this._isLoading = false
  }

  @HandledAction({ commit: 'updateUser' })
  public async fetchUser(
    callback: () => Promise<User | void>,
  ): Promise<User | void> {
    return trackActionExecutionProgress(callback, this.updateIsLoading)
  }

  @HandledAction({ commit: 'updateAvailableCountries' })
  public async fetchCountries(
    action: () => Promise<CountryPresentation[] | void>,
  ): Promise<CountryPresentation[] | void> {

    return action()
  }

  @HandledAction()
  public async save(
    action: () => Promise<void>,
  ): Promise<void> {

    return trackActionExecutionProgress(action, this.updateIsLoading)
  }
}
