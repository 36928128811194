import type { ICustomerApiClient } from '@/domain/stripe/customer/client/contract/ICustomerApiClient'
import type { ICustomerDto } from '@/domain/stripe/customer/client/dto/Dtos'
import DITokens from '@/domain/stripe/customer/DITokens'
import type { ICustomerMapper } from '@/domain/stripe/customer/mapper/contract/ICustomerMapper'
import { Customer } from '@/domain/stripe/customer/model/Customer'
import {
  CouldNotGetCustomerException,
} from '@/domain/stripe/customer/repository/contract/exception/CouldNotGetCustomerException'
import {
  CouldNotSaveCustomerException,
} from '@/domain/stripe/customer/repository/contract/exception/CouldNotSaveCustomerException'
import {
  CustomerEndpointsServerException,
} from '@/domain/stripe/customer/repository/contract/exception/CustomerEndpointsServerException'
import {
  CustomerNotFoundException,
} from '@/domain/stripe/customer/repository/contract/exception/CustomerNotFoundException'
import {
  UnknownCustomerException,
} from '@/domain/stripe/customer/repository/contract/exception/UnknownCustomerException'
import type { ICustomerRepository } from '@/domain/stripe/customer/repository/contract/ICustomerRepository'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { StatusCode } from '@/infrastructure/api/http/StatusCode'
import { inject, injectable } from 'inversify'

export type CustomerMemoryCache = { [id: string]: Customer }

const STRIPE_CUSTOMER_CACHE_KEY = 'stripe'

@injectable()
export class CustomerRepository implements ICustomerRepository {
  constructor(
    @inject(DITokens.apiClient.ICustomerApiClient)
    private readonly apiClient: ICustomerApiClient,
    @inject(DITokens.mapper.ICustomerMapper)
    private readonly customerMapper: ICustomerMapper,
    @inject(DITokens.repository.IMemoryCache)
    private readonly cachedCustomer?: CustomerMemoryCache,
  ) {
  }

  public async load(): Promise<Customer> {

    const customer = this.readFromCache()
    if (customer) {
      return customer
    }

    try {
      const dto = await this.apiClient.get()
      return this.mapToModelAndCache(dto)
    } catch (e) {
      if (e instanceof HttpError) {
        switch (e.code) {
          case StatusCode.NotFound:
            throw new CustomerNotFoundException()
          case StatusCode.BadRequest:
            throw new CouldNotGetCustomerException()
          case StatusCode.TooManyRequests:
            throw new CustomerEndpointsServerException()
          default: {
            if (e.code >= StatusCode.InternalServerError) {
              throw new CustomerEndpointsServerException()
            }
          }
        }
      }
      throw new UnknownCustomerException()
    }
  }

  public async create(customer: Customer): Promise<Customer> {
    return this.save(customer, (dto) => this.apiClient.create(dto))
  }

  public async update(customer: Customer): Promise<Customer> {
    return this.save(customer, (dto) => this.apiClient.update(dto))
  }

  private async save(
    customer: Customer,
    action: (customerDto: ICustomerDto) => Promise<ICustomerDto>,
  ): Promise<Customer> {
    try {
      const editedDto = this.customerMapper.toDto(customer)
      const dto = await action(editedDto)
      return this.mapToModelAndCache(dto)
    } catch (e) {
      if (e instanceof HttpError) {
        switch (e.code) {
          case StatusCode.BadRequest:
            throw new CouldNotSaveCustomerException()
          case StatusCode.TooManyRequests:
            throw new CustomerEndpointsServerException()
          default: {
            if (e.code >= StatusCode.InternalServerError) {
              throw new CustomerEndpointsServerException()
            }
          }
        }
      }
      throw new UnknownCustomerException()
    }
  }

  private mapToModelAndCache(dto: ICustomerDto) {

    const model = this.customerMapper.toModel(dto)
    this.writeToCache(model)
    return model
  }

  private writeToCache(model: Customer) {
    if (this.cachedCustomer) {
      this.cachedCustomer[STRIPE_CUSTOMER_CACHE_KEY] = model
    }
  }

  private readFromCache(): Customer | null {
    if (this.cachedCustomer) {
      return this.cachedCustomer[STRIPE_CUSTOMER_CACHE_KEY]
    }
    return null
  }

  public clearCachedCustomer():void{
    if (this.cachedCustomer) {
      delete this.cachedCustomer[STRIPE_CUSTOMER_CACHE_KEY]
    }
  }
}
