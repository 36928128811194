import { DownloadTokens } from '@/domain/DITokens'
import type { IDownloadApiClient } from '@/domain/download/client/contract/IDownloadApiClient'
import type { IDownloadMapper } from '@/domain/download/mapper/contract/IDownloadMapper'
import { DownloadMapper } from '@/domain/download/mapper/DownloadMapper'
import { IDownloadRepository } from '@/domain/download/repository/contract/IDownloadRepository'
import { DownloadRepository } from '@/domain/download/repository/DownloadRepository'
import { GetAvailableApplicationsDownloadLinkUseCase } from '@/domain/download/useCase/GetAvailableApplicationsDownloadLinkUseCase'
import { GetLatestVersionDownloadLinkUseCase } from '@/domain/download/useCase/GetLatestVersionDownloadLinkUseCase'
import { DownloadApiClient } from '@/infrastructure/api/download/DownloadApiClient'
import { Container } from 'inversify'

export function downloadContainer(container: Container): Container {
  initializeHttpClients(container)
  initializeMappers(container)
  initializeRepositories(container)
  initializeUseCases(container)

  return container
}

function initializeHttpClients(container: Container): void {
  container
    .bind<IDownloadApiClient>(DownloadTokens.apiClient.IDownloadApiClient)
    .to(DownloadApiClient)
}

function initializeMappers(container: Container): void {
  container
    .bind<IDownloadMapper>(DownloadTokens.mapper.IApplicationDownloadMapper)
    .to(DownloadMapper)
}

function initializeRepositories(container: Container): void {
  container
    .bind<IDownloadRepository>(DownloadTokens.repository.IApplicationDownloadRepository)
    .to(DownloadRepository)
}

function initializeUseCases(container: Container): void {
  container
    .bind<GetLatestVersionDownloadLinkUseCase>(DownloadTokens.useCase.GetLatestVersionDownloadLinkUseCase)
    .to(GetLatestVersionDownloadLinkUseCase)

  container
    .bind<GetAvailableApplicationsDownloadLinkUseCase>(DownloadTokens.useCase.GetAvailableApplicationsDownloadLinkUseCase)
    .to(GetAvailableApplicationsDownloadLinkUseCase)
}
