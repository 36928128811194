import type { IQuotePresentation } from '@/domain/stripe/quote/presentation/contract/IQuotePresentation'

export class QuoteFailurePresentation extends Error implements IQuotePresentation {
  constructor(
    public readonly message: string,
  ) {
    super(message)
  }

  public get isFailure(): boolean {
    return true
  }
}
