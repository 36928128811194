import type {
  ISubscriptionApiClient,
} from '@/domain/stripe/customer/sub-domains/subscription/client/contract/ISubscriptionApiClient'
import type { ISubscriptionDto } from '@/domain/stripe/customer/sub-domains/subscription/client/dto/Dtos'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { StripeApiClient } from '@/infrastructure/api/stripe/StripeApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class SubscriptionApiClient extends StripeApiClient implements ISubscriptionApiClient {

  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
      httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  public list(): Promise<ISubscriptionDto[]> {
    return this.httpClient.get(`${this.baseUrl}/customer/subscription`)
  }

  public get(id: string): Promise<ISubscriptionDto> {
    return this.httpClient.get(`${this.baseUrl}/customer/subscription/${id}`)
  }

  public toggleAutoRenew(subscription: ISubscriptionDto): Promise<ISubscriptionDto> {
    return this.httpClient.put(`${this.baseUrl}/customer/subscription/${subscription.id}/auto_renew`, {
      auto_renew: subscription.validity.auto_renew,
      customer_id: subscription.latest_invoice.customer,
    })
  }

}
