import { StripeTokens } from '@/domain/DITokens'
import { CouponCodeExceptionPresentation, CouponCodePresentation } from '@/domain/stripe/couponCode/presentation/CouponCodePresentation'
import type { ICouponCodePresenter } from '@/domain/stripe/couponCode/presenter/contract/ICouponCodePresenter'
import { CouponCodeException } from '@/domain/stripe/couponCode/repository/contract/exception/CouponCodeException'
import type { ICouponCodeRepository } from '@/domain/stripe/couponCode/repository/contract/ICouponCodeRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class ValidateCouponCodeUseCase {
  constructor(
    @inject(StripeTokens.CouponTokens.repository.ICouponCodeRepository)
    private readonly couponRepository: ICouponCodeRepository,
    @inject(StripeTokens.CouponTokens.presenter.ICouponCodePresenter)
    private readonly couponPresenter: ICouponCodePresenter,
  ) {
  }

  public async execute(couponCode: string): Promise<CouponCodePresentation | CouponCodeExceptionPresentation> {
    try {
      const couponValidated = await this.couponRepository.validateCouponCode(couponCode)

      return this.couponPresenter.present(couponValidated)
    } catch (error) {
      if (error instanceof CouponCodeException) {
        return this.couponPresenter.presentException(error)
      }

      throw error
    }
  }
}
