const authenticatedHttpClient = Symbol()

const httpClient = Symbol()

const tokens = {
  authenticatedHttpClient,
  httpClient,
}

export { tokens as ApiTokens }
