import { pascalCase } from '@/shared/extensions/lodash'
import { ModuleTree } from 'vuex'

// Match any file with `.store.js` or `.store.ts` extension
const requireModule = require.context(
  '.', true, /\w+.store.[tj]s$/i,
)

const modules: ModuleTree<unknown> = {}

for (const filename of requireModule.keys()) {
  if (filename.includes('contract')) {
    continue
  }

  const moduleName: string = pascalCase(
    filename.replace(/.store.[tj]s/gi, ''),
  )
  const storeModule = (
    requireModule(filename).default
    || requireModule(filename)
  )

  modules[moduleName] = {
    namespaced: true,
    ...storeModule,
  }
}

export default modules
