import { StripeTokens } from '@/domain/DITokens'
import type { ICouponDto } from '@/domain/stripe/couponCode/client/dto/Dtos'
import type { ICouponMapper } from '@/domain/stripe/couponCode/mapper/contract/ICouponMapper'
import type { IUnitAmountMapper } from '@/domain/stripe/couponCode/mapper/contract/IUnitAmountMapper'
import { Coupon } from '@/domain/stripe/couponCode/model/Coupon'
import { inject, injectable } from 'inversify'

@injectable()
export class CouponMapper implements ICouponMapper {
  constructor(
    @inject(StripeTokens.CouponTokens.mapper.IUnitAmountMapper)
    private readonly unitAmountMapper: IUnitAmountMapper,
  ) {
  }

  public toModel(coupon: ICouponDto): Coupon {
    return new Coupon(
      coupon.id,
      coupon.name,
      this.unitAmountMapper.toModel(coupon.amount_off),
    )
  }
}
