import { UserTokens } from '@/domain/DITokens'
import type { IUserRepository } from '@/domain/user/repository/contract/IUserRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class DeleteUserByIdUseCase {
  constructor(
    @inject(UserTokens.repository.IUserRepository)
    private readonly userRepository: IUserRepository,
  ) {
  }

  public async execute(id: string) {
    return await this.userRepository.deleteUser(id)
  }
}
