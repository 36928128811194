import { CountryPresentation } from '@/domain/statics/presentation/CountryPresentation'
import { BillingDetailsPresentation } from '@/domain/stripe/customer/presentation/BillingDetailsPresentation'
import { trackActionExecutionProgress } from '@/infrastructure/store/ActionLoadingIndicator'
import { IBillingDetailsStore } from '@/infrastructure/store/modules/contract/shop/IBillingDetails.store'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { isEmpty } from 'lodash'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'ShopBillingDetails',
  namespaced: true,
})
export default class BillingDetailsStore extends VuexModule implements IBillingDetailsStore {
  private _company = ''

  public get company(): string {
    return this._company
  }

  private _email = ''

  public get email(): string {
    return this._email
  }

  private _address = ''

  public get address(): string {
    return this._address
  }

  private _city = ''

  public get city(): string {
    return this._city
  }

  private _stateOrProvince = ''

  public get stateOrProvince(): string {
    return this._stateOrProvince
  }

  private _zipOrPostalCode = ''

  public get zipOrPostalCode(): string {
    return this._zipOrPostalCode
  }

  private _country: CountryPresentation | undefined = undefined

  public get country(): CountryPresentation | undefined {
    return this._country
  }

  private _contactNumber = ''

  public get contactNumber(): string {
    return this._contactNumber
  }

  private _companyWebsite = ''

  public get companyWebsite(): string {
    return this._companyWebsite
  }

  private _useRegisteredInfo = false

  public get useRegisteredInfo(): boolean {
    return this._useRegisteredInfo
  }

  private _registeredInfoAvailable = false

  public get registeredInfoAvailable(): boolean {
    return this._registeredInfoAvailable
  }

  private _availableCountries: CountryPresentation[] = []

  public get availableCountries(): CountryPresentation[] {
    return this._availableCountries
  }

  private _saveActionInProgress = false

  public get saveActionInProgress(): boolean {
    return this._saveActionInProgress
  }

  public get isFormIncomplete(): boolean {
    return isEmpty(this._company)
      || isEmpty(this._email)
      || isEmpty(this._address)
      || isEmpty(this._stateOrProvince)
      || isEmpty(this._zipOrPostalCode)
      || isEmpty(this._country)
      || isEmpty(this._contactNumber)
  }

  @Mutation
  public updateCompany(companyName: string): void {
    this._company = companyName
    this._useRegisteredInfo = false
  }

  @Mutation
  public updateEmail(email: string): void {
    this._email = email
    this._useRegisteredInfo = false
  }

  @Mutation
  public updateAddress(address: string): void {
    this._address = address
    this._useRegisteredInfo = false
  }

  @Mutation
  public updateCity(city: string): void {
    this._city = city
    this._useRegisteredInfo = false
  }

  @Mutation
  public updateStateOrProvince(stateOrProvince: string): void {
    this._stateOrProvince = stateOrProvince
    this._useRegisteredInfo = false
  }

  @Mutation
  public updateZipOrPostalCode(zipOrPostalCode: string): void {
    this._zipOrPostalCode = zipOrPostalCode
    this._useRegisteredInfo = false
  }

  @Mutation
  public updateCountry(country: CountryPresentation | undefined): void {
    this._country = country
    this._useRegisteredInfo = false
  }

  @Mutation
  public updateContactNumber(contactNumber: string): void {
    this._contactNumber = contactNumber
    this._useRegisteredInfo = false
  }

  @Mutation
  public updateCompanyWebsite(companyWebsite: string): void {
    this._companyWebsite = companyWebsite
    this._useRegisteredInfo = false
  }

  @Mutation
  public updateUseRegisteredInfo(available: boolean): void {
    this._useRegisteredInfo = available
  }

  @Mutation
  public updateBillingDetails(presentation: BillingDetailsPresentation): void {
    this._registeredInfoAvailable = presentation.registeredInfoAvailable
    this._useRegisteredInfo = presentation.registeredInfoAvailable

    this._company = presentation.company
    this._email = presentation.email
    this._address = presentation.address
    this._city = presentation.city
    this._stateOrProvince = presentation.stateOrProvince
    this._zipOrPostalCode = presentation.zipOrPostalCode
    this._country = presentation.country
    this._contactNumber = presentation.contactNumber
    this._companyWebsite = presentation.companyWebsite ?? ''
  }

  @Mutation
  public updateAvailableCountries(countries: CountryPresentation[]): void {
    this._availableCountries = countries
  }

  @Mutation
  public updateSaveInProgress(value: boolean) {
    this._saveActionInProgress = value
  }

  @Mutation
  public resetForm(): void {
    this._company = ''
    this._email = ''
    this._address = ''
    this._city = ''
    this._stateOrProvince = ''
    this._zipOrPostalCode = ''
    this._country = undefined
    this._contactNumber = ''
    this._companyWebsite = ''
    this._saveActionInProgress = false
  }

  @Mutation
  public resetState(): void {
    this._company = ''
    this._email = ''
    this._address = ''
    this._city = ''
    this._stateOrProvince = ''
    this._zipOrPostalCode = ''
    this._country = undefined
    this._contactNumber = ''
    this._companyWebsite = ''
    this._useRegisteredInfo = false
    this._registeredInfoAvailable = false
    this._availableCountries = []
  }

  @HandledAction({ commit: 'updateBillingDetails' })
  public async fetchData(
    action: () => Promise<BillingDetailsPresentation | void>,
  ): Promise<BillingDetailsPresentation | void> {

    return action()
  }

  @HandledAction({ commit: 'updateAvailableCountries' })
  public async fetchCountries(
    action: () => Promise<CountryPresentation[] | void>,
  ): Promise<CountryPresentation[] | void> {

    return action()
  }

  @HandledAction()
  public async save(
    action: () => Promise<BillingDetailsPresentation | void>,
  ): Promise<BillingDetailsPresentation | void> {

    return trackActionExecutionProgress(action, this.updateSaveInProgress)
  }
}
