import { StaticsTokens } from '@/domain/DITokens'
import type { IStaticsApiClient } from '@/domain/statics/client/contract/IStaticsApiClient'
import type { ICountryDto } from '@/domain/statics/client/dto/Dtos'
import { Country } from '@/domain/statics/model/Country'
import { CouldNotGetCountriesException } from '@/domain/statics/repository/contract/exception/CouldNotGetCountriesException'
import { CountriesEndpointsServerException } from '@/domain/statics/repository/contract/exception/CountriesEndpointsServerException'
import { CountriesNotFoundException } from '@/domain/statics/repository/contract/exception/CountriesNotFoundException'
import { UnknownCountriesException } from '@/domain/statics/repository/contract/exception/UnknownCountriesException'
import type { IStaticsRepository } from '@/domain/statics/repository/contract/IStaticsRepository'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { StatusCode } from '@/infrastructure/api/http/StatusCode'
import { inject, injectable } from 'inversify'

@injectable()
export class StaticsRepository implements IStaticsRepository {
  constructor(
    @inject(StaticsTokens.apiClient)
    private readonly staticsApiClient: IStaticsApiClient,
  ) {
  }

  private static map(country: ICountryDto): Country {
    return new Country(
      country.id,
      country.name,
      country.iso2_code,
      country.currency_code,
    )
  }

  public async loadAll(): Promise<Country[]> {
    let data: { countries: ICountryDto[] }

    try {
      data = await this.staticsApiClient.getAll()
    } catch (e) {
      if (e instanceof HttpError) {
        switch (e.code) {
          case StatusCode.NotFound:
            throw new CountriesNotFoundException()
          case StatusCode.BadRequest:
            throw new CouldNotGetCountriesException()
          case StatusCode.TooManyRequests:
            throw new CountriesEndpointsServerException()
          default: {
            if (e.code >= StatusCode.InternalServerError) {
              throw new CountriesEndpointsServerException()
            }
          }
        }
      }
      throw new UnknownCountriesException()
    }

    return data.countries.map(StaticsRepository.map)
  }
}
