import DITokens from '@/domain/stripe/customer/DITokens'
import { Customer } from '@/domain/stripe/customer/model/Customer'
import type { IBillingDetailsPresentation } from '@/domain/stripe/customer/presentation/contract/IBillingDetailsPresentation'
import { SaveBillingDetailsRequest } from '@/domain/stripe/customer/useCase/SaveBillingDetailsRequest'
import { CustomerPresentationInput } from '@/domain/stripe/customer/presenter/contract/CustomerPresentationInput'
import type { IBillingDetailsPresenter } from '@/domain/stripe/customer/presenter/contract/IBillingDetailsPresenter'
import { CustomerException } from '@/domain/stripe/customer/repository/contract/exception/CustomerException'
import { CustomerNotFoundException } from '@/domain/stripe/customer/repository/contract/exception/CustomerNotFoundException'
import type { ICustomerRepository } from '@/domain/stripe/customer/repository/contract/ICustomerRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class SaveBillingDetailsUseCase {
  constructor(
    @inject(DITokens.repository.ICustomerRepository)
    private readonly repository: ICustomerRepository,
    @inject(DITokens.presenter.IBillingDetailsPresenter)
    private readonly presenter: IBillingDetailsPresenter,
  ) {
  }

  async execute(request: SaveBillingDetailsRequest): Promise<IBillingDetailsPresentation> {

    let savedCustomer: Customer | null
    try {
      savedCustomer = await this.repository.load()
    } catch (e) {
      if (e instanceof CustomerNotFoundException) {
        savedCustomer = null
      } else if (e instanceof CustomerException) {
        return this.presenter.present(e)
      } else {
        throw e
      }
    }

    let customerPresentationInput: CustomerPresentationInput | CustomerException
    let customer: Customer
    try {
      if (savedCustomer === null) {
        customer = await this.repository.create(SaveBillingDetailsUseCase.createCustomerFromRequest(request))
      } else {
        SaveBillingDetailsUseCase.updateCustomerFromRequest(savedCustomer, request)
        customer = await this.repository.update(savedCustomer)
      }

      customerPresentationInput = new CustomerPresentationInput(
        customer.company,
        customer.email,
        customer.phoneNumber,
        customer.website,
        customer.streetAddress,
        customer.city,
        customer.state,
        customer.postalCode,
        customer.countryId,
        customer.countryName,
        customer.countryCode,
        customer.id,
      )
    } catch (e) {
      if (e instanceof CustomerException) {
        customerPresentationInput = e
      } else {
        throw e
      }
    }

    return this.presenter.present(customerPresentationInput)
  }

  private static updateCustomerFromRequest(savedCustomer: Customer, request: SaveBillingDetailsRequest) {
    savedCustomer.company = request.company
    savedCustomer.email = request.email
    savedCustomer.phoneNumber = request.contactNumber
    savedCustomer.website = request.companyWebsite
    savedCustomer.streetAddress = request.address
    savedCustomer.city = request.city
    savedCustomer.state = request.stateOrProvince
    savedCustomer.postalCode = request.zipOrPostalCode
    savedCustomer.countryId = request.countryId
    savedCustomer.countryName = request.countryName
    savedCustomer.countryCode = request.countryCode
  }

  private static createCustomerFromRequest(request: SaveBillingDetailsRequest) {
    return new Customer(
      request.company,
      request.email,
      request.contactNumber,
      request.companyWebsite,
      request.address,
      request.city,
      request.stateOrProvince,
      request.zipOrPostalCode,
      request.countryId,
      request.countryName,
      request.countryCode,
    )
  }
}
