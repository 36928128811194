import { User } from '@/domain/user/model/User'
import { trackActionExecutionProgress } from '@/infrastructure/store/ActionLoadingIndicator'
import { IAuthStore } from '@/infrastructure/store/modules/contract/IAuth.store'
import { getSsoCookieOrThrow } from '@/infrastructure/utils/extensions/js-cookie'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import Vue from 'vue'
import Vuex from 'vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

Vue.use(Vuex)

@Module({
  name: 'Auth',
  namespaced: true,
})
export default class AuthStore extends VuexModule implements IAuthStore {
  private _userProfile = {} as User

  public get userProfile(): User {
    return this._userProfile
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  private _isAccessRestricted = false

  public get isAccessRestricted(): boolean {
    return this._isAccessRestricted
  }

  public get isAuthenticated(): boolean {
    return !!getSsoCookieOrThrow()
  }

  private _restrictedAccessIsKnown = false

  public get restrictedAccessIsKnown(): boolean {
    return this._restrictedAccessIsKnown
  }

  @Mutation
  public markRestrictedAccessIsKnown(): void {
    this._restrictedAccessIsKnown = true
  }

  @Mutation
  public grantRestrictedAccess(): void {
    this._isAccessRestricted = true
  }

  @Mutation
  public grantFullAccess(): void {
    this._isAccessRestricted = false
    this._restrictedAccessIsKnown = true
  }

  @Mutation
  public resetIsRestrictedAccess(): void {
    this._isAccessRestricted = false
    this._restrictedAccessIsKnown = false
  }

  @Mutation
  public updateIsLoading(value: boolean) {
    this._isLoading = value
  }

  @Mutation
  public updateUserProfile(value: User): void {
    this._userProfile = value
  }

  @HandledAction({ commit: 'updateUserProfile' })
  public async fetchUserProfile(
    callback: () => Promise<User | void>,
  ): Promise<User | void> {
    return trackActionExecutionProgress(callback, this.updateIsLoading)
  }

  @HandledAction()
  public clear(): void {
    this.context.commit('resetIsRestrictedAccess')
  }
}
