import { StripeTokens } from '@/domain/DITokens'
import { QuoteException } from '@/domain/stripe/quote/exception/QuoteException'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import { Quote } from '@/domain/stripe/quote/model/Quote'
import type { IQuotePresentation } from '@/domain/stripe/quote/presentation/contract/IQuotePresentation'
import type { IQuotePresenter } from '@/domain/stripe/quote/presenter/contract/IQuotePresenter'
import type { IQuoteRepository } from '@/domain/stripe/quote/repository/contract/IQuoteRepository'
import { inject, injectable } from 'inversify'

export class ConvertQuoteRequest {
  constructor(
    public readonly productType: ProductType,
    public readonly couponCode?: string,
  ) {
  }
}

@injectable()
export class ConvertQuoteUseCase {
  constructor(
    @inject(StripeTokens.QuoteTokens.repository.IQuoteRepository)
    private readonly quoteRepository: IQuoteRepository,
    @inject(StripeTokens.QuoteTokens.presenter.IQuotePresenter)
    private readonly quotePresenter: IQuotePresenter,
  ) {
  }

  public async execute(request: ConvertQuoteRequest): Promise<IQuotePresentation> {
    let outcome: Quote | QuoteException

    try {
      outcome = await this.quoteRepository.convert(request.productType, request.couponCode)
    } catch (error) {
      if (error instanceof QuoteException) {
        outcome = error
      } else {
        throw error
      }
    }

    return this.quotePresenter.present(outcome)
  }
}
