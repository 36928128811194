import type { IUserApiClient } from '@/domain/user/client/contract/IUserApiClient'
import type { IUserRequestDto } from '@/domain/user/client/request/Dtos'
import type { IUserDto } from '@/domain/user/client/response/Dtos'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { VersionedApiClient } from '@/infrastructure/api/VersionedApiClient'
import { inject } from 'inversify'

export class UserApiClient extends VersionedApiClient implements IUserApiClient {
  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  protected get baseUrl(): string {
    return `${process.env.VUE_APP_BACKEND_SCHEME}://${process.env.VUE_APP_BACKEND_HOST}/user`
  }

  public async getUserById(id: string): Promise<IUserDto> {
    return await this.httpClient.get(`${this.baseUrl}/${id}`)
  }

  public async edit(id: string, request: IUserRequestDto): Promise<void> {
    return await this.httpClient.patch(`${this.baseUrl}/${id}`, request)
  }

  public async deleteUser(id: string): Promise<void> {
    await this.httpClient.delete(`${this.baseUrl}/${id}`)
  }

  public async inviteUser(dto: IUserRequestDto): Promise<void> {
    return await this.httpClient.post(`${this.baseUrl}/invite`, dto)
  }

  public async getUserProfile(): Promise<IUserDto> {
    return await this.httpClient.get(`${this.baseUrl}/profile`)
  }

  public async resetPassword(email: string): Promise<void> {
    return await this.httpClient.post(`${this.baseUrl}/reset_password`, { email })
  }

  public async resendInvitation(userId: string): Promise<void> {
    await this.httpClient.post(`${this.baseUrl}/${userId}/resend_invitation`)
  }
}
