import Dashboard from '@/infrastructure/views/Dashboard.vue'
import Download from '@/infrastructure/views/Download.vue'
import NotFound from '@/infrastructure/views/NotFound.vue'
import { RouteConfig } from 'vue-router'

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home.myLicenses',
    component: Dashboard,
    meta: {
      title: 'dashboard.title',
      breadcrumb: 'dashboard.title',
    },
  },
  {
    path: '/download',
    name: 'download.home',
    component: Download,
    meta: {
      title: 'download.title',
    },
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound,
  },
]
