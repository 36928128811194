import type { IAccessMapper } from '@/domain/licensing/mapper/contract/IAccessMapper'
import { Access, AccessType } from '@/domain/licensing/model/Access'
import { injectable } from 'inversify'

@injectable()
export class AccessMapper implements IAccessMapper {
  public toModel(type: AccessType): Access {
    return new Access(type)
  }
}
