import { UserTokens } from '@/domain/DITokens'
import type { IUserRepository } from '@/domain/user/repository/contract/IUserRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class ResetPasswordUserUseCase {
  constructor(
    @inject(UserTokens.repository.IUserRepository)
    private readonly userRepository: IUserRepository,
  ) {
  }

  public async execute(email: string): Promise<void> {
    await this.userRepository.resetPassword(email)
  }
}
