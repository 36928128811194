import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import SharedDITokens from '@/domain/shared/DITokens'
import PaymentMethodDITokens from '@/domain/stripe/customer/sub-domains/paymentMethod/DITokens'
import {
  CustomerPaymentMethodsException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CustomerPaymentMethodsException'
import {
  CustomerPaymentMethodsFailurePresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/CustomerPaymentMethodsFailurePresentation'
import type {
  ICustomerPaymentMethodsPresenter,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ICustomerPaymentMethodsPresenter'
import DITokens from '@/domain/stripe/customer/sub-domains/subscription/DITokens'
import {
  SubscriptionException,
} from '@/domain/stripe/customer/sub-domains/subscription/model/exception/SubscriptionException'
import {
  SubscriptionPaymentException,
} from '@/domain/stripe/customer/sub-domains/subscription/model/exception/SubscriptionPaymentException'
import type {
  ISubscriptionPresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/ISubscriptionPresentation'
import {
  SubscriptionPaymentFailurePresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/SubscriptionPaymentFailurePresentation'
import type {
  ISubscriptionPresenter,
} from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/ISubscriptionPresenter'
import {
  SubscriptionPresentationInput,
} from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/SubscriptionPresentationInput'
import { inject, injectable } from 'inversify'

@injectable()
export class RetrySubscriptionPaymentPresenter implements ISubscriptionPresenter {

  constructor(
    @inject(DITokens.presenter.ISubscriptionPresenter)
    private readonly subscriptionPresenter: ISubscriptionPresenter,
    @inject(PaymentMethodDITokens.presenter.ICustomerPaymentMethodsPresenter)
    private readonly customerPaymentMethodsPresenter: ICustomerPaymentMethodsPresenter,
    @inject(SharedDITokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
  ) {
  }

  public present(
    input: SubscriptionPresentationInput | SubscriptionException,
  ): ISubscriptionPresentation {

    if (input instanceof SubscriptionPresentationInput) {
      return this.subscriptionPresenter.present(input)
    } else {
      if (input instanceof SubscriptionPaymentException) {
        let message
        if (input.cause instanceof CustomerPaymentMethodsException) {
          const failurePresentation = this.customerPaymentMethodsPresenter.present(input.cause) as CustomerPaymentMethodsFailurePresentation
          message = failurePresentation.message
        } else {
          message = this.localizedStringProvider.getText('billing.overview.mySubscriptions.actions.retry.outcome.cardError')
        }
        return new SubscriptionPaymentFailurePresentation(message)
      } else {
        return this.subscriptionPresenter.present(input)
      }
    }
  }
}
