import { Item } from '@/domain/stripe/quote/model/Item'
import { Product } from '@/domain/stripe/quote/model/Product'
import { TotalAmount } from '@/domain/stripe/quote/model/TotalAmount'
import { UnitAmount } from '@/domain/stripe/quote/model/UnitAmount'

export class LineItem extends Item {
  constructor(
    public readonly id: string,
    public readonly description: string,
    quantity: number,
    public readonly product: Product,
    public readonly unitAmount: UnitAmount,
    public readonly totalAmount: TotalAmount,
  ) {
    super(id, quantity)
  }
}
