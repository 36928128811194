import { ActivationStatus } from '@/domain/licensing/model/ActivationStatus'
import { Device } from '@/domain/licensing/model/Device'
import { User } from '@/domain/licensing/model/User'

export class Activation {
  constructor(
    public readonly activationId: string,
    public readonly device: Device,
    public readonly created: Date,
    public readonly user: User,
    public status: ActivationStatus,
  ) {
  }

  public get isAcquired(): boolean {
    return this.status === ActivationStatus.Active || this.status === ActivationStatus.Acquire
  }

  public revertStatus(): void {
    this.status = this.status === ActivationStatus.Release
      ? ActivationStatus.Active
      : ActivationStatus.Inactive
  }
}
