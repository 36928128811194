import { Points } from '@/domain/points/accounting/model/Points'
import { PointsPresentation } from '@/domain/points/accounting/presentation/PointsPresentation'
import type { IPointsPresenter } from '@/domain/points/accounting/presenter/contract/IPointsPresenter'
import { injectable } from 'inversify'

@injectable()
export class PointsPresenter implements IPointsPresenter {
  public present(points: Points): PointsPresentation {
    return new PointsPresentation(
      points.expiring,
      points.perpetual,
      points.total,
    )
  }
}
