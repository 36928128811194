import { Invoice } from '@/domain/stripe/invoice/model/Invoice'
import { LineItem } from '@/domain/stripe/quote/model/LineItem'
import { RecurringAmount } from '@/domain/stripe/quote/model/RecurringAmount'
import { Upfront } from '@/domain/stripe/quote/model/Upfront'

export class Quote {
  constructor(
    public readonly id: string,
    public readonly status: string,
    public readonly upfront: Upfront,
    public readonly lineItems: LineItem[],
    public readonly recurringAmount?: RecurringAmount,
    public readonly invoice?: Invoice,
  ) {
  }

  public findLineItem(id: string): LineItem | undefined {
    return this.lineItems.find((item) => item.id === id)
  }
}
