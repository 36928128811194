import type { ISubscriptionDto } from '@/domain/stripe/customer/sub-domains/subscription/client/dto/Dtos'
import SubscriptionDITokens from '@/domain/stripe/customer/sub-domains/subscription/DITokens'
import type {
  ISubscriptionItemMapper,
} from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/ISubscriptionItemMapper'
import type {
  ISubscriptionMapper,
} from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/ISubscriptionMapper'
import type { IValidityMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/IValidityMapper'
import type { ISubscription } from '@/domain/stripe/customer/sub-domains/subscription/model/contract/ISubscription'
import { Subscription } from '@/domain/stripe/customer/sub-domains/subscription/model/Subscription'
import InvoiceDITokens from '@/domain/stripe/invoice/DITokens'
import type { IInvoiceMapper } from '@/domain/stripe/invoice/mapper/contract/IInvoiceMapper'
import { inject, injectable } from 'inversify'

@injectable()
export class SubscriptionMapper implements ISubscriptionMapper {

  constructor(
    @inject(SubscriptionDITokens.mapper.ISubscriptionItemMapper)
    private readonly itemMapper: ISubscriptionItemMapper,
    @inject(SubscriptionDITokens.mapper.IValidityMapper)
    private readonly validityMapper: IValidityMapper,
    @inject(InvoiceDITokens.mapper.IInvoiceMapper)
    private readonly invoiceMapper: IInvoiceMapper,
  ) {
  }

  public toModel(dto: ISubscriptionDto): ISubscription {
    return new Subscription(
      dto.id,
      dto.status,
      dto.items.map((itemDto) => this.itemMapper.toModel(itemDto)),
      this.validityMapper.toModel(dto.validity),
      this.invoiceMapper.toModel(dto.latest_invoice),
    )
  }

  public toDto(model: ISubscription): ISubscriptionDto {
    return {
      id: model.id,
      status: model.status,
      items: model.items.map((item) => this.itemMapper.toDto(item)),
      validity: this.validityMapper.toDto(model.validity),
      latest_invoice: this.invoiceMapper.toDto(model.latestInvoice),
    }
  }

}
