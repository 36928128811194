import { TotalAmount } from '@/domain/stripe/invoice/model/TotalAmount'
import { TotalAmountPresentation } from '@/domain/stripe/invoice/presentation/TotalAmountPresentation'
import type { ITotalAmountPresenter } from '@/domain/stripe/invoice/presenter/contract/ITotalAmountPresenter'
import { injectable } from 'inversify'

@injectable()
export class TotalAmountPresenter implements ITotalAmountPresenter {
  public present(totalAmount: TotalAmount): TotalAmountPresentation {
    const formatter = new Intl.NumberFormat('en-US', {
      maximumSignificantDigits: 5,
    })

    return new TotalAmountPresentation(
      totalAmount.currency,
      formatter.format(totalAmount.amount),
      totalAmount.interval,
    )
  }
}
