import type { IToast, IToastService } from '@/infrastructure/services/contract/IToastService'
import { injectable } from 'inversify'
import Vue from 'vue'
import Toasted, { ToastObject } from 'vue-toasted'
import './style/toast.css'

@injectable()
export default class ToastService implements IToastService {
  constructor() {
    Vue.use(Toasted, {
      type: 'info',
      iconPack: 'mdi',
      position: 'bottom-center',
    })
  }

  public inform(message: string, duration?: number): IToast {
    return this.toToast(Vue.toasted.info(message, {
      theme: 'outline',
      duration,
    }))
  }

  public error(message: string): IToast {
    return this.toToast(Vue.toasted.error(message, {
      duration: 10_000,
      type: 'error',
      theme: 'md-toast-error',
      className: 'toast-text-error',
      icon: 'mdi-alert-circle-outline',
      action: {
        text: 'Ok',
        onClick: (_: unknown, toast: ToastObject): void => {
          toast.goAway(0)
        },
      },
    }))
  }

  public success(message: string): IToast {
    return this.toToast(Vue.toasted.success(message, {
      duration: 10_000,
      type: 'success',
      theme: 'md-toast-success',
      className: 'toast-text-success',
      icon: 'mdi-check-circle-outline',
      action: {
        text: 'Ok',
        onClick: (_: unknown, toast: ToastObject): void => {
          toast.goAway(0)
        },
      },
    }))
  }

  public warning(message: string): IToast {
    return this.toToast(Vue.toasted.error(message, {
      duration: 10_000,
      type: 'warning',
      theme: 'md-toast-warning',
      className: 'toast-text-warning',
      icon: 'mdi-information-outline',
      action: {
        text: 'Ok',
        onClick: (_: unknown, toast: ToastObject): void => {
          toast.goAway(0)
        },
      },
    }))
  }

  public clear(): void {
    Vue.toasted.clear()
  }

  private toToast(toastObject: ToastObject): IToast {
    return {
      dismiss(): void {
        toastObject.goAway()
      },
    }
  }
}
