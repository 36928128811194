const apiClient = {
  ICouponCodeApiClient: Symbol(),
}

const mapper = {
  ICouponCodeMapper: Symbol(),
  ICouponMapper: Symbol(),
  IUnitAmountMapper: Symbol(),
}

const repository = {
  ICouponCodeRepository: Symbol(),
}

const presenter = {
  ICouponPresenter: Symbol(),
  ICouponCodePresenter: Symbol(),
  IUnitAmountPresenter: Symbol(),
}

const useCase = {
  ValidateCouponCodeUseCase: Symbol(),
}

export default {
  apiClient,
  mapper,
  repository,
  presenter,
  useCase,
}
