import { VersionedApiClient } from '@/infrastructure/api/VersionedApiClient'

export class StripeApiClient extends VersionedApiClient {

  protected get version(): string {
    return 'v1'
  }

  protected get baseUrl(): string {
    const baseUrl = `${process.env.VUE_APP_BACKEND_SCHEME}://${process.env.VUE_APP_BACKEND_HOST}`
    const domain = 'stripe'

    return `${baseUrl}/${domain}/${this.version}`
  }
}
