const apiClient = {
  IOrganizationApiClient: Symbol('IOrganizationApiClient'),
  IOrganizationMdCsaApiClient: Symbol('IOrganizationMdCsaApiClient'),
}

const mapper = {
  IOrganizationMapper: Symbol('IOrganizationMapper'),
  IStatusMapper: Symbol('IStatusMapper'),
  IUserMapper: Symbol('IUserMapper'),
  ICountryMapper: Symbol('ICountryMapper'),
}

const repository = {
  IOrganizationRepository: Symbol('IOrganizationRepository'),
}

const useCase = {
  GetMyOrganizationUseCase: Symbol('GetMyOrganizationUseCase'),
  EditOrganizationUseCase: Symbol('EditOrganizationUseCase'),
}

export default {
  apiClient,
  mapper,
  repository,
  useCase,
}
