import { StripeTokens } from '@/domain/DITokens'
import { QuoteException } from '@/domain/stripe/quote/exception/QuoteException'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import { Quote } from '@/domain/stripe/quote/model/Quote'
import { QuoteWithMessage } from '@/domain/stripe/quote/model/QuoteWithMessage'
import type { IQuotePresentation } from '@/domain/stripe/quote/presentation/contract/IQuotePresentation'
import type { IQuotePresenter } from '@/domain/stripe/quote/presenter/contract/IQuotePresenter'
import type { IQuoteRepository } from '@/domain/stripe/quote/repository/contract/IQuoteRepository'
import { inject, injectable } from 'inversify'

export class FindOrCreateQuoteRequest {
  constructor(
    public readonly productType: ProductType,
    public readonly copySubscriptionId?: string,
  ) {
  }
}

@injectable()
export class FindOrCreateQuoteUseCase {
  constructor(
    @inject(StripeTokens.QuoteTokens.repository.IQuoteRepository)
    private readonly quoteRepository: IQuoteRepository,
    @inject(StripeTokens.QuoteTokens.presenter.IQuotePresenter)
    private readonly quotePresenter: IQuotePresenter,
  ) {
  }

  public async execute(request: FindOrCreateQuoteRequest): Promise<IQuotePresentation> {
    let outcome: QuoteWithMessage | Quote | QuoteException

    try {
      outcome = await this.quoteRepository.create(
        request.productType,
        request.copySubscriptionId,
      )
    } catch (error) {
      outcome = error as QuoteException
    }

    return this.quotePresenter.present(outcome)
  }
}
