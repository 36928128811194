import { Organization } from '@/domain/organization/model/Organization'
import { CountryPresentation } from '@/domain/statics/presentation/CountryPresentation'
import { trackActionExecutionProgress } from '@/infrastructure/store/ActionLoadingIndicator'
import { IOrganizationDetailsStore } from '@/infrastructure/store/modules/contract/admin/IOrganizationDetails.store'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'AdminOrganizationDetails',
  namespaced: true,
})
export default class OrganizationDetailsStore extends VuexModule implements IOrganizationDetailsStore {
  private _organization = {} as Organization

  public get organization(): Organization {
    return this._organization
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  private _availableCountries: CountryPresentation[] = []

  public get availableCountries(): CountryPresentation[] {
    return this._availableCountries
  }

  @Mutation
  public updateIsLoading(value: boolean) {
    this._isLoading = value
  }

  @Mutation
  public updateOrganization(value: Organization): void {
    this._organization = value
  }

  @Mutation
  public updateAvailableCountries(countries: CountryPresentation[]): void {
    this._availableCountries = countries
  }

  @Mutation
  public reset() {
    this._organization = {} as Organization
    this._isLoading = false
  }

  @HandledAction({ commit: 'updateOrganization' })
  public async fetchOrganization(
    callback: () => Promise<Organization | void>,
  ): Promise<Organization | void> {
    return trackActionExecutionProgress(callback, this.updateIsLoading)
  }

  @HandledAction({ commit: 'updateAvailableCountries' })
  public async fetchCountries(
    action: () => Promise<CountryPresentation[] | void>,
  ): Promise<CountryPresentation[] | void> {

    return action()
  }

  @HandledAction()
  public async save(
    action: () => Promise<void>,
  ): Promise<void> {

    return trackActionExecutionProgress(action, this.updateIsLoading)
  }
}
