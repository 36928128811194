import { StripeTokens } from '@/domain/DITokens'
import type { IQuoteApiClient } from '@/domain/stripe/quote/client/contract/IQuoteApiClient'
import type { ILineItemMapper } from '@/domain/stripe/quote/mapper/contract/ILineItemMapper'
import type { IProductMapper } from '@/domain/stripe/quote/mapper/contract/IProductMapper'
import type { IQuoteMapper } from '@/domain/stripe/quote/mapper/contract/IQuoteMapper'
import type { IQuoteWithMessageMapper } from '@/domain/stripe/quote/mapper/contract/IQuoteWithMessageMapper'
import type { IRecurringAmountMapper } from '@/domain/stripe/quote/mapper/contract/IRecurringAmountMapper'
import type { ITotalAmountMapper } from '@/domain/stripe/quote/mapper/contract/ITotalAmountMapper'
import type { IUnitAmountMapper } from '@/domain/stripe/quote/mapper/contract/IUnitAmountMapper'
import type { IUpfrontAmountMapper } from '@/domain/stripe/quote/mapper/contract/IUpfrontAmountMapper'
import { LineItemMapper } from '@/domain/stripe/quote/mapper/LineItemMapper'
import { ProductMapper } from '@/domain/stripe/quote/mapper/ProductMapper'
import { QuoteMapper } from '@/domain/stripe/quote/mapper/QuoteMapper'
import { QuoteWithMessageMapper } from '@/domain/stripe/quote/mapper/QuoteWithMessageMapper'
import { RecurringAmountMapper } from '@/domain/stripe/quote/mapper/RecurringAmountMapper'
import { TotalAmountMapper } from '@/domain/stripe/quote/mapper/TotalAmountMapper'
import { UnitAmountMapper } from '@/domain/stripe/quote/mapper/UnitAmountMapper'
import { UpfrontAmountMapper } from '@/domain/stripe/quote/mapper/UpfrontAmountMapper'
import type { ILineItemPresenter } from '@/domain/stripe/quote/presenter/contract/ILineItemPresenter'
import type { IProductPresenter } from '@/domain/stripe/quote/presenter/contract/IProductPresenter'
import type { IQuotePresenter } from '@/domain/stripe/quote/presenter/contract/IQuotePresenter'
import type { IRecurringAmountPresenter } from '@/domain/stripe/quote/presenter/contract/IRecurringAmountPresenter'
import type { ITotalAmountPresenter } from '@/domain/stripe/quote/presenter/contract/ITotalAmountPresenter'
import type { IUnitAmountPresenter } from '@/domain/stripe/quote/presenter/contract/IUnitAmountPresenter'
import type { IUpfrontAmountPresenter } from '@/domain/stripe/quote/presenter/contract/IUpfrontAmountPresenter'
import { LineItemPresenter } from '@/domain/stripe/quote/presenter/LineItemPresenter'
import { ProductPresenter } from '@/domain/stripe/quote/presenter/ProductPresenter'
import { QuotePresenter } from '@/domain/stripe/quote/presenter/QuotePresenter'
import { RecurringAmountPresenter } from '@/domain/stripe/quote/presenter/RecurringAmountPresenter'
import { TotalAmountPresenter } from '@/domain/stripe/quote/presenter/TotalAmountPresenter'
import { UnitAmountPresenter } from '@/domain/stripe/quote/presenter/UnitAmountPresenter'
import { UpfrontAmountPresenter } from '@/domain/stripe/quote/presenter/UpfrontAmountPresenter'
import type { IQuoteRepository } from '@/domain/stripe/quote/repository/contract/IQuoteRepository'
import { QuoteMemoryCache, QuoteRepository } from '@/domain/stripe/quote/repository/QuoteRepository'
import { CancelQuoteUseCase } from '@/domain/stripe/quote/useCase/CancelQuoteUseCase'
import { ClearQuoteCacheUseCase } from '@/domain/stripe/quote/useCase/ClearQuoteCacheUseCase'
import { ConvertQuoteUseCase } from '@/domain/stripe/quote/useCase/ConvertQuoteUseCase'
import { EditQuoteLineItemUseCase } from '@/domain/stripe/quote/useCase/EditQuoteLineItemUseCase'
import { FindOrCreateQuoteUseCase } from '@/domain/stripe/quote/useCase/FindOrCreateQuoteUseCase'
import { SelectPointPackUseCase } from '@/domain/stripe/quote/useCase/SelectPointPackUseCase'
import { QuoteApiClient } from '@/infrastructure/api/stripe/quote/QuoteApiClient'
import { Container } from 'inversify'

const sharedCache: QuoteMemoryCache = {}

export function quoteContainer(container: Container): Container {
  initializeHttpClients(container)
  initializeMappers(container)
  initializeRepositories(container)
  initializePresenters(container)
  initializeUseCases(container)

  return container
}

function initializeHttpClients(container: Container): void {
  container
    .bind<IQuoteApiClient>(StripeTokens.QuoteTokens.apiClient.IQuoteApiClient)
    .to(QuoteApiClient)
}

function initializeMappers(container: Container): void {
  container
    .bind<ILineItemMapper>(StripeTokens.QuoteTokens.mapper.ILineItemMapper)
    .to(LineItemMapper)

  container
    .bind<IProductMapper>(StripeTokens.QuoteTokens.mapper.IProductMapper)
    .to(ProductMapper)

  container
    .bind<IQuoteMapper>(StripeTokens.QuoteTokens.mapper.IQuoteMapper)
    .to(QuoteMapper)

  container
    .bind<IQuoteWithMessageMapper>(StripeTokens.QuoteTokens.mapper.IQuoteWithMessageMapper)
    .to(QuoteWithMessageMapper)

  container
    .bind<IRecurringAmountMapper>(StripeTokens.QuoteTokens.mapper.IRecurringAmountMapper)
    .to(RecurringAmountMapper)

  container
    .bind<ITotalAmountMapper>(StripeTokens.QuoteTokens.mapper.ITotalAmountMapper)
    .to(TotalAmountMapper)

  container
    .bind<IUnitAmountMapper>(StripeTokens.QuoteTokens.mapper.IUnitAmountMapper)
    .to(UnitAmountMapper)

  container
    .bind<IUpfrontAmountMapper>(StripeTokens.QuoteTokens.mapper.IUpfrontAmountMapper)
    .to(UpfrontAmountMapper)
}

function initializeRepositories(container: Container): void {

  container
    .bind<QuoteMemoryCache>(StripeTokens.QuoteTokens.repository.IMemoryCache)
    .toConstantValue(sharedCache)

  container
    .bind<IQuoteRepository>(StripeTokens.QuoteTokens.repository.IQuoteRepository)
    .to(QuoteRepository)
}

function initializePresenters(container: Container): void {
  container
    .bind<ILineItemPresenter>(StripeTokens.QuoteTokens.presenter.ILineItemPresenter)
    .to(LineItemPresenter)

  container
    .bind<IProductPresenter>(StripeTokens.QuoteTokens.presenter.IProductPresenter)
    .to(ProductPresenter)

  container
    .bind<IQuotePresenter>(StripeTokens.QuoteTokens.presenter.IQuotePresenter)
    .to(QuotePresenter)

  container
    .bind<IRecurringAmountPresenter>(StripeTokens.QuoteTokens.presenter.IRecurringAmountPresenter)
    .to(RecurringAmountPresenter)

  container
    .bind<ITotalAmountPresenter>(StripeTokens.QuoteTokens.presenter.ITotalAmountPresenter)
    .to(TotalAmountPresenter)

  container
    .bind<IUnitAmountPresenter>(StripeTokens.QuoteTokens.presenter.IUnitAmountPresenter)
    .to(UnitAmountPresenter)

  container
    .bind<IUpfrontAmountPresenter>(StripeTokens.QuoteTokens.presenter.IUpfrontAmountPresenter)
    .to(UpfrontAmountPresenter)
}

function initializeUseCases(container: Container): void {
  container
    .bind<ConvertQuoteUseCase>(StripeTokens.QuoteTokens.useCase.ConvertQuoteUseCase)
    .to(ConvertQuoteUseCase)

  container
    .bind<FindOrCreateQuoteUseCase>(StripeTokens.QuoteTokens.useCase.FindOrCreateQuoteUseCase)
    .to(FindOrCreateQuoteUseCase)

  container
    .bind<EditQuoteLineItemUseCase>(StripeTokens.QuoteTokens.useCase.EditQuoteLineItemUseCase)
    .to(EditQuoteLineItemUseCase)

  container
    .bind<ClearQuoteCacheUseCase>(StripeTokens.QuoteTokens.useCase.ClearQuoteCacheUseCase)
    .to(ClearQuoteCacheUseCase)

  container
    .bind<SelectPointPackUseCase>(StripeTokens.QuoteTokens.useCase.SelectPointPackUseCase)
    .to(SelectPointPackUseCase)

  container
    .bind<CancelQuoteUseCase>(StripeTokens.QuoteTokens.useCase.CancelQuoteUseCase)
    .to(CancelQuoteUseCase)
}
