import { IApplicationDownloadDto } from '@/domain/download/client/dto/Dtos'
import { IDownloadMapper } from '@/domain/download/mapper/contract/IDownloadMapper'
import { ApplicationDownload } from '@/domain/download/model/ApplicationDownload'
import { DownloadType } from '@/domain/download/model/DownloadType'
import { injectable } from 'inversify'

@injectable()
export class DownloadMapper implements IDownloadMapper {
  public toModel(dto: IApplicationDownloadDto): ApplicationDownload {
    return new ApplicationDownload(
      dto.name,
      dto.link,
      dto.version,
      dto.latest,
      dto.type as DownloadType,
    )
  }
}
