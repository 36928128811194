import DITokens from '@/domain/download/DITokens'
import { ApplicationDownload } from '@/domain/download/model/ApplicationDownload'
import type { IDownloadRepository } from '@/domain/download/repository/contract/IDownloadRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class GetLatestVersionDownloadLinkUseCase {
  constructor(
    @inject(DITokens.repository.IApplicationDownloadRepository)
    private readonly applicationDownloadRepository: IDownloadRepository,
  ) {
  }

  async execute(application: string): Promise<ApplicationDownload> {
    return await this.applicationDownloadRepository.loadLatestVersion(application)
  }
}
