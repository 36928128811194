import { InvoicePresentation } from '@/domain/stripe/invoice/presentation/InvoicePresentation'
import { IInvoiceDetailsStore } from '@/infrastructure/store/modules/contract/shop/IInvoiceDetails.store'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'ShopInvoiceDetails',
  namespaced: true,
})
export default class InvoiceDetailsStore extends VuexModule implements IInvoiceDetailsStore {

  private _invoice: InvoicePresentation = {} as InvoicePresentation

  public get invoice(): InvoicePresentation {
    return this._invoice
  }

  @Mutation
  public updateInvoice(value: InvoicePresentation) {
    this._invoice = value
  }

  @Action({commit: 'updateInvoice'})
  public getInvoice(
    callback: () => Promise<InvoicePresentation | void>,
  ): Promise<InvoicePresentation | void> {
    return callback()
  }
}
