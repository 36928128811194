import { LicensingTokens } from '@/domain/DITokens'
import type { IActivationDto } from '@/domain/licensing/client/response/Dtos'
import type { IActivationMapper } from '@/domain/licensing/mapper/contract/IActivationMapper'
import type { IDeviceMapper } from '@/domain/licensing/mapper/contract/IDeviceMapper'
import type { IUserMapper } from '@/domain/licensing/mapper/contract/IUserMapper'
import { Activation } from '@/domain/licensing/model/Activation'
import { ActivationStatus } from '@/domain/licensing/model/ActivationStatus'
import { inject, injectable } from 'inversify'

@injectable()
export class ActivationMapper implements IActivationMapper {
  constructor(
    @inject(LicensingTokens.mapper.IDeviceMapper)
    private readonly deviceMapper: IDeviceMapper,
    @inject(LicensingTokens.mapper.IUserMapper)
    private readonly userMapper: IUserMapper,
  ) {
  }

  public toModel(dto: IActivationDto): Activation {
    const device = this.deviceMapper.toModel(dto.device)
    const user = this.userMapper.toModel({ id: dto.user_id, ...dto.user })

    return new Activation(
      dto.activation_id,
      device,
      new Date(dto.created),
      user,
      dto.is_deleted ? ActivationStatus.Inactive : ActivationStatus.Active,
    )
  }
}
