import { Store } from 'vuex'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const actionLoadingIndicator = (store: Store<any>): void => {

  store.subscribeAction({
    before: (action, state) => {
      state.App._isLoading = true
    },
    after: (action, state) => {
      state.App._isLoading = false
    },
    error: (action, state) => {
      state.App._isLoading = false
    },
  })
}

export async function trackActionExecutionProgress<T>(
  callback: (() => Promise<T | void>),
  progressIndicatorMutation: ((value: boolean) => void),
) {
  progressIndicatorMutation(true)
  let promise: T | void
  try {
    promise = await callback()
  } finally {
    progressIndicatorMutation(false)
  }
  return promise
}
