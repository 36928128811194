import {
  ISetupIntentPresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/contract/ISetupIntentPresentation'

export class SetupIntentPresentation implements ISetupIntentPresentation{
  constructor(
    public readonly id: string,
    public readonly secret: string,
    public readonly status: string,
  ) {
  }

  public get isFailure(): boolean {
    return false
  }
}