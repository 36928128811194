import {
  SubscriptionException,
} from '@/domain/stripe/customer/sub-domains/subscription/model/exception/SubscriptionException'

export class SubscriptionPaymentException extends Error implements SubscriptionException {

  constructor(
    public readonly cause?: Error,
    message?: string,
  ) {
    super(message)
  }
}
