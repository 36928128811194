const apiClient = {
  IAuthorizationApiClient: Symbol('IAuthorizationApiClient'),
}

const repository = {
  IAuthorizationRepository: Symbol('IAuthorizationRepository'),
}

const useCase = {
  RefreshTokenUseCase: Symbol('RefreshTokenUseCase'),
  ConfirmAccessUseCase: Symbol('ConfirmAccessUseCase'),
}

export default {
  apiClient,
  repository,
  useCase,
}
