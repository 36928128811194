import type { InvoiceLineDictionaryDtoType } from '@/domain/stripe/invoice/client/dto/Dtos'
import type { IInvoiceLinesMapper } from '@/domain/stripe/invoice/mapper/contract/IInvoiceLineMapper'
import { InvoiceLine } from '@/domain/stripe/invoice/model/InvoiceLine'
import { injectable } from 'inversify'

@injectable()
export class InvoiceLinesMapper implements IInvoiceLinesMapper {

  public toDto(models: InvoiceLine[]): InvoiceLineDictionaryDtoType {

    return models
      .reduce((final, previous) => {
        final[previous.name] = {
          quantity: previous.quantity,
          platform: previous.platform,
          product_info: previous.productMetadata,
        }
        return final
      }, {} as InvoiceLineDictionaryDtoType)
  }

  public toModels(dto: InvoiceLineDictionaryDtoType): InvoiceLine[] {
    return Object.keys(dto).map((key) => new InvoiceLine(key, dto[key].quantity ?? dto[key], dto[key].platform ?? dto[key].product_info?.platform , dto[key].product_info))
  }
}
