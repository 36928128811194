import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { injectable } from 'inversify'

@injectable()
export abstract class ApiClient {
  constructor(
    protected readonly httpClient: IHttpClient,
  ) {
  }
}
