import { StripeTokens } from '@/domain/DITokens'
import type { ICouponCodeApiClient } from '@/domain/stripe/couponCode/client/contract/ICouponCodeApiClient'
import type { ICouponCodeMapper } from '@/domain/stripe/couponCode/mapper/contract/ICouponCodeMapper'
import type { ICouponMapper } from '@/domain/stripe/couponCode/mapper/contract/ICouponMapper'
import type { IUnitAmountMapper } from '@/domain/stripe/couponCode/mapper/contract/IUnitAmountMapper'
import { CouponCodeMapper } from '@/domain/stripe/couponCode/mapper/CouponCodeMapper'
import { CouponMapper } from '@/domain/stripe/couponCode/mapper/CouponMapper'
import { UnitAmountMapper } from '@/domain/stripe/couponCode/mapper/UnitAmountMapper'
import type { ICouponCodePresenter } from '@/domain/stripe/couponCode/presenter/contract/ICouponCodePresenter'
import type { IUnitAmountPresenter } from '@/domain/stripe/couponCode/presenter/contract/IUnitAmountPresenter'
import { CouponCodePresenter } from '@/domain/stripe/couponCode/presenter/CouponCodePresenter'
import { CouponPresenter } from '@/domain/stripe/couponCode/presenter/CouponPresenter'
import { UnitAmountPresenter } from '@/domain/stripe/couponCode/presenter/UnitAmountPresenter'
import type { ICouponCodeRepository } from '@/domain/stripe/couponCode/repository/contract/ICouponCodeRepository'
import { CouponCodeRepository } from '@/domain/stripe/couponCode/repository/CouponCodeRepository'
import { ValidateCouponCodeUseCase } from '@/domain/stripe/couponCode/useCase/ValidateCouponCodeUseCase'
import type { ICouponPresenter } from '@/domain/stripe/invoice/presenter/contract/ICouponPresenter'
import { CouponApiClient } from '@/infrastructure/api/stripe/coupon/CouponApiClient'
import { Container } from 'inversify'

export function couponContainer(container: Container): Container {
  initializeHttpClients(container)
  initializeMappers(container)
  initializeRepositories(container)
  initializePresenters(container)
  initializeUseCases(container)

  return container
}

function initializeHttpClients(container: Container): void {
  container
    .bind<ICouponCodeApiClient>(StripeTokens.CouponTokens.apiClient.ICouponCodeApiClient)
    .to(CouponApiClient)
}

function initializeMappers(container: Container): void {
  container
    .bind<ICouponMapper>(StripeTokens.CouponTokens.mapper.ICouponMapper)
    .to(CouponMapper)

  container
    .bind<ICouponCodeMapper>(StripeTokens.CouponTokens.mapper.ICouponCodeMapper)
    .to(CouponCodeMapper)

  container
    .bind<IUnitAmountMapper>(StripeTokens.CouponTokens.mapper.IUnitAmountMapper)
    .to(UnitAmountMapper)
}

function initializeRepositories(container: Container): void {
  container
    .bind<ICouponCodeRepository>(StripeTokens.CouponTokens.repository.ICouponCodeRepository)
    .to(CouponCodeRepository)
}

function initializePresenters(container: Container): void {
  container
    .bind<ICouponCodePresenter>(StripeTokens.CouponTokens.presenter.ICouponCodePresenter)
    .to(CouponCodePresenter)

  container
    .bind<ICouponPresenter>(StripeTokens.CouponTokens.presenter.ICouponPresenter)
    .to(CouponPresenter)

  container
    .bind<IUnitAmountPresenter>(StripeTokens.CouponTokens.presenter.IUnitAmountPresenter)
    .to(UnitAmountPresenter)
}

function initializeUseCases(container: Container): void {
  container
    .bind<ValidateCouponCodeUseCase>(StripeTokens.CouponTokens.useCase.ValidateCouponCodeUseCase)
    .to(ValidateCouponCodeUseCase)
}
