import { IInvoicePresentation } from '@/domain/stripe/invoice/presentation/contract/IInvoicePresentation'
import { InvoiceLinePresentation } from '@/domain/stripe/invoice/presentation/InvoiceLinePresentation'
import { TotalAmountPresentation } from '@/domain/stripe/invoice/presentation/TotalAmountPresentation'

export class InvoicePresentation implements IInvoicePresentation {

  public static readonly EMPTY = new InvoicePresentation(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    [InvoiceLinePresentation.EMPTY],
    '',
    TotalAmountPresentation.EMPTY,
    TotalAmountPresentation.EMPTY,
    '',
    '',
    null,
  )

  constructor(
    public readonly id: string,
    public readonly status: string,
    public readonly clientSecret: string,
    public readonly paymentIntentId: string,
    public readonly number: string,
    public readonly date: string,
    public readonly productNames: string,
    public readonly products: InvoiceLinePresentation[],
    public readonly summaryHtml: string,
    public readonly subtotal: TotalAmountPresentation,
    public readonly total: TotalAmountPresentation,
    public readonly downloadLink: string,
    public readonly discountSummaryHtml: string,
    public readonly subscriptionId: string | null,
  ) {
  }

  public get productType(): string {
    return this.products[0].productMetadata.type
  }

  public get isFailure(): boolean {
    return false
  }
}
