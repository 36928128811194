import { BillingDetailsPresentation } from '@/domain/stripe/customer/presentation/BillingDetailsPresentation'

export class NoRegisteredBillingDetailsPresentation extends BillingDetailsPresentation {

  constructor(
    private readonly _message: string,
  ) {
    super()
  }

  public get message(): string {
    return this._message
  }

  public get registeredInfoAvailable(): boolean {
    return false
  }
}
