import { ApiTokens } from '@/infrastructure/api/DITokens'
import { HttpClient, IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { middlewareContainer } from '@/infrastructure/api/http/middleware/DIContainer'
import { getSsoCookieOrThrow } from '@/infrastructure/utils/extensions/js-cookie'
import { Container } from 'inversify'

export function apiContainer(container: Container): Container {

  middlewareContainer(container)
  container
    .bind<IHttpClient>(ApiTokens.httpClient)
    .toConstantValue(new HttpClient())

  container
    .bind<IHttpClient>(ApiTokens.authenticatedHttpClient)
    .toConstantValue(new HttpClient(() => getSsoCookieOrThrow()._token))

  return container
}
