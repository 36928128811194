export class TotalAmountPresentation {

  public static readonly EMPTY = new TotalAmountPresentation('', '', '')

  constructor(
    public readonly currency: string,
    public readonly amount: string,
    public readonly interval: string,
  ) {
  }
}
