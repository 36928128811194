import { StripeTokens } from '@/domain/DITokens'
import type { ILineItemDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { ILineItemMapper } from '@/domain/stripe/quote/mapper/contract/ILineItemMapper'
import type { IProductMapper } from '@/domain/stripe/quote/mapper/contract/IProductMapper'
import type { ITotalAmountMapper } from '@/domain/stripe/quote/mapper/contract/ITotalAmountMapper'
import type { IUnitAmountMapper } from '@/domain/stripe/quote/mapper/contract/IUnitAmountMapper'
import { LineItem } from '@/domain/stripe/quote/model/LineItem'
import { inject, injectable } from 'inversify'

@injectable()
export class LineItemMapper implements ILineItemMapper {
  constructor(
    @inject(StripeTokens.QuoteTokens.mapper.IUnitAmountMapper)
    private readonly unitAmountMapper: IUnitAmountMapper,
    @inject(StripeTokens.QuoteTokens.mapper.IProductMapper)
    private readonly productMapper: IProductMapper,
    @inject(StripeTokens.QuoteTokens.mapper.ITotalAmountMapper)
    private readonly totalAmountMapper: ITotalAmountMapper,
  ) {
  }

  public toModel(lineItem: ILineItemDto): LineItem {
    return new LineItem(
      lineItem.id,
      lineItem.description,
      lineItem.quantity,
      this.productMapper.toModel(lineItem.product),
      this.unitAmountMapper.toModel(lineItem.unit_amount),
      this.totalAmountMapper.toModel(lineItem.total_amount),
    )
  }
}
