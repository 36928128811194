import { InvoiceLine } from '@/domain/stripe/invoice/model/InvoiceLine'
import { InvoiceLinePresentation } from '@/domain/stripe/invoice/presentation/InvoiceLinePresentation'
import type { IInvoiceLinePresenter } from '@/domain/stripe/invoice/presenter/contract/IInvoiceLinePresenter'
import { injectable } from 'inversify'

@injectable()
export class InvoiceLinePresenter implements IInvoiceLinePresenter {
  public present(product: InvoiceLine): InvoiceLinePresentation {
    return new InvoiceLinePresentation(
      product.name,
      product.quantity,
      product.platform,
      product.productMetadata,
    )
  }
}
