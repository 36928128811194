import type { ITotalAmountDto } from '@/domain/stripe/invoice/client/dto/Dtos'
import type { ITotalAmountMapper } from '@/domain/stripe/invoice/mapper/contract/ITotalAmountMapper'
import { TotalAmount } from '@/domain/stripe/invoice/model/TotalAmount'
import { injectable } from 'inversify'

@injectable()
export class TotalAmountMapper implements ITotalAmountMapper {

  public toModel(dto: ITotalAmountDto): TotalAmount {
    return new TotalAmount(
      dto.currency,
      dto.amount,
      dto.interval,
    )
  }

  public toDto(model: TotalAmount): ITotalAmountDto {
    return {
      currency: model.currency,
      amount: model.amount,
      interval: model.interval,
    }
  }
}
