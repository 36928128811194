import type {
  ISetupIntentPresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/contract/ISetupIntentPresentation'

export class SetupIntentFailurePresentation implements ISetupIntentPresentation {
  constructor(
    public readonly errorMessage: string,
  ) {
  }

  public get isFailure(): boolean {
    return true
  }
}