import type { IValidityDto } from '@/domain/stripe/customer/sub-domains/subscription/client/dto/Dtos'
import type { IValidityMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/IValidityMapper'
import { Validity } from '@/domain/stripe/customer/sub-domains/subscription/model/Validity'
import { injectable } from 'inversify'

@injectable()
export class ValidityMapper implements IValidityMapper {

  public toModel(dto: IValidityDto): Validity {
    return new Validity(
      new Date(dto.started_at),
      new Date(dto.valid_until),
      dto.auto_renew,
    )
  }

  public toDto(model: Validity): IValidityDto {
    const isoStringStartedAt = model.startedAt.toISOString()
    const isoStringValidAt = model.validUntil.toISOString()
    const isoStringPartsStartedAt = isoStringStartedAt.split('T')
    const isoStringPartsValidAt = isoStringValidAt.split('T')
    return {
      started_at: isoStringPartsStartedAt.length ? isoStringPartsStartedAt[0] : '',
      valid_until: isoStringPartsValidAt.length ? isoStringPartsValidAt[0] : '',
      auto_renew: model.autoRenew,
    }
  }
}
