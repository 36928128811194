import { ICountryDto } from '@/domain/organization/client/response/Dtos'
import { ICountryMapper } from '@/domain/organization/mapper/contract/ICountryMapper'
import { Country } from '@/domain/organization/model/Country'
import { injectable } from 'inversify'

@injectable()
export class CountryMapper implements ICountryMapper {
  public toModel(dto: ICountryDto): Country {
    return new Country(
      dto.id,
      dto.name,
    )
  }
}
