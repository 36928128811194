import { SharedTokens } from '@/domain/DITokens'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import { Container } from 'inversify'

export function sharedContainer(
  container: Container,
  localizedStringProvider: ILocalizedStringProvider,
): Container {
  container
    .bind<ILocalizedStringProvider>(SharedTokens.ILocalizedStringProvider)
    .toConstantValue(localizedStringProvider)

  return container
}
