import { InvoicePresentation } from '@/domain/stripe/invoice/presentation/InvoicePresentation'
import type { IQuotePresentation } from '@/domain/stripe/quote/presentation/contract/IQuotePresentation'
import { LineItemPresentation } from '@/domain/stripe/quote/presentation/LineItemPresentation'
import { RecurringAmountPresentation } from '@/domain/stripe/quote/presentation/RecurringAmountPresentation'
import { UpfrontAmountPresentation } from '@/domain/stripe/quote/presentation/UpfrontAmountPresentation'

export class QuoteSuccessPresentation implements IQuotePresentation {
  constructor(
    public readonly id: string,
    public readonly status: string,
    public readonly upfrontAmount: UpfrontAmountPresentation,
    public readonly lineItems: LineItemPresentation[],
    public readonly recurringAmount?: RecurringAmountPresentation,
    public readonly invoice?: InvoicePresentation,
    public readonly message?: string,
  ) {
  }

  public get isFailure(): boolean {
    return false
  }

  public get hasMessage(): boolean {
    return !!this.message
  }
}
