import type { ICardDto } from '@/domain/stripe/customer/sub-domains/paymentMethod/client/dto/Dtos'
import type { ICardMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/ICardMapper'
import { Card } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/Card'
import { injectable } from 'inversify'

@injectable()
export class CardMapper implements ICardMapper {
  public toModel(card: ICardDto): Card {
    return new Card(
      card.brand,
      card.fingerprint,
      card.last_4_digits,
      card.expiration_month,
      card.expiration_year,
    )
  }
}
