import type { IUnitAmountDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { IUnitAmountMapper } from '@/domain/stripe/quote/mapper/contract/IUnitAmountMapper'
import { UnitAmount } from '@/domain/stripe/quote/model/UnitAmount'
import { injectable } from 'inversify'

@injectable()
export class UnitAmountMapper implements IUnitAmountMapper {
  public toModel(dto: IUnitAmountDto): UnitAmount {
    return new UnitAmount(
      dto.currency,
      dto.amount,
      dto.interval,
    )
  }

  public toDto(model: UnitAmount): IUnitAmountDto {
    return {
      currency: model.currency,
      amount: model.amount,
      interval: model.interval,
    }
  }
}
