import { UnitAmount } from '@/domain/stripe/couponCode/model/UnitAmount'

export class Coupon {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly amountOff: UnitAmount,
  ) {
  }
}
