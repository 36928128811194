import { OrganizationTokens } from '@/domain/DITokens'
import { IOrganizationApiClient } from '@/domain/organization/client/contract/IOrganizationApiClient'
import { IOrganizationMdCsaApiClient } from '@/domain/organization/client/contract/IOrganizationMdCsaApiClient'
import { ICountryMapper } from '@/domain/organization/mapper/contract/ICountryMapper'
import { IOrganizationMapper } from '@/domain/organization/mapper/contract/IOrganizationMapper'
import { IStatusMapper } from '@/domain/organization/mapper/contract/IStatusMapper'
import { IUserMapper } from '@/domain/organization/mapper/contract/IUserMapper'
import { CountryMapper } from '@/domain/organization/mapper/CountryMapper'
import { OrganizationMapper } from '@/domain/organization/mapper/OrganizationMapper'
import { StatusMapper } from '@/domain/organization/mapper/StatusMapper'
import { UserMapper } from '@/domain/organization/mapper/UserMapper'
import { IOrganizationRepository } from '@/domain/organization/repository/contract/IOrganizationRepository'
import { OrganizationRepository } from '@/domain/organization/repository/OrganizationRepository'
import { EditOrganizationUseCase } from '@/domain/organization/useCase/EditOrganizationUseCase'
import { GetMyOrganizationUseCase } from '@/domain/organization/useCase/GetMyOrganizationUseCase'
import { OrganizationApiClient } from '@/infrastructure/api/organization/OrganizationApiClient'
import { OrganizationMdCsaApiClient } from '@/infrastructure/api/organization/OrganizationMdCsaApiClient'
import { Container } from 'inversify'

export function organizationContainer(container: Container): void {
  initializeHttpClients(container)
  initializeMapper(container)
  initializeRepository(container)
  initializeUseCase(container)
}

function initializeHttpClients(container: Container): void {
  container
    .bind<IOrganizationApiClient>(OrganizationTokens.apiClient.IOrganizationApiClient)
    .to(OrganizationApiClient)

  container
    .bind<IOrganizationMdCsaApiClient>(OrganizationTokens.apiClient.IOrganizationMdCsaApiClient)
    .to(OrganizationMdCsaApiClient)
}

function initializeMapper(container: Container): void {
  container
    .bind<IOrganizationMapper>(OrganizationTokens.mapper.IOrganizationMapper)
    .to(OrganizationMapper)

  container
    .bind<IStatusMapper>(OrganizationTokens.mapper.IStatusMapper)
    .to(StatusMapper)

  container
    .bind<IUserMapper>(OrganizationTokens.mapper.IUserMapper)
    .to(UserMapper)

  container
    .bind<ICountryMapper>(OrganizationTokens.mapper.ICountryMapper)
    .to(CountryMapper)
}

function initializeRepository(container: Container): void {
  container
    .bind<IOrganizationRepository>(OrganizationTokens.repository.IOrganizationRepository)
    .to(OrganizationRepository)
}

function initializeUseCase(container: Container): void {
  container
    .bind<GetMyOrganizationUseCase>(OrganizationTokens.useCase.GetMyOrganizationUseCase)
    .to(GetMyOrganizationUseCase)

  container
    .bind<EditOrganizationUseCase>(OrganizationTokens.useCase.EditOrganizationUseCase)
    .to(EditOrganizationUseCase)
}
