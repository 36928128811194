import { Product } from '@/domain/stripe/quote/model/Product'
import { ProductPresentation } from '@/domain/stripe/quote/presentation/ProductPresentation'
import type { IProductPresenter } from '@/domain/stripe/quote/presenter/contract/IProductPresenter'
import { injectable } from 'inversify'

@injectable()
export class ProductPresenter implements IProductPresenter {
  public present(product: Product): ProductPresentation {
    return new ProductPresentation(
      product.id,
      product.name,
      product.description,
      product.metadata,
    )
  }
}
