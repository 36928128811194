export class Customer {

  constructor(
    private _company: string,
    private _email: string,
    private _phoneNumber: string,
    private _website: string | null,
    private _streetAddress: string,
    private _city: string,
    private _state: string,
    private _postalCode: string,
    private _countryId: string,
    private _countryName: string,
    private _countryCode: string,
    private readonly _id?: string | null,
  ) {
  }

  get id(): string|null {
    return this._id ?? null
  }

  get company(): string {
    return this._company
  }

  set company(value: string) {
    this._company = value
  }

  get email(): string {
    return this._email
  }

  set email(value: string) {
    this._email = value
  }

  get phoneNumber(): string {
    return this._phoneNumber
  }

  set phoneNumber(value: string) {
    this._phoneNumber = value
  }

  get website(): string | null {
    return this._website
  }

  set website(value: string | null) {
    this._website = value
  }

  get streetAddress(): string {
    return this._streetAddress
  }

  set streetAddress(value: string) {
    this._streetAddress = value
  }

  get city(): string {
    return this._city
  }

  set city(value: string) {
    this._city = value
  }

  get state(): string {
    return this._state
  }

  set state(value: string) {
    this._state = value
  }

  get postalCode(): string {
    return this._postalCode
  }

  set postalCode(value: string) {
    this._postalCode = value
  }

  get countryId(): string {
    return this._countryId
  }

  set countryId(value: string) {
    this._countryId = value
  }

  get countryName(): string {
    return this._countryName
  }

  set countryName(value: string) {
    this._countryName = value
  }

  get countryCode(): string {
    return this._countryCode
  }

  set countryCode(value: string) {
    this._countryCode = value
  }
}
