import { SubscriptionItem } from '@/domain/stripe/customer/sub-domains/subscription/model/SubscriptionItem'
import type { ISubscriptionPresentation } from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/ISubscriptionPresentation'
import { SubscriptionStatusPresentation } from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/SubscriptionStatusPresentation'
import type { InvoicePresentation } from '@/domain/stripe/invoice/presentation/InvoicePresentation'

export class SubscriptionPresentation implements ISubscriptionPresentation {
  constructor(
    public readonly id: string,
    public readonly status: SubscriptionStatusPresentation,
    public readonly quantity: number,
    public readonly seatsBreakdown: string,
    public readonly startedAt: string,
    public readonly renewalDate: string,
    public readonly latestInvoice: InvoicePresentation,
    public readonly items: SubscriptionItem[],
  ) {
  }
}
