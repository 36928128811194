export class UnitAmountPresentation {
  public static readonly EMPTY = new UnitAmountPresentation(
    '',
    0,
  )

  constructor(
    public readonly currency: string,
    public readonly amount: number,
  ) {
  }
}
