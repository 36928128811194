import { Points } from '@/domain/points/accounting/model/Points'

export class TransactionDetails {
  constructor(
    public amount: string,
    public detailedAmount: Points,
    public transactionCode: string,
    public operation: string,
    public description: string,
    public balance?: Points,
  ) {
  }
}
