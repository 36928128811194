import type { IRecurringAmountDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { IRecurringAmountMapper } from '@/domain/stripe/quote/mapper/contract/IRecurringAmountMapper'
import { RecurringAmount } from '@/domain/stripe/quote/model/RecurringAmount'
import { injectable } from 'inversify'

@injectable()
export class RecurringAmountMapper implements IRecurringAmountMapper {
  public toModel(recurringAmount: IRecurringAmountDto): RecurringAmount {
    return new RecurringAmount(
      recurringAmount.currency,
      recurringAmount.amount,
      recurringAmount.interval,
    )
  }
}
