import i18n from '@/infrastructure/plugins/i18n'
import { driver, type DriveStep } from 'driver.js'

export const tourSteps: DriveStep[] =
  [
    {
      element: '[data-tour="welcome"]',
      popover: {
        title: i18n.tc('tour.step1.title'),
        description: i18n.tc('tour.step1.description'),
        side: 'bottom',
        align: 'center',
        popoverClass: 'step-1',
      },
    },
    {
      element: '[data-tour="app.sidebar.dashboard"]',
      popover: {
        title: i18n.tc('tour.step2.title'),
        description: i18n.tc('tour.step2.description'),
        popoverClass: 'step-2',
      },
    },
    {
      element: '[data-tour="my-licenses"]',
      popover: {
        title: i18n.tc('tour.step3.title'),
        description: i18n.tc('tour.step3.description'),
        popoverClass: 'step-3',
        side: 'right',
      },
    },
    {
      element: '[data-tour="points"]',
      popover: {
        title: i18n.tc('tour.step4.title'),
        description: i18n.tc('tour.step4.description'),
      },
    },
    {
      element: '[data-tour="app.sidebar.store.title"]',
      popover: {
        title: i18n.tc('tour.step5.title'),
        description: i18n.tc('tour.step5.description'),
      },
    },
    {
      element: '[data-tour="app.sidebar.download"]',
      popover: {
        title: i18n.tc('tour.step6.title'),
        description: i18n.tc('tour.step6.description'),
      },
    },
    {
      element: '[data-tour="app.sidebar.admin.title"]',
      popover: {
        title: i18n.tc('tour.step7.title'),
        description: i18n.tc('tour.step7.description'),
      },
    },
    {
      element: '[data-tour="lp360-cloud"]',
      popover: {
        title: i18n.tc('tour.step8.title'),
        description: i18n.tc('tour.step8.description'),
      },
    },
    {
      element: '[data-tour="account"]',
      popover: {
        title: i18n.tc('tour.step9.title'),
        description: i18n.tc('tour.step9.description'),
      },
    },
    {
      element: '[data-tour="balance"]',
      popover: {
        title: i18n.tc('tour.step10.title'),
        description: i18n.tc('tour.step10.description'),
        popoverClass: 'step-10',
      },
    },
  ]

export const driverObj= driver({
  showProgress: false,
  allowKeyboardControl: true,
  overlayColor: 'rgba(0,0,0,0.5)',
  stagePadding: 0,
  allowClose: false,
  prevBtnText: `<i class="mdi mdi-arrow-left" style="margin-right: 6px;"></i>${i18n.tc('tour.btn.previous')}`,
  nextBtnText: `<i class="mdi mdi-arrow-right" style="margin-right: 6px;"></i>${i18n.tc('tour.btn.next')}`,
  doneBtnText: `<i class="mdi mdi-checkbox-marked-circle" style="margin-right: 6px; font-size: 16px;"></i>${i18n.tc('tour.btn.done')}`,
  onPopoverRender: (popover) => {
    if (!driverObj.hasPreviousStep()) {
      const previousButton = document.createElement('button')
      const nextButton = document.createElement('button')

      previousButton.innerHTML = `<i class="mdi mdi-arrow-left" style="margin-right: 6px;"></i>${i18n.tc('tour.btn.maybeLater')}`
      previousButton.classList.add('previous-btn-custom')
      popover.footerButtons.appendChild(previousButton)

      previousButton.addEventListener('click', () => {
        driverObj.destroy()
      })

      nextButton.innerHTML = `<i class="mdi mdi-checkbox-marked-circle" style="margin-right: 6px; font-size: 16px;"></i>${i18n.tc('tour.btn.letsStart')}`
      nextButton.classList.add('next-btn-custom')
      popover.footerButtons.appendChild(nextButton)

      nextButton.addEventListener('click', () => {
        driverObj.moveNext()
      })
    }
  },
  steps: tourSteps,
})

export const dashboardExcludedStepsElements = ['[data-tour="my-licenses"]', '[data-tour="points"]']

