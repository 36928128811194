import type { IPriceDto } from '@/domain/stripe/customer/sub-domains/subscription/client/dto/Dtos'
import type { IPriceMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/IPriceMapper'
import { Price } from '@/domain/stripe/customer/sub-domains/subscription/model/Price'
import { QuoteTokens } from '@/domain/stripe/DITokens'
import type { IUnitAmountMapper } from '@/domain/stripe/quote/mapper/contract/IUnitAmountMapper'
import { inject, injectable } from 'inversify'

@injectable()
export class PriceMapper implements IPriceMapper {
  constructor(
    @inject(QuoteTokens.mapper.IUnitAmountMapper)
    private readonly unitAmountMapper: IUnitAmountMapper,
  ) {
  }

  public toModel(dto: IPriceDto): Price {
    return new Price(
      dto.id,
      this.unitAmountMapper.toModel(dto.unit_amount),
    )
  }

  public toDto(model: Price): IPriceDto {
    return {
      id: model.id,
      unit_amount: this.unitAmountMapper.toDto(model.unitAmount),
    }
  }
}
