import { authenticationContainer } from '@/domain/authenticating/authentication/DIContainer'
import { authorizationContainer } from '@/domain/authenticating/authorization/DIContainer'
import { Container } from 'inversify'

export function authenticatingContainer(container: Container): Container {
  authenticationContainer(container)
  authorizationContainer(container)

  return container
}

