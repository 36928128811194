import { TotalAmount } from '@/domain/stripe/quote/model/TotalAmount'
import { TotalAmountPresentation } from '@/domain/stripe/quote/presentation/TotalAmountPresentation'
import type { ITotalAmountPresenter } from '@/domain/stripe/quote/presenter/contract/ITotalAmountPresenter'
import { injectable } from 'inversify'

@injectable()
export class TotalAmountPresenter implements ITotalAmountPresenter {
  public present(totalAmount: TotalAmount): TotalAmountPresentation {
    return new TotalAmountPresentation(
      totalAmount.currency,
      totalAmount.amount,
      totalAmount.interval,
    )
  }
}
