import { LicensingTokens } from '@/domain/DITokens'
import { IManageLicenseActivationRequestDto } from '@/domain/licensing/client/request/Dtos'
import { IManageLicenseActivationsResponseDto } from '@/domain/licensing/client/response/Dtos'
import { Activation } from '@/domain/licensing/model/Activation'
import { ActivationStatus } from '@/domain/licensing/model/ActivationStatus'
import type { ILicenseRepository } from '@/domain/licensing/repository/contract/ILicenseRepository'
import { inject, injectable } from 'inversify'

export class ManageLicenseActivationsRequest {
  constructor(
    public readonly licenseId: string,
    public readonly activations: Activation[],
    public readonly licenseType: string,
  ) {
  }
}

@injectable()
export class ManageLicenseActivationsUseCase {
  constructor(
    @inject(LicensingTokens.repository.ILicenseRepository)
    private readonly licenseRepository: ILicenseRepository,
  ) {
  }

  public async execute(request: ManageLicenseActivationsRequest): Promise<Activation[]> {
    const activations = request.activations.filter(activation => (
      activation.status === ActivationStatus.Acquire
      || activation.status === ActivationStatus.Release
    ))

    if (activations.length > 0) {
      await this.modifyLicenseActivations(request, activations)
    }

    return request.activations.filter(activation => activation.isAcquired)
  }

  private async modifyLicenseActivations(request: ManageLicenseActivationsRequest, activations: Activation[]): Promise<void> {
    const requestDtos = activations.map((activation): IManageLicenseActivationRequestDto => ({
      user_id: activation.user.id,
      activation_id: activation.activationId,
      license_type: request.licenseType,
      action: activation.status,
    }))
    const response = await this.licenseRepository.manageLicenseActivations(request.licenseId, requestDtos)

    if (response.failure.length > 0) {
      this.revertActivations(request.activations, response)
    }
  }

  private revertActivations(activations: Activation[], response: IManageLicenseActivationsResponseDto): void {
    for (const dto of response.failure) {
      const activation = activations.find(activation => activation.user.id === dto.user_id)
      if (activation) {
        activation.revertStatus()
      }
    }
  }
}
