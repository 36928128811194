import { LicensingTokens } from '@/domain/DITokens'
import { License } from '@/domain/licensing/model/License'
import type { ILicenseRepository } from '@/domain/licensing/repository/contract/ILicenseRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class ListLicenseActivationsUserUseCase {
  constructor(
    @inject(LicensingTokens.repository.ILicenseRepository)
    private readonly licenseRepository: ILicenseRepository,
  ) {
  }

  public async execute(userId: string): Promise<License[]> {
    return await this.licenseRepository.getLicenseActivationsByUserId(userId)
  }
}
