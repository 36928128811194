import type {
  ICustomerPaymentMethodsPresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/contract/ICustomerPaymentMethodsPresentation'
import { PaymentMethodPresentation } from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/PaymentMethodPresentation'

export class CustomerPaymentMethodsPresentation implements ICustomerPaymentMethodsPresentation {

  public static readonly EMPTY = new CustomerPaymentMethodsPresentation('', [])

  constructor(
    public readonly customerId: string,
    public readonly paymentMethods: PaymentMethodPresentation[],
  ) {
  }

  public get registeredInfoAvailable(): boolean {
    return this.paymentMethods.length > 0
  }
}
