import { InvoiceLine } from '@/domain/stripe/invoice/model/InvoiceLine'

export class InvoiceLinePresentation {

  public static readonly EMPTY = new InvoiceLine('', 0, '', {})

  constructor(
    public readonly name: string,
    public readonly quantity: number,
    public readonly platform: string,
    public readonly productMetadata: Record<string, string>,
  ) {
  }

}
