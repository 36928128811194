import type { IInvoiceApiClient } from '@/domain/stripe/invoice/client/contract/IInvoiceApiClient'
import type { IInvoiceDto } from '@/domain/stripe/invoice/client/dto/Dtos'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { StripeApiClient } from '@/infrastructure/api/stripe/StripeApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class InvoiceApiClient extends StripeApiClient implements IInvoiceApiClient {

  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  public async getCustomerInvoice(invoiceId: string, type: ProductType): Promise<IInvoiceDto> {
    const requestUrl = `${this.baseUrl}/customer/invoice/${type}/${invoiceId}`

    return await this.httpClient.get(requestUrl)
  }

  public async getCustomerInvoices(): Promise<IInvoiceDto[]> {
    const requestUrl = `${this.baseUrl}/customer/invoice`

    return await this.httpClient.get(requestUrl)
  }
}
