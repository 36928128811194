import type { TokensDto } from '@/domain/authenticating/authorization/client/response/TokensDto'
import { RefreshTokenException } from '@/domain/authenticating/authorization/exception/RefreshTokenException'
import { UnauthorizedException } from '@/domain/authenticating/authorization/exception/UnauthorizedException'
import type { IAuthorizationRepository } from '@/domain/authenticating/authorization/repository/contract/IAuthorizationRepository'
import { AuthorizationTokens } from '@/domain/authenticating/DITokens'
import { inject, injectable } from 'inversify'

@injectable()
export class RefreshTokenUseCase {
  constructor(
    @inject(AuthorizationTokens.repository.IAuthorizationRepository)
    private readonly repository: IAuthorizationRepository,
  ) {
  }

  public async execute(refreshToken: string): Promise<TokensDto> {
    try {
      return await this.repository.refresh(refreshToken)
    } catch (error) {
      if (error instanceof RefreshTokenException) {
        throw new UnauthorizedException('Invalid Token.')
      }

      throw error
    }
  }
}
