import DITokens from '@/domain/stripe/customer/sub-domains/subscription/DITokens'
import type { ISubscription } from '@/domain/stripe/customer/sub-domains/subscription/model/contract/ISubscription'
import {
  SubscriptionException,
} from '@/domain/stripe/customer/sub-domains/subscription/model/exception/SubscriptionException'
import type {
  ISubscriptionPresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/ISubscriptionPresentation'
import type {
  ISubscriptionPresenter,
} from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/ISubscriptionPresenter'
import type {
  ISubscriptionRepository,
} from '@/domain/stripe/customer/sub-domains/subscription/repository/contract/ISubscriptionRepository'
import {
  createSubscriptionPresentationInput,
} from '@/domain/stripe/customer/sub-domains/subscription/useCase/SubscriptionUseCase'
import {
  ToggleSubscriptionAutomaticRenewalRequest,
} from '@/domain/stripe/customer/sub-domains/subscription/useCase/ToggleSubscriptionAutomaticRenewalRequest'
import { inject, injectable } from 'inversify'

@injectable()
export class ToggleSubscriptionAutomaticRenewalUseCase {

  constructor(
    @inject(DITokens.repository.ISubscriptionRepository)
    private readonly subscriptionRepository: ISubscriptionRepository,
    @inject(DITokens.presenter.ISubscriptionPresenter)
    private readonly subscriptionPresenter: ISubscriptionPresenter,
  ) {
  }

  async execute(request: ToggleSubscriptionAutomaticRenewalRequest): Promise<ISubscriptionPresentation> {

    let subscription: ISubscription

    try {
      subscription = await this.subscriptionRepository.load(request.subscriptionId)
      subscription.validity.autoRenew = request.autoRenew
      subscription = await this.subscriptionRepository.saveAutoRenewalChange(subscription)
    } catch (e) {
      if (e instanceof SubscriptionException) {
        return this.subscriptionPresenter.present(e)
      }
      throw e
    }

    const subscriptionPresentationInput = createSubscriptionPresentationInput(subscription)

    return this.subscriptionPresenter.present(subscriptionPresentationInput)
  }

}
