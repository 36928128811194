import { Author } from '@/domain/points/accounting/model/Author'
import { Client } from '@/domain/points/accounting/model/Client'
import { TransactionDetails } from '@/domain/points/accounting/model/TransactionDetails'
import { PayableService } from '@/domain/points/accounting/model/PayableService'

export class Transaction {
  constructor(
    public type: string,
    public id: string,
    public createdAt: Date,
    public details: TransactionDetails,
    public client: Client,
    public updatedAt?: Date,
    public author?: Author,
    public billId?: string,
    public service?: PayableService,
  ) {
  }
}
