import type { IConfirmDeletionStore, DeleteConfirmationUser } from '@/infrastructure/store/modules/contract/user/IConfirmDeletion.store'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'UserConfirmDeletion',
  namespaced: true,
})
export default class ConfirmDeletionStore extends VuexModule implements IConfirmDeletionStore {
  private _userId = ''

  public get userId(): string {
    return this._userId
  }

  private _userName = ''

  public get userName(): string {
    return this._userName
  }

  private _dialogIsOpen = false

  public get dialogIsOpened(): boolean {
    return this._dialogIsOpen
  }

  @Mutation
  public updateUser(user: DeleteConfirmationUser): void {
    this._userId = user.id
    this._userName = user.name
  }

  @Mutation
  public openDialog(): void {
    this._dialogIsOpen = true
  }

  @Mutation
  public closeDialog(): void {
    this._userId = ''
    this._userName = ''
    this._dialogIsOpen = false
  }
}
