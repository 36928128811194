import { Activation } from '@/domain/licensing/model/Activation'
import { ActivationStatus } from '@/domain/licensing/model/ActivationStatus'
import { License } from '@/domain/licensing/model/License'
import type {
  ILicenseActivationsStore,
} from '@/infrastructure/store/modules/contract/organization/ILicenseActivations.store'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'AdminLicenseActivations',
  namespaced: true,
})
export default class LicenseActivationsStore extends VuexModule implements ILicenseActivationsStore {
  private _dialogIsOpen = false

  public get dialogIsOpened(): boolean {
    return this._dialogIsOpen
  }

  private _license = {} as License

  public get license(): License {
    return this._license
  }

  private _activations: Activation[] = []

  public get activations(): Activation[] {
    return this._activations.sort((a, b) => {
      if (a.isAcquired && !b.isAcquired) return -1
      if (!a.isAcquired && b.isAcquired) return 1
      return 0
    })
  }

  private _loadingActivations = false

  public get loadingActivations(): boolean {
    return this._loadingActivations
  }

  @Mutation
  public reset(): void {
    this._dialogIsOpen = false
    this._license = {} as License
    this._activations = []
    this._loadingActivations = false
  }

  @Mutation
  public openDialog(): void {
    this._dialogIsOpen = true
  }

  @Mutation
  public closeDialog(): void {
    this._dialogIsOpen = false
  }

  @Mutation
  public updateLicense(license: License): void {
    this._license = license
  }

  @Mutation
  public updateActivations(activations: Activation[]): void {
    this._activations = activations
  }

  @Mutation
  public startLoadingActivations(): void {
    this._loadingActivations = true
  }

  @Mutation
  public stopLoadingActivations(): void {
    this._loadingActivations = false
  }

  @Mutation
  public acquireLicense(userId: string): void {
    const activationIndex = this._activations.findIndex(activation => (
      activation.user.id === userId && activation.status !== ActivationStatus.Active
    ))
    if (activationIndex === -1) {
      return
    }

    const alreadyAcquiredLicense = this._activations.some(activation => (
      activation.user.id === userId && activation.status === ActivationStatus.Release
    ))

    this._activations[activationIndex].status = alreadyAcquiredLicense
      ? ActivationStatus.Active
      : ActivationStatus.Acquire
  }

  @Mutation
  public releaseLicense(userId: string): void {
    const activationIndex = this._activations.findIndex(activation => (
      activation.user.id === userId && activation.status !== ActivationStatus.Inactive
    ))
    if (activationIndex === -1) {
      return
    }

    const alreadyAcquiredLicense = this._activations.some(activation => (
      activation.user.id === userId && activation.status === ActivationStatus.Active
    ))

    this._activations[activationIndex].status = alreadyAcquiredLicense
      ? ActivationStatus.Release
      : ActivationStatus.Inactive
  }
}
