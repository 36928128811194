import type { IDownloadApiClient } from '@/domain/download/client/contract/IDownloadApiClient'
import type { IApplicationDownloadDto } from '@/domain/download/client/dto/Dtos'
import DITokens from '@/domain/download/DITokens'
import type { IDownloadMapper } from '@/domain/download/mapper/contract/IDownloadMapper'
import type { ApplicationDownload } from '@/domain/download/model/ApplicationDownload'
import type {
  IDownloadRepository,
} from '@/domain/download/repository/contract/IDownloadRepository'
import {
  CouldNotCreateApplicationDownloadLinkException,
} from '@/domain/download/repository/exception/CouldNotCreateApplicationDownloadLinkException'
import { CouldNotGetAvailableApplications } from '@/domain/download/repository/exception/CouldNotGetAvailableApplications'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'

@injectable()
export class DownloadRepository implements IDownloadRepository {

  constructor(
    @inject(DITokens.apiClient.IDownloadApiClient)
    private readonly downloadApiClient: IDownloadApiClient,
    @inject(DITokens.mapper.IApplicationDownloadMapper)
    private readonly applicationDownloadMapper: IDownloadMapper,
  ) {
  }

  public async loadLatestVersion(application: string): Promise<ApplicationDownload> {

    let dto: IApplicationDownloadDto
    try {
      dto = await this.downloadApiClient.getLatestVersion(application)
    } catch (e) {
      if (e instanceof HttpError) {
        throw new CouldNotCreateApplicationDownloadLinkException()
      }
      throw e
    }
    return this.applicationDownloadMapper.toModel(dto)
  }

  public async getAvailableApplications(): Promise<ApplicationDownload[]> {
    try {
      const dtos = await this.downloadApiClient.getAvailableApplications()

      return dtos.map(dto => this.applicationDownloadMapper.toModel(dto))
    } catch (error) {
      if (error instanceof HttpError) {
        throw new CouldNotGetAvailableApplications()
      }

      throw error
    }
  }
}
