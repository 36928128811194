import { DateTimeFormats } from 'vue-i18n'

export const dateTimeFormats: DateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    shortTime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
}
