import { StatusCode } from '@/infrastructure/api/http/StatusCode'
import { AxiosResponse } from 'axios'

export class HttpError<T = undefined> extends Error {
  constructor(
    public readonly message: string,
    public readonly code: StatusCode,
    public readonly response?: AxiosResponse<Record<string, string>, T>,
  ) {
    super(message)
  }
}
