import type { IStripeModel } from '@/domain/stripe/shared/model/contract/IStripeModel'

export abstract class StripeModel implements IStripeModel {

  private _isSynchronized = true

  public desynchronized?: ((model: this) => void) | null = null

  public shouldBeSynchronized(): boolean {
    return !this._isSynchronized
  }

  public set isSynchronized(value: boolean) {
    this._isSynchronized = value
    if (!value) {
      this.desynchronized?.call(this, this)
    }
  }

  public get isSynchronized():boolean {
    return this._isSynchronized
  }
}
