import { Access, AccessType } from '@/domain/licensing/model/Access'
import { Activation } from '@/domain/licensing/model/Activation'
import { ActivationStatus } from '@/domain/licensing/model/ActivationStatus'
import { Application } from '@/domain/licensing/model/Application'
import { Feature } from '@/domain/licensing/model/Feature'
import { Owner } from '@/domain/licensing/model/Owner'

export class License {
  constructor(
    public readonly id: string,
    public readonly owner: Owner,
    public readonly application: Application,
    public readonly maxActivationCount: number,
    private _currentActivationCount: number,
    public activations: Activation[] = [],
    public readonly features: Feature[] = [],
    public readonly access: Access,
    public readonly created: Date,
    public readonly expire?: Date,
    public readonly startingDate?: Date,
  ) {
  }

  public get activeActivations(): Activation[] {
    return this.activations.filter(activation => activation.isAcquired)
  }

  public get currentActivationCount(): number {
    const acquiredActivationCount = this.activeActivations.length

    return acquiredActivationCount === 0 ? this._currentActivationCount : acquiredActivationCount
  }

  public get hasActiveActivations(): boolean {
    return this.activeActivations.length > 0
  }

  public get expired(): boolean {
    if (this.expire === undefined || this.access.type !== AccessType.Expiring) {
      return false
    }

    const now = new Date()
    return  now > this.expire
  }

  public get disabled(): boolean {
    return this.startingDate === undefined || this.access.type === AccessType.Disabled
  }

  public releaseLicense(userId: string): void {
    const activation = this.activations.find(activation => (
      activation.user.id === userId && activation.isAcquired
    ))
    if (!activation) {
      return
    }

    activation.status = ActivationStatus.Release

    if (this._currentActivationCount > 0) {
      this._currentActivationCount--
    }
  }
}
