import { StripeTokens } from '@/domain/DITokens'
import { IInvoicePresentation } from '@/domain/stripe/invoice/presentation/contract/IInvoicePresentation'
import type { IInvoicePresenter } from '@/domain/stripe/invoice/presenter/contract/IInvoicePresenter'
import { InvoicePresentationInput } from '@/domain/stripe/invoice/presenter/InvoicePresentationInput'
import { InvoiceException } from '@/domain/stripe/invoice/repository/contract/exception/InvoiceException'
import type { IInvoiceRepository } from '@/domain/stripe/invoice/repository/contract/IInvoiceRepository'
import { Presentation } from '@/domain/stripe/invoice/useCase/Presentation'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import { inject, injectable } from 'inversify'

export class GetInvoiceRequest {
  constructor(
    public readonly invoiceId: string,
    public readonly type: ProductType,
  ) {
  }
}

@injectable()
export class GetInvoiceUseCase {
  constructor(
    @inject(StripeTokens.InvoiceTokens.repository.IInvoiceRepository)
    private readonly invoiceRepository: IInvoiceRepository,
    @inject(StripeTokens.InvoiceTokens.presenter.IInvoicePresenter)
    private readonly invoicePresenter: IInvoicePresenter,
  ) {
  }

  public async execute(request: GetInvoiceRequest): Promise<IInvoicePresentation> {
    let outcome: InvoicePresentationInput | InvoiceException

    try {
      const customerInvoice = await this.invoiceRepository.getCustomerInvoice(request.invoiceId, request.type)

      outcome = Presentation.convertToPresentationInput(customerInvoice)
    } catch (error) {
      if (error instanceof InvoiceException) {
        outcome = error
      } else {
        throw error
      }
    }

    return this.invoicePresenter.present(outcome)
  }
}
