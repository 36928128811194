import { PointsAccountingTokens } from '@/domain/DITokens'
import { Transaction } from '@/domain/points/accounting/model/Transaction'
import type { IPointsAccountingRepository } from '@/domain/points/accounting/repository/contract/IPointsAccountingRepository'
import { inject, injectable } from 'inversify'

export class GetTransactionsRequest {
  constructor(
    public readonly from?: string,
    public readonly to?: string,
    public readonly transactionCode?: string,
    public readonly hidePendingTransactions?: boolean,
    public readonly page?: number,
    public readonly perPage?: number,
    public readonly type: string = 'purchase,credit,withdrawal,service_usage_hold,metered_service_payment',
  ) {}
}

@injectable()
export class GetTransactionsUseCase {
  constructor(
    @inject(PointsAccountingTokens.repository.IPointsAccountingRepository)
    private readonly repository: IPointsAccountingRepository,
  ) {
  }

  public async execute(filters?: GetTransactionsRequest):  Promise<{ data: Transaction[], headers: any }> {
    return await this.repository.getTransactions({
      page: filters?.page,
      per_page: filters?.perPage,
      hide_pending_transactions: filters?.hidePendingTransactions,
      type: filters?.type,
    })
  }
}
