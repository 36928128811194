import type { ICountriesPresentation } from '@/domain/statics/presentation/contract/ICountriesPresentation'
import { CountryPresentation } from '@/domain/statics/presentation/CountryPresentation'

export class CountriesPresentation implements ICountriesPresentation {
  constructor(
    public readonly countries: CountryPresentation[],
  ) {
  }

  public get message(): string {
    return ''
  }
}
