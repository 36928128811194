import type { ILinkDto } from '@/domain/stripe/invoice/client/dto/Dtos'
import type { ILinkMapper } from '@/domain/stripe/invoice/mapper/contract/ILinkMapper'
import { Link } from '@/domain/stripe/invoice/model/Link'
import { injectable } from 'inversify'

@injectable()
export class LinkMapper implements ILinkMapper {

  public toModel(dto: ILinkDto): Link {
    return new Link(
      dto.download,
      dto.web,
    )
  }

  public toDto(model: Link): ILinkDto {
    return {
      download: model.download,
      web: model.web,
    }
  }
}
