import { UnauthorizedException } from '@/domain/authenticating/authorization/exception/UnauthorizedException'
import { ssoCookieName } from '@/domain/authenticating/authorization/repository/AuthorizationRepository'
import type { SsoCookie } from '@/infrastructure/api/http/middleware/RetryRefreshToken'
import Cookies from 'js-cookie'

export const getCookie = <T>(name: string): T | undefined => {
  const cookie = Cookies.get(name)
  if (!cookie) {
    return
  }

  return JSON.parse(cookie) as T
}

export const getSsoCookieOrThrow = (): SsoCookie => {
  const cookie = getCookie<SsoCookie>(ssoCookieName)
  const hasTokens = cookie?._token && cookie?._refreshToken

  if (!hasTokens) {
    throw new UnauthorizedException('No tokens found.')
  }

  return cookie
}
