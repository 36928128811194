import { ProductType } from '@/domain/stripe/quote/model/Product'
import { QuoteSuccessPresentation } from '@/domain/stripe/quote/presentation/QuoteSuccessPresentation'
import { trackActionExecutionProgress } from '@/infrastructure/store/ActionLoadingIndicator'
import { IDataTableStore } from '@/infrastructure/store/modules/contract/shop/IDataTable.store'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'ShopDataTable',
  namespaced: true,
})
export default class DataTableStore extends VuexModule implements IDataTableStore {
  private _quote: QuoteSuccessPresentation = {} as QuoteSuccessPresentation

  private _currentQuote: ProductType = ProductType.Subscription

  public get quote(): QuoteSuccessPresentation {
    return this._quote
  }

  public get currentQuote(): ProductType {
    return this._currentQuote
  }

  public get isDataTableEmpty(): boolean {
    return this.quote?.lineItems?.every(line => line.quantity === 0) ?? true
  }

  private _actionInProgress = false

  public get actionInProgress(): boolean {
    return this._actionInProgress
  }

  @Mutation
  public updateQuote(value: QuoteSuccessPresentation): void {
    if (value.lineItems?.every(line => line.product.type === this._currentQuote)) {
      this._quote = value
    }
  }

  @Mutation
  public updateActionInProgress(value: boolean): void {
    this._actionInProgress = value
  }

  @Mutation
  public updateCurrentQuote(value: ProductType): void {
    this._currentQuote = value
  }

  @Mutation
  public resetState(): void {
    this._quote = {} as QuoteSuccessPresentation
  }

  @HandledAction({ commit: 'updateQuote' })
  public getQuote(
    callback: () => Promise<QuoteSuccessPresentation | void>,
  ): Promise<QuoteSuccessPresentation | void> {
    return trackActionExecutionProgress(callback, this.updateActionInProgress)
  }

  @HandledAction({ commit: 'updateQuote' })
  public editQuoteLineItem(
    callback: () => Promise<QuoteSuccessPresentation | void>,
  ): Promise<QuoteSuccessPresentation | void> {
    return trackActionExecutionProgress(callback, this.updateActionInProgress)
  }
}
