import { PointsAccountingTokens } from '@/domain/DITokens'
import type { IBalanceDto } from '@/domain/points/accounting/client/response/IBalanceDto'
import type { IBalanceMapper } from '@/domain/points/accounting/mapper/contract/IBalanceMapper'
import type { IPointsMapper } from '@/domain/points/accounting/mapper/contract/IPointsMapper'
import { Balance } from '@/domain/points/accounting/model/Balance'
import { inject, injectable } from 'inversify'

@injectable()
export class BalanceMapper implements IBalanceMapper {
  constructor(
    @inject(PointsAccountingTokens.mapper.IPointsMapper)
    private readonly pointsMapper: IPointsMapper,
  ) {
  }

  public toModel(balance: IBalanceDto): Balance {
    return new Balance(
      this.pointsMapper.toModel(balance.points),
      balance.organizationId,
    )
  }
}
