import { ProductPresentation } from '@/domain/stripe/quote/presentation/ProductPresentation'
import { TotalAmountPresentation } from '@/domain/stripe/quote/presentation/TotalAmountPresentation'
import { UnitAmountPresentation } from '@/domain/stripe/quote/presentation/UnitAmountPresentation'

export class LineItemPresentation {
  constructor(
    public readonly id: string,
    public readonly description: string,
    public readonly quantity: number,
    public readonly product: ProductPresentation,
    public readonly unitAmount: UnitAmountPresentation,
    public readonly totalAmount: TotalAmountPresentation,
  ) {
  }
}
