import type { IStatusDto } from '@/domain/user/client/response/Dtos'
import type { IStatusMapper } from '@/domain/user/mapper/contract/IStatusMapper'
import { Status } from '@/domain/user/model/Status'
import { injectable } from 'inversify'

@injectable()
export class StatusMapper implements IStatusMapper {
  public toModel(dto: IStatusDto): Status {
    return new Status(
      dto.enabled,
      dto.activation_date,
      dto.is_deleted,
      dto.email_confirmation_date,
    )
  }
}
