import type { IPaymentIntentDto } from '@/domain/stripe/invoice/client/dto/Dtos'
import type { IPaymentIntentMapper } from '@/domain/stripe/invoice/mapper/contract/IPaymentIntentMapper'
import { PaymentIntent } from '@/domain/stripe/invoice/model/PaymentIntent'
import { injectable } from 'inversify'

@injectable()
export class PaymentIntentMapper implements IPaymentIntentMapper {

  public toModel(dto: IPaymentIntentDto): PaymentIntent {
    return new PaymentIntent(
      dto.id,
      dto.secret,
    )
  }

  public toDto(model: PaymentIntent): IPaymentIntentDto {
    return {
      id: model.id,
      secret: model.secret,
    }
  }
}
