import { SubscriptionPresentation } from '@/domain/stripe/customer/sub-domains/subscription/presentation/SubscriptionPresentation'
import { ISubscriptionDetailsStore } from '@/infrastructure/store/modules/contract/shop/ISubscriptionDetails.store'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'ShopSubscriptionDetails',
  namespaced: true,
})
export default class SubscriptionDetailsStore extends VuexModule implements ISubscriptionDetailsStore {

  private _subscription: SubscriptionPresentation = {} as SubscriptionPresentation

  public get subscription(): SubscriptionPresentation {
    return this._subscription
  }

  @Mutation
  public updateSubscription(value: SubscriptionPresentation) {
    this._subscription = value
  }

  @Action({commit: 'updateSubscription'})
  public getSubscription(
    callback: () => Promise<SubscriptionPresentation | void>,
  ): Promise<SubscriptionPresentation | void> {
    return callback()
  }
}
