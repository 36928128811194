import { UserProfilePresentation } from '@/domain/authenticating/authentication/presentation/UserProfilePresentation'
import { UnauthorizedException } from '@/domain/authenticating/authorization/exception/UnauthorizedException'
import { ssoCookieName } from '@/domain/authenticating/authorization/repository/AuthorizationRepository'
import { RefreshTokenUseCase } from '@/domain/authenticating/authorization/useCase/RefreshTokenUseCase'
import { AuthenticatingTokens } from '@/domain/DITokens'
import { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { StatusCode } from '@/infrastructure/api/http/StatusCode'
import { getSsoCookieOrThrow } from '@/infrastructure/utils/extensions/js-cookie'
import { AxiosResponse } from 'axios'
import { inject, injectable } from 'inversify'
import Cookies from 'js-cookie'

export type SsoCookie = {
  _token: string
  _refreshToken: string
  _userProfile: UserProfilePresentation
}

@injectable()
export class RetryRefreshToken {
  protected isRetry = false

  constructor(
    @inject(AuthenticatingTokens.AuthorizationTokens.useCase.RefreshTokenUseCase)
    private readonly refreshTokenUseCase: RefreshTokenUseCase,
  ) {
  }

  public async onResponseFailed(error: HttpError, httpClient: IHttpClient): Promise<AxiosResponse> {
    if (error.code !== StatusCode.Unauthorized && error.message.toUpperCase() !== 'MALFORMED_AUTHORIZATION_HEADER') {
      return Promise.reject(error)
    }

    const { response } = error
    if (!response) {
      return Promise.reject(error)
    }

    if (this.isRetry) {
      this.isRetry = false
      throw new UnauthorizedException(error.message)
    }

    this.isRetry = true
    await this.handleCookies()

    const secondAttempt = await httpClient.doRequest(response.config)
    this.isRetry = false

    return secondAttempt
  }

  private async handleCookies(): Promise<void> {
    const currentCookie = getSsoCookieOrThrow()

    const { token, refresh_token: refreshToken } = await this.refreshTokenUseCase.execute(currentCookie._refreshToken)

    const updatedCookie = {
      ...currentCookie,
      _token: token,
      _refreshToken: refreshToken,
    }

    const COOKIE_DURATION_DAYS = 30

    Cookies.set(ssoCookieName, JSON.stringify(updatedCookie), {
      expires: COOKIE_DURATION_DAYS,
      domain: process.env.VUE_APP_LP360_APP_DOMAIN,
    })
  }
}
