import type { IOwnerDto } from '@/domain/licensing/client/response/Dtos'
import type { IOwnerMapper } from '@/domain/licensing/mapper/contract/IOwnerMapper'
import { Owner } from '@/domain/licensing/model/Owner'
import { injectable } from 'inversify'

@injectable()
export class OwnerMapper implements IOwnerMapper {
  public toModel(dto: IOwnerDto): Owner {
    return new Owner(
      dto.id,
      dto.name,
      dto.type,
    )
  }
}
