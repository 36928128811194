import { LicensingTokens } from '@/domain/DITokens'
import type { ILicenseDto } from '@/domain/licensing/client/response/Dtos'
import type { IAccessMapper } from '@/domain/licensing/mapper/contract/IAccessMapper'
import type { IActivationMapper } from '@/domain/licensing/mapper/contract/IActivationMapper'
import type { IApplicationMapper } from '@/domain/licensing/mapper/contract/IApplicationMapper'
import type { IFeatureMapper } from '@/domain/licensing/mapper/contract/IFeatureMapper'
import type { ILicenseMapper } from '@/domain/licensing/mapper/contract/ILicenseMapper'
import type { IOwnerMapper } from '@/domain/licensing/mapper/contract/IOwnerMapper'
import { License } from '@/domain/licensing/model/License'
import { inject, injectable } from 'inversify'

@injectable()
export class LicenseMapper implements ILicenseMapper {
  constructor(
    @inject(LicensingTokens.mapper.IAccessMapper)
    private readonly accessMapper: IAccessMapper,
    @inject(LicensingTokens.mapper.IActivationMapper)
    private readonly activationMapper: IActivationMapper,
    @inject(LicensingTokens.mapper.IApplicationMapper)
    private readonly applicationMapper: IApplicationMapper,
    @inject(LicensingTokens.mapper.IFeatureMapper)
    private readonly featureMapper: IFeatureMapper,
    @inject(LicensingTokens.mapper.IOwnerMapper)
    private readonly ownerMapper: IOwnerMapper,
  ) {
  }

  public toModel(dto: ILicenseDto): License {
    const features = dto.features.map(feature => (
      this.featureMapper.toModel(feature)
    ))

    const activations = dto.activations?.map(activation => (
      this.activationMapper.toModel(activation)
    )) ?? []

    return new License(
      dto.id,
      this.ownerMapper.toModel(dto.owner),
      this.applicationMapper.toModel(dto.application),
      dto.max_activation_count,
      dto.current_activation_count,
      activations,
      features,
      this.accessMapper.toModel(dto.access),
      new Date(dto.created),
      dto.expire ? new Date(dto.expire) : undefined,
      dto.starting_date ? new Date(dto.starting_date) : undefined,
    )
  }
}
