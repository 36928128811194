import { routes as constantRoutes } from '@/infrastructure/router/constant.routes'
import modules from '@/infrastructure/router/modules'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: RouteConfig[] = constantRoutes

for (const module of modules) {
  for (const route of module) {
    routes.push(route)
  }
}

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
