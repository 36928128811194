import type { ISubscription } from '@/domain/stripe/customer/sub-domains/subscription/model/contract/ISubscription'
import { SubscriptionPresentationInput } from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/SubscriptionPresentationInput'

export function createSubscriptionPresentationInput(subscription: ISubscription) {
  return new SubscriptionPresentationInput(
    subscription.id,
    subscription.totalQuantity,
    subscription.status,
    subscription.latestInvoice,
    subscription.validity.startedAt,
    subscription.validity.validUntil,
    subscription.items,
  )
}
