import { Product } from '@/domain/stripe/customer/sub-domains/subscription/model/Product'

export class SubscriptionItem {
  constructor(
    public readonly id: string,
    public readonly quantity: number,
    public readonly product?: Product,
  ) {
  }

  public get totalPrice(): number | undefined {
    if (this.product) {
      return this.product.price.unitAmount.amount * this.quantity
    }
  }
}
