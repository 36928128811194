import { StripeTokens } from '@/domain/DITokens'
import type { IInvoiceApiClient } from '@/domain/stripe/invoice/client/contract/IInvoiceApiClient'
import type { IInvoiceLinesMapper } from '@/domain/stripe/invoice/mapper/contract/IInvoiceLineMapper'
import type { IInvoiceMapper } from '@/domain/stripe/invoice/mapper/contract/IInvoiceMapper'
import type { ILinkMapper } from '@/domain/stripe/invoice/mapper/contract/ILinkMapper'
import type { IPaymentIntentMapper } from '@/domain/stripe/invoice/mapper/contract/IPaymentIntentMapper'
import type { IPaymentMapper } from '@/domain/stripe/invoice/mapper/contract/IPaymentMapper'
import type {
  ITotalAmountMapper as IInvoiceTotalAmountMapper,
} from '@/domain/stripe/invoice/mapper/contract/ITotalAmountMapper'
import { InvoiceLinesMapper } from '@/domain/stripe/invoice/mapper/InvoiceLinesMapper'
import { InvoiceMapper } from '@/domain/stripe/invoice/mapper/InvoiceMapper'
import { LinkMapper } from '@/domain/stripe/invoice/mapper/LinkMapper'
import { PaymentIntentMapper } from '@/domain/stripe/invoice/mapper/PaymentIntentMapper'
import { PaymentMapper } from '@/domain/stripe/invoice/mapper/PaymentMapper'
import { TotalAmountMapper as InvoiceTotalAmountMapper } from '@/domain/stripe/invoice/mapper/TotalAmountMapper'
import type { IInvoiceLinePresenter } from '@/domain/stripe/invoice/presenter/contract/IInvoiceLinePresenter'
import type { IInvoicePresenter } from '@/domain/stripe/invoice/presenter/contract/IInvoicePresenter'
import type { ITotalAmountPresenter } from '@/domain/stripe/invoice/presenter/contract/ITotalAmountPresenter'
import { InvoiceLinePresenter } from '@/domain/stripe/invoice/presenter/InvoiceLinePresenter'
import { InvoicePresenter } from '@/domain/stripe/invoice/presenter/InvoicePresenter'
import { TotalAmountPresenter } from '@/domain/stripe/invoice/presenter/TotalAmountPresenter'
import { IInvoiceRepository } from '@/domain/stripe/invoice/repository/contract/IInvoiceRepository'
import { InvoiceRepository } from '@/domain/stripe/invoice/repository/InvoiceRepository'
import { GetInvoicesUseCase } from '@/domain/stripe/invoice/useCase/GetInvoicesUseCase'
import { GetInvoiceUseCase } from '@/domain/stripe/invoice/useCase/GetInvoiceUseCase'
import { InvoiceApiClient } from '@/infrastructure/api/stripe/invoice/InvoiceApiClient'
import { Container } from 'inversify'

export function invoiceContainer(container: Container): Container {
  initializeHttpClients(container)
  initializeRepositories(container)
  initializeMappers(container)
  initializePresenters(container)
  initializeUseCases(container)

  return container
}

function initializeHttpClients(container: Container): void {
  container
    .bind<IInvoiceApiClient>(StripeTokens.InvoiceTokens.apiClient.IInvoiceApiClient)
    .to(InvoiceApiClient)
}

function initializeMappers(container: Container): void {
  container
    .bind<IInvoiceMapper>(StripeTokens.InvoiceTokens.mapper.IInvoiceMapper)
    .to(InvoiceMapper)

  container
    .bind<ILinkMapper>(StripeTokens.InvoiceTokens.mapper.ILinkMapper)
    .to(LinkMapper)

  container
    .bind<IPaymentMapper>(StripeTokens.InvoiceTokens.mapper.IPaymentMapper)
    .to(PaymentMapper)

  container
    .bind<IPaymentIntentMapper>(StripeTokens.InvoiceTokens.mapper.IPaymentIntentMapper)
    .to(PaymentIntentMapper)

  container
    .bind<IInvoiceTotalAmountMapper>(StripeTokens.InvoiceTokens.mapper.ITotalAmountMapper)
    .to(InvoiceTotalAmountMapper)

  container
    .bind<IInvoiceLinesMapper>(StripeTokens.InvoiceTokens.mapper.IInvoiceLinesMapper)
    .to(InvoiceLinesMapper)
}

function initializeRepositories(container: Container): void {
  container
    .bind<IInvoiceRepository>(StripeTokens.InvoiceTokens.repository.IInvoiceRepository)
    .to(InvoiceRepository)
}

function initializePresenters(container: Container): void {
  container
    .bind<IInvoicePresenter>(StripeTokens.InvoiceTokens.presenter.IInvoicePresenter)
    .to(InvoicePresenter)

  container
    .bind<ITotalAmountPresenter>(StripeTokens.InvoiceTokens.presenter.ITotalAmountPresenter)
    .to(TotalAmountPresenter)

  container
    .bind<IInvoiceLinePresenter>(StripeTokens.InvoiceTokens.presenter.IInvoiceLinePresenter)
    .to(InvoiceLinePresenter)
}

function initializeUseCases(container: Container): void {
  container
    .bind<GetInvoiceUseCase>(StripeTokens.InvoiceTokens.useCase.GetInvoiceUseCase)
    .to(GetInvoiceUseCase)

  container.bind<GetInvoicesUseCase>(StripeTokens.InvoiceTokens.useCase.GetInvoicesUseCase)
    .to(GetInvoicesUseCase)
}
