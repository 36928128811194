import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import SharedDITokens from '@/domain/shared/DITokens'
import { CouldNotGetCustomerSubscriptionsException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/CouldNotGetCustomerSubscriptionsException'
import { CouldNotReadSubscriptionOrganizationInfoException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/CouldNotReadSubscriptionOrganizationInfoException'
import { InvalidSubscriptionException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/InvalidSubscriptionException'
import { InvalidSubscriptionStatusException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/InvalidSubscriptionStatusException'
import { SubscriptionException } from '@/domain/stripe/customer/sub-domains/subscription/model/exception/SubscriptionException'
import type { ISubscriptionPresentation } from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/ISubscriptionPresentation'
import { SubscriptionStatus } from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/SubscriptionStatus'
import { SubscriptionStatusPresentation } from '@/domain/stripe/customer/sub-domains/subscription/presentation/contract/SubscriptionStatusPresentation'
import { GetSubscriptionErrorPresentation } from '@/domain/stripe/customer/sub-domains/subscription/presentation/GetSubscriptionErrorPresentation'
import { SubscriptionPresentation } from '@/domain/stripe/customer/sub-domains/subscription/presentation/SubscriptionPresentation'
import type { ISubscriptionPresenter } from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/ISubscriptionPresenter'
import { SubscriptionPresentationInput } from '@/domain/stripe/customer/sub-domains/subscription/presenter/contract/SubscriptionPresentationInput'
import InvoiceDITokens from '@/domain/stripe/invoice/DITokens'
import { Invoice } from '@/domain/stripe/invoice/model/Invoice'
import type { InvoicePresentation } from '@/domain/stripe/invoice/presentation/InvoicePresentation'
import type { IInvoicePresenter } from '@/domain/stripe/invoice/presenter/contract/IInvoicePresenter'
import { Presentation } from '@/domain/stripe/invoice/useCase/Presentation'
import { formatToUTCDate } from '@/infrastructure/utils/Helpers'
import { inject, injectable } from 'inversify'

@injectable()
export class SubscriptionPresenter implements ISubscriptionPresenter {

  constructor(
    @inject(InvoiceDITokens.presenter.IInvoicePresenter)
    private readonly invoicePresenter: IInvoicePresenter,
    @inject(SharedDITokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
  ) {
  }

  public present(
    input: SubscriptionPresentationInput | SubscriptionException,
  ): ISubscriptionPresentation {

    if (input instanceof SubscriptionPresentationInput) {
      const invoicePresentationInput = Presentation.convertToPresentationInput(input.latestInvoice)

      return new SubscriptionPresentation(
        input.id,
        this.presentStatus(input.status),
        input.totalQuantity,
        this.generateSeatsBreakdown(input.latestInvoice),
        this.presentFormattedDate(input.startedAt),
        this.presentFormattedDate(input.validUntil),
        this.invoicePresenter.present(invoicePresentationInput) as InvoicePresentation,
        input.items,
      )
    } else {
      let message
      if (input instanceof CouldNotReadSubscriptionOrganizationInfoException) {
        message = this.localizedStringProvider.getText('shared.domainError.unknownError')
      } else if (input instanceof InvalidSubscriptionStatusException) {
        message = this.localizedStringProvider.getText('shared.domainError.unknownError')
      } else if (input instanceof CouldNotGetCustomerSubscriptionsException) {
        message = this.localizedStringProvider.getText('shared.domainError.serverError')
      } else if (input instanceof InvalidSubscriptionException) {
        message = this.localizedStringProvider.getText('shared.domainError.unknownError')
      } else {
        message = this.localizedStringProvider.getText('shared.domainError.unknownError')
      }
      return new GetSubscriptionErrorPresentation(message)
    }
  }

  private presentStatus(status: string): SubscriptionStatusPresentation {
    let value: SubscriptionStatus
    let label: string
    switch (status) {
      case 'pending_payment':
        value = 'PENDING_PAYMENT'
        label = this.localizedStringProvider.getText('billing.overview.mySubscriptions.statuses.pendingPayment')
        break
      case 'auto_renewal':
        value = 'AUTO_RENEWAL'
        label = this.localizedStringProvider.getText('billing.overview.mySubscriptions.statuses.autoRenewal')
        break
      case 'canceled':
        value = 'CANCELED'
        label = this.localizedStringProvider.getText('billing.overview.mySubscriptions.statuses.canceled')
        break
      default:
        value = 'EXPIRED'
        label = this.localizedStringProvider.getText('billing.overview.mySubscriptions.statuses.expired')
        break
    }
    return new SubscriptionStatusPresentation(value, label)
  }

  private presentFormattedDate(date: Date): string {
    return this.localizedStringProvider.getDate(formatToUTCDate(date), 'short')
  }

  private generateSeatsBreakdown(latestInvoice: Invoice): string {
    return latestInvoice.lines
      .map((line) => {
        const seatsKey = 'shared.language.seat'
        const seats = this.localizedStringProvider.getText(seatsKey, line.quantity)
        return `${line.quantity} ${seats} ${line.name}`
      })
      .join('<br/>')
  }
}
