import { HasRequiredPermissionsUseCase } from '@/domain/authenticating/authentication/useCase/HasRequiredPermissionsUseCase'
import { AuthenticatingTokens } from '@/domain/DITokens'
import { STORE_ADMIN_PERMISSIONS } from '@/shared/config/variables'
import { Container } from 'inversify'

export function authenticationContainer(container: Container): Container {
  initializeUseCases(container)

  return container
}

function initializeUseCases(container: Container): void {
  container
    .bind<HasRequiredPermissionsUseCase>(AuthenticatingTokens.AuthenticationTokens.useCase.HasRequiredPermissionsUseCase)
    .toConstantValue(new HasRequiredPermissionsUseCase(STORE_ADMIN_PERMISSIONS))
}
