import { CouponInput } from '@/domain/stripe/couponCode/presenter/CouponInput'
import { InvoiceLineInput } from '@/domain/stripe/invoice/presenter/InvoiceLineInput'
import { TotalAmountInput } from '@/domain/stripe/invoice/presenter/TotalAmountInput'

export class InvoicePresentationInput {

  constructor(
    public readonly id: string,
    public readonly status: string,
    public readonly paymentIntentSecret: string,
    public readonly paymentIntentId: string,
    public readonly lines: InvoiceLineInput[],
    public readonly subtotal: TotalAmountInput,
    public readonly total: TotalAmountInput,
    public readonly number: string,
    public readonly downloadLink: string,
    public readonly date: string,
    public readonly coupon: CouponInput|undefined,
    public readonly subscriptionId: string|null,
  ) {
  }
}
