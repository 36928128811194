import { OrganizationTokens } from '@/domain/DITokens'
import type { IUserDto } from '@/domain/organization/client/response/Dtos'
import type { ICountryMapper } from '@/domain/organization/mapper/contract/ICountryMapper'
import type { IStatusMapper } from '@/domain/organization/mapper/contract/IStatusMapper'
import type { IUserMapper } from '@/domain/organization/mapper/contract/IUserMapper'
import { User } from '@/domain/organization/model/User'
import { inject, injectable } from 'inversify'

@injectable()
export class UserMapper implements IUserMapper {
  constructor(
    @inject(OrganizationTokens.mapper.IStatusMapper)
    private readonly statusMapper: IStatusMapper,
    @inject(OrganizationTokens.mapper.ICountryMapper)
    private readonly countryMapper: ICountryMapper,
  ) {
  }

  public toModel(dto: IUserDto): User {
    return new User(
      dto.id,
      dto.first_name,
      dto.last_name,
      dto.email,
      this.statusMapper.toModel(dto.status),
      this.countryMapper.toModel(dto.country),
      dto.permissions,
    )
  }
}
