import { Point } from '@/domain/points/accounting/client/response/IBalanceDto'

export class PointsPresentation {
  public static readonly EMPTY = new PointsPresentation(
    '0',
    '0',
    '0',
  )

  constructor(
    public readonly expiring: Point,
    public readonly perpetual: Point,
    public readonly total: Point,
  ) {
  }
}
