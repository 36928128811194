import { StripeTokens } from '@/domain/DITokens'
import type { IPaymentDto } from '@/domain/stripe/invoice/client/dto/Dtos'
import type { IPaymentIntentMapper } from '@/domain/stripe/invoice/mapper/contract/IPaymentIntentMapper'
import type { IPaymentMapper } from '@/domain/stripe/invoice/mapper/contract/IPaymentMapper'
import { Payment } from '@/domain/stripe/invoice/model/Payment'
import { inject, injectable } from 'inversify'

@injectable()
export class PaymentMapper implements IPaymentMapper {
  constructor(
    @inject(StripeTokens.InvoiceTokens.mapper.IPaymentIntentMapper)
    private readonly paymentIntentMapper: IPaymentIntentMapper,
  ) {
  }

  public toModel(dto: IPaymentDto): Payment {
    return new Payment(
      this.paymentIntentMapper.toModel(dto.intent),
    )
  }

  public toDto(model: Payment): IPaymentDto {
    return {
      intent: this.paymentIntentMapper.toDto(model.intent),
    }
  }
}
