import { UserTokens } from '@/domain/DITokens'
import type { IUserApiClient } from '@/domain/user/client/contract/IUserApiClient'
import type { ICountryMapper } from '@/domain/user/mapper/contract/ICountryMapper'
import type { IOrganizationMapper } from '@/domain/user/mapper/contract/IOrganizationMapper'
import type { IStatusMapper } from '@/domain/user/mapper/contract/IStatusMapper'
import type { IUserMapper } from '@/domain/user/mapper/contract/IUserMapper'
import { CountryMapper } from '@/domain/user/mapper/CountryMapper'
import { OrganizationMapper } from '@/domain/user/mapper/OrganizationMapper'
import { StatusMapper } from '@/domain/user/mapper/StatusMapper'
import { UserMapper } from '@/domain/user/mapper/UserMapper'
import type { IUserRepository } from '@/domain/user/repository/contract/IUserRepository'
import { UserRepository } from '@/domain/user/repository/UserRepository'
import { EditUserUseCase } from '@/domain/user/useCase/EditUserUseCase'
import { GetUserByIdUseCase } from '@/domain/user/useCase/GetUserByIdUseCase'
import { DeleteUserByIdUseCase } from '@/domain/user/useCase/DeleteUserByIdUseCase'
import { GetUserProfileUseCase } from '@/domain/user/useCase/GetUserProfileUseCase'
import { InviteUserUseCase } from '@/domain/user/useCase/InviteUserUseCase'
import { UserApiClient } from '@/infrastructure/api/user/UserApiClient'
import { Container } from 'inversify'
import { ResetPasswordUserUseCase } from '@/domain/user/useCase/ResetPasswordUserUseCase'
import { ResendInvitationUserUseCase } from '@/domain/user/useCase/ResendInvitationUserUseCase'

export function userContainer(container: Container): void {
  initializeHttpClients(container)
  initializeMapper(container)
  initializeRepository(container)
  initializeUseCase(container)
}

function initializeHttpClients(container: Container): void {
  container
    .bind<IUserApiClient>(UserTokens.apiClient.IUserApiClient)
    .to(UserApiClient)
}

function initializeMapper(container: Container): void {
  container
    .bind<ICountryMapper>(UserTokens.mapper.ICountryMapper)
    .to(CountryMapper)

  container
    .bind<IOrganizationMapper>(UserTokens.mapper.IOrganizationMapper)
    .to(OrganizationMapper)

  container
    .bind<IStatusMapper>(UserTokens.mapper.IStatusMapper)
    .to(StatusMapper)

  container
    .bind<IUserMapper>(UserTokens.mapper.IUserMapper)
    .to(UserMapper)
}

function initializeRepository(container: Container): void {
  container
    .bind<IUserRepository>(UserTokens.repository.IUserRepository)
    .to(UserRepository)
}

function initializeUseCase(container: Container): void {
  container
    .bind<GetUserByIdUseCase>(UserTokens.useCase.GetUserByIdUseCase)
    .to(GetUserByIdUseCase)

  container
    .bind<EditUserUseCase>(UserTokens.useCase.EditUserUseCase)
    .to(EditUserUseCase)

  container
    .bind<DeleteUserByIdUseCase>(UserTokens.useCase.DeleteUserByIdUseCase)
    .to(DeleteUserByIdUseCase)

  container
    .bind<InviteUserUseCase>(UserTokens.useCase.InviteUserUseCase)
    .to(InviteUserUseCase)

  container
    .bind<ResetPasswordUserUseCase>(UserTokens.useCase.ResetPasswordUserUseCase)
    .to(ResetPasswordUserUseCase)

  container
    .bind<ResendInvitationUserUseCase>(UserTokens.useCase.ResendInvitationUserUseCase)
    .to(ResendInvitationUserUseCase)

  container
    .bind<GetUserProfileUseCase>(UserTokens.useCase.GetUserProfileUseCase)
    .to(GetUserProfileUseCase)
}
