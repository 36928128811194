import type { IOrganizationDto } from '@/domain/user/client/response/Dtos'
import type { IOrganizationMapper } from '@/domain/user/mapper/contract/IOrganizationMapper'
import { Organization } from '@/domain/user/model/Orgaization'
import { injectable } from 'inversify'

@injectable()
export class OrganizationMapper implements IOrganizationMapper {
  public toModel(dto: IOrganizationDto): Organization {
    return new Organization(
      dto.id,
      dto.name,
    )
  }
}
