import type { IDeviceDto } from '@/domain/licensing/client/response/Dtos'
import type { IDeviceMapper } from '@/domain/licensing/mapper/contract/IDeviceMapper'
import { Device } from '@/domain/licensing/model/Device'
import { injectable } from 'inversify'

@injectable()
export class DeviceMapper implements IDeviceMapper {
  public toModel(dto: IDeviceDto): Device {
    return new Device(
      dto.id,
      dto.name,
    )
  }
}
