export enum AccessType {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Expiring = 'expiring',
}

export class Access {
  constructor(
    public readonly type: AccessType,
  ) {
  }
}