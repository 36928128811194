import { StripeTokens } from '@/domain/DITokens'
import { InvalidQuoteItemException } from '@/domain/stripe/quote/exception/InvalidQuoteItemException'
import { QuoteException } from '@/domain/stripe/quote/exception/QuoteException'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import { Quote } from '@/domain/stripe/quote/model/Quote'
import type { IQuotePresentation } from '@/domain/stripe/quote/presentation/contract/IQuotePresentation'
import type { IQuotePresenter } from '@/domain/stripe/quote/presenter/contract/IQuotePresenter'
import type { IQuoteRepository } from '@/domain/stripe/quote/repository/contract/IQuoteRepository'
import { inject, injectable } from 'inversify'

export class EditQuoteLineItemRequest {
  constructor(
    public readonly id: string,
    public readonly quantity: number,
    public readonly productType: ProductType,
  ) {
  }
}

@injectable()
export class EditQuoteLineItemUseCase {
  constructor(
    @inject(StripeTokens.QuoteTokens.repository.IQuoteRepository)
    private readonly quoteRepository: IQuoteRepository,
    @inject(StripeTokens.QuoteTokens.presenter.IQuotePresenter)
    private readonly quotePresenter: IQuotePresenter,
  ) {
  }

  public async execute(request: EditQuoteLineItemRequest): Promise<IQuotePresentation> {

    let quote: Quote | QuoteException
    try {
      quote = await this.quoteRepository.load(request.productType)
      const lineItem = quote.findLineItem(request.id)
      if (lineItem == undefined) {
        return this.quotePresenter.present(new InvalidQuoteItemException(request.id))
      }
      lineItem.quantity = request.quantity
      quote = await this.quoteRepository.save(request.productType, quote)
    } catch (error) {
      if (error instanceof QuoteException) {
        return this.quotePresenter.present(error)
      }

      throw error
    }

    return this.quotePresenter.present(quote)
  }
}
