const apiClient = {
  IPointsAccountingApiClient: Symbol('IPointsAccountingApiClient'),
}

const mapper = {
  IBalanceMapper: Symbol('IBalanceMapper'),
  IPointsMapper: Symbol('IPointsMapper'),
  IAuthorMapper: Symbol('IAuthorMapper'),
  IClientMapper: Symbol('IClientMapper'),
  ITransactionMapper: Symbol('ITransactionMapper'),
  ITransactionDetailsMapper: Symbol('ITransactionDetailsMapper'),
}

const repository = {
  IPointsAccountingRepository: Symbol('IPointsAccountingRepository'),
}

const presenter = {
  IAccountPresenter: Symbol('IAccountPresenter'),
  IBalancePresenter: Symbol('IBalancePresenter'),
  IPointsPresenter: Symbol('IPointsPresenter'),
}

const useCase = {
  GetBalanceUseCase: Symbol('GetBalanceUseCase'),
  GetTransactionsUseCase: Symbol('GetTransactionsUseCase'),
}

export default {
  apiClient,
  mapper,
  repository,
  presenter,
  useCase,
}
