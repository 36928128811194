import { StripeTokens } from '@/domain/DITokens'
import { Invoice } from '@/domain/stripe/invoice/model/Invoice'
import type { IInvoiceRepository } from '@/domain/stripe/invoice/repository/contract/IInvoiceRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class GetInvoicesUseCase {
  constructor(
    @inject(StripeTokens.InvoiceTokens.repository.IInvoiceRepository)
    private readonly invoiceRepository: IInvoiceRepository,
  ) {
  }

  public async execute(): Promise<Invoice[]> {
    return await this.invoiceRepository.getCustomerInvoices()
  }
}
