import { ApiClient } from '@/infrastructure/api/ApiClient'
import { injectable } from 'inversify'

@injectable()
export abstract class VersionedApiClient extends ApiClient {
  protected get version(): string {
    return 'v3'
  }

  protected abstract get baseUrl(): string;
}
