import { StripeTokens } from '@/domain/DITokens'
import { SetupIntent } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/SetupIntent'
import {
  ISetupIntentPresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/contract/ISetupIntentPresentation'
import type {
  ISetupIntentPresenter,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ISetupIntentPresenter'
import {
  CustomerPaymentMethodsException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CustomerPaymentMethodsException'
import type {
  ICustomerPaymentMethodsRepository,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/ICustomerPaymentMethodsRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class CreateSetupIntentUseCase {
  constructor(
    @inject(StripeTokens.PaymentTokens.repository.IPaymentMethodRepository)
    private readonly paymentMethodRepository: ICustomerPaymentMethodsRepository,
    @inject(StripeTokens.PaymentTokens.presenter.ISetupIntentPresenter)
    private readonly setupIntentPresenter: ISetupIntentPresenter,
  ) {
  }

  public async execute(): Promise<ISetupIntentPresentation> {

    let outcome: SetupIntent | CustomerPaymentMethodsException

    try {
      outcome = await this.paymentMethodRepository.setupCreditCard()
    } catch (error) {
      if (error instanceof CustomerPaymentMethodsException) {
        outcome = error
      } else {
        throw error
      }
    }

    return this.setupIntentPresenter.present(outcome)
  }
}