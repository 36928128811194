import { ICustomerPaymentMethodsApiClient } from '@/domain/stripe/customer/sub-domains/paymentMethod/client/contract/ICustomerPaymentMethodsApiClient'
import type {
  ICustomerPaymentMethodsDto,
  ISetupIntentDto,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/client/dto/Dtos'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { StripeApiClient } from '@/infrastructure/api/stripe/StripeApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class CustomerPaymentMethodsApiClient extends StripeApiClient implements ICustomerPaymentMethodsApiClient {
  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  public async getAll(): Promise<ICustomerPaymentMethodsDto> {
    const requestUrl = `${this.baseUrl}/customer/payment_method`

    return this.httpClient.get(requestUrl)
  }

  public setupCreditCard(): Promise<ISetupIntentDto> {
    const requestUrl = `${this.baseUrl}/customer/payment_method/setup/card`

    return this.httpClient.post(requestUrl)
  }
}
