import { dateTimeFormats } from '@/infrastructure/locales/dateTimeFormats'
import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'

Vue.use(VueI18n)

const loadLocaleMessages = (): Record<string, LocaleMessages> => {
  const requiredModule = require.context(
    '../locales',
    true,
    /\w+.locale.[tj]s$/i,
  )
  const messages: Record<string, LocaleMessages> = {}

  for (const filename of requiredModule.keys()) {
    const matched = filename.match(/([\w\d-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = requiredModule(filename).default
    }
  }

  return messages
}
export const localeMessages = loadLocaleMessages()

export const checkDefaultLanguage = (): string | null => {
  let matched = null
  const languages = Object.getOwnPropertyNames(localeMessages)

  for (const lang of languages) {
    if (lang === navigator.language) {
      matched = lang
    }
  }

  if (!matched) {
    for (const lang of languages) {
      const languagePartials = navigator.language.split('-')[0]
      if (lang === languagePartials) {
        matched = lang
      }
    }
  }

  if (!matched) {
    for (const lang of languages) {
      const languagePartials = navigator.language.split('-')[0]
      if (lang.split('-')[0] === languagePartials) {
        matched = lang
      }
    }
  }

  return matched
}

export const languages = Object.getOwnPropertyNames(localeMessages)
export const defaultLocale = 'en'

export const selectedLocale = checkDefaultLanguage()
  || process.env.VUE_APP_I18N_LOCALE
  || defaultLocale

export default new VueI18n({
  locale: selectedLocale,
  messages: localeMessages,
  dateTimeFormats,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || defaultLocale,
})
