import { SharedTokens, StripeTokens } from '@/domain/DITokens'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import {
  CouldNotGetCustomerPaymentMethodsException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CouldNotGetCustomerPaymentMethodsException'
import {
  CustomerHasNoCardPaymentMethodException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CustomerHasNoCardPaymentMethodException'
import { CustomerNotFoundException } from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CustomerNotFoundException'
import {
  CustomerPaymentMethodsException,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/exception/CustomerPaymentMethodsException'
import { CustomerPaymentMethods } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/CustomerPaymentMethods'
import {
  ICustomerPaymentMethodsPresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/contract/ICustomerPaymentMethodsPresentation'
import {
  CustomerPaymentMethodsFailurePresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/CustomerPaymentMethodsFailurePresentation'
import {
  CustomerPaymentMethodsPresentation,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presentation/CustomerPaymentMethodsPresentation'
import type {
  ICustomerPaymentMethodsPresenter,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ICustomerPaymentMethodsPresenter'
import type {
  IPaymentMethodPresenter,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/IPaymentMethodPresenter'
import { inject, injectable } from 'inversify'

@injectable()
export class CustomerPaymentMethodsPresenter implements ICustomerPaymentMethodsPresenter {
  constructor(
    @inject(StripeTokens.PaymentTokens.presenter.IPaymentMethodPresenter)
    private readonly paymentMethodPresenter: IPaymentMethodPresenter,
    @inject(SharedTokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
  ) {
  }

  public present(input: CustomerPaymentMethods | CustomerPaymentMethodsException): ICustomerPaymentMethodsPresentation {
    if (input instanceof CustomerPaymentMethods) {

      return new CustomerPaymentMethodsPresentation(
        input.customerId,
        input.paymentMethods.map(
          (paymentMethod) => this.paymentMethodPresenter.present(paymentMethod),
        ),
      )
    } else if (input instanceof CustomerNotFoundException) {
      return CustomerPaymentMethodsPresentation.EMPTY
    } else {
      let message: string

      if (input instanceof CouldNotGetCustomerPaymentMethodsException) {
        message = this.localizedStringProvider.getText('shop.paymentMethod.response.error.couldNotGetCustomerPaymentMethods')
      } else if (input instanceof CustomerHasNoCardPaymentMethodException) {
        message = this.localizedStringProvider.getText('shop.paymentMethod.customerHasNoCardPaymentMethod')
      } else { message = this.localizedStringProvider.getText('shop.paymentMethod.response.error.unexpectedError')
      }

      return new CustomerPaymentMethodsFailurePresentation(message)
    }
  }
}
