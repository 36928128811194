const apiClient = {
  ISubscriptionApiClient: Symbol(),
}

const mapper = {
  ISubscriptionItemMapper: Symbol(),
  IProductMapper: Symbol(),
  IProductPriceMapper: Symbol(),
  IValidityMapper: Symbol(),
  ISubscriptionMapper: Symbol(),
}

const repository = {
  IMemoryCache: Symbol(),
  ISubscriptionRepository: Symbol(),
}

const presenter = {
  IMySubscriptionsPresenter: Symbol(),
  ISubscriptionPresenter: Symbol(),
  IRetrySubscriptionPaymentPresenter: Symbol(),
}

const useCase = {
  ListMySubscriptionsUseCase: Symbol(),
  GetSubscriptionUseCase: Symbol(),
  ToggleSubscriptionAutomaticRenewalUseCase: Symbol(),
  RetrySubscriptionPaymentUseCase: Symbol(),
}

export default {
  apiClient,
  mapper,
  repository,
  presenter,
  useCase,
}
