import { PointsAccountingTokens } from '@/domain/DITokens'
import { PointsAccountingException } from '@/domain/points/accounting/exception/PointsAccountingException'
import { BalanceExceptionPresentation, BalancePresentation } from '@/domain/points/accounting/presentation/BalancePresentation'
import type { IBalancePresenter } from '@/domain/points/accounting/presenter/contract/IBalancePresenter'
import type { IPointsAccountingRepository } from '@/domain/points/accounting/repository/contract/IPointsAccountingRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class GetBalanceUseCase {
  constructor(
    @inject(PointsAccountingTokens.repository.IPointsAccountingRepository)
    private readonly repository: IPointsAccountingRepository,
    @inject(PointsAccountingTokens.presenter.IBalancePresenter)
    private readonly presenter: IBalancePresenter,
  ) {
  }

  public async execute(): Promise<BalancePresentation | BalanceExceptionPresentation> {
    try {
      const balance = await this.repository.getBalance()

      return this.presenter.present(balance)
    } catch (error) {
      if (error instanceof PointsAccountingException) {
        return this.presenter.presentException(error)
      }

      throw error
    }
  }
}
