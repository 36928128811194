import { StripeTokens } from '@/domain/DITokens'
import type { ICouponMapper } from '@/domain/stripe/couponCode/mapper/contract/ICouponMapper'
import type { ICustomerMapper } from '@/domain/stripe/customer/mapper/contract/ICustomerMapper'
import type { IInvoiceDto } from '@/domain/stripe/invoice/client/dto/Dtos'
import type { IInvoiceLinesMapper } from '@/domain/stripe/invoice/mapper/contract/IInvoiceLineMapper'
import type { IInvoiceMapper } from '@/domain/stripe/invoice/mapper/contract/IInvoiceMapper'
import type { ILinkMapper } from '@/domain/stripe/invoice/mapper/contract/ILinkMapper'
import type { IPaymentMapper } from '@/domain/stripe/invoice/mapper/contract/IPaymentMapper'
import type { ITotalAmountMapper } from '@/domain/stripe/invoice/mapper/contract/ITotalAmountMapper'
import { Invoice } from '@/domain/stripe/invoice/model/Invoice'
import { inject, injectable } from 'inversify'
import { isObject } from 'lodash'

@injectable()
export class InvoiceMapper implements IInvoiceMapper {
  constructor(
    @inject(StripeTokens.InvoiceTokens.mapper.ITotalAmountMapper)
    private readonly totalAmountMapper: ITotalAmountMapper,
    @inject(StripeTokens.InvoiceTokens.mapper.IPaymentMapper)
    private readonly paymentMapper: IPaymentMapper,
    @inject(StripeTokens.InvoiceTokens.mapper.ILinkMapper)
    private readonly linkMapper: ILinkMapper,
    @inject(StripeTokens.InvoiceTokens.mapper.IInvoiceLinesMapper)
    private readonly invoiceLinesMapper: IInvoiceLinesMapper,
    @inject(StripeTokens.CouponTokens.mapper.ICouponMapper)
    private readonly couponMapper: ICouponMapper,
    @inject(StripeTokens.CustomerTokens.mapper.ICustomerMapper)
    private readonly customerMapper: ICustomerMapper,
  ) {
  }

  public toModel(dto: IInvoiceDto): Invoice {
    return new Invoice(
      dto.id,
      dto.number,
      isObject(dto.customer) ? this.customerMapper.toModel(dto.customer) : dto.customer,
      this.invoiceLinesMapper.toModels(dto.products),
      dto.date,
      this.totalAmountMapper.toModel(dto.subtotal),
      this.totalAmountMapper.toModel(dto.total),
      this.paymentMapper.toModel(dto.payment),
      this.linkMapper.toModel(dto.link),
      dto.coupon ? this.couponMapper.toModel(dto.coupon) : undefined,
      dto.status,
      dto.subscription_id,
    )
  }

  public toDto(model: Invoice): IInvoiceDto {
    return {
      id: model.id,
      number: model.number,
      status: model.status,
      customer: isObject(model.customer) ? this.customerMapper.toDto(model.customer) : model.customer,
      products: this.invoiceLinesMapper.toDto(model.lines),
      date: model.date,
      subtotal: this.totalAmountMapper.toDto(model.subtotal),
      total: this.totalAmountMapper.toDto(model.total),
      payment: this.paymentMapper.toDto(model.payment),
      link: this.linkMapper.toDto(model.link),
      coupon: undefined,
      subscription_id: model.subscriptionId,
    }
  }
}
