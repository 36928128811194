import { UnitAmount } from '@/domain/stripe/couponCode/model/UnitAmount'
import { UnitAmountPresentation } from '@/domain/stripe/couponCode/presentation/UnitAmountPresentation'
import type { IUnitAmountPresenter } from '@/domain/stripe/couponCode/presenter/contract/IUnitAmountPresenter'
import { injectable } from 'inversify'

@injectable()
export class UnitAmountPresenter implements IUnitAmountPresenter {
  public present(unitAmount: UnitAmount): UnitAmountPresentation {
    return new UnitAmountPresentation(
      unitAmount.currency,
      unitAmount.amount,
    )
  }
}
