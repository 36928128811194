import { IUnitAmountDto } from '@/domain/stripe/couponCode/client/dto/Dtos'
import { IUnitAmountMapper } from '@/domain/stripe/couponCode/mapper/contract/IUnitAmountMapper'
import { UnitAmount } from '@/domain/stripe/couponCode/model/UnitAmount'
import { injectable } from 'inversify'

@injectable()
export class UnitAmountMapper implements IUnitAmountMapper {
  public toModel(dto: IUnitAmountDto): UnitAmount {
    return new UnitAmount(
      dto.currency,
      dto.amount,
    )
  }
}
