import { ICheckMyAccessDto } from '@/domain/licensing/client/response/Dtos'
import type { IApplicationAccessMapper } from '@/domain/licensing/mapper/contract/IApplicationAccessMapper'
import type { ApplicationAccessMap } from '@/domain/licensing/model/ApplicationsAccess'
import { injectable } from 'inversify'

@injectable()
export class ApplicationAccessMapper implements IApplicationAccessMapper {
  public toModel(dto: ICheckMyAccessDto): ApplicationAccessMap {
    return new Map(Object.entries(dto.applications))
  }
}
