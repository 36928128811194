import type { ApplicationAccessMap } from '@/domain/licensing/model/ApplicationsAccess'
import type { IApplicationAccessStore } from '@/infrastructure/store/modules/contract/access/IApplicationAccessStore'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'AccessApplicationAccess',
  namespaced: true,
})
export default class ApplicationAccessStore extends VuexModule implements IApplicationAccessStore {
  private _applicationAccess!: Map<string, boolean>

  public get applicationAccessMap(): Map<string, boolean> {
    return this._applicationAccess
  }

  public get hasAccessToCloud(): boolean {
    return this.applicationAccessMap?.get(process.env.VUE_APP_CLOUD_ACCESS_APPLICATION_ID ?? '') ?? false
  }

  @Mutation
  setApplicationAccessMap(map: ApplicationAccessMap): void {
    this._applicationAccess = map
  }
}
