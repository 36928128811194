import { PointsAccountingTokens } from '@/domain/DITokens'
import type { IPointsAccountingApiClient } from '@/domain/points/accounting/client/contract/IPointsAccountingApiClient'
import { AuthorMapper } from '@/domain/points/accounting/mapper/AuthorMapper'
import { BalanceMapper } from '@/domain/points/accounting/mapper/BalanceMapper'
import { ClientMapper } from '@/domain/points/accounting/mapper/ClientMapper'
import { IAuthorMapper } from '@/domain/points/accounting/mapper/contract/IAuthorMapper'
import type { IBalanceMapper } from '@/domain/points/accounting/mapper/contract/IBalanceMapper'
import { IClientMapper } from '@/domain/points/accounting/mapper/contract/IClientMapper'
import type { IPointsMapper } from '@/domain/points/accounting/mapper/contract/IPointsMapper'
import { ITransactionDetailsMapper } from '@/domain/points/accounting/mapper/contract/ITransactionDetailsMapper'
import { ITransactionMapper } from '@/domain/points/accounting/mapper/contract/ITransactionMapper'
import { PointsMapper } from '@/domain/points/accounting/mapper/PointsMapper'
import { TransactionDetailsMapper } from '@/domain/points/accounting/mapper/TransactionDetailsMapper'
import { TransactionMapper } from '@/domain/points/accounting/mapper/TransactionMapper'
import { BalancePresenter } from '@/domain/points/accounting/presenter/BalancePresenter'
import type { IBalancePresenter } from '@/domain/points/accounting/presenter/contract/IBalancePresenter'
import type { IPointsPresenter } from '@/domain/points/accounting/presenter/contract/IPointsPresenter'
import { PointsPresenter } from '@/domain/points/accounting/presenter/PointsPresenter'
import type { IPointsAccountingRepository } from '@/domain/points/accounting/repository/contract/IPointsAccountingRepository'
import { PointsAccountingRepository } from '@/domain/points/accounting/repository/PointsAccountingRepository'
import { GetBalanceUseCase } from '@/domain/points/accounting/useCase/GetBalanceUseCase'
import { GetTransactionsUseCase } from '@/domain/points/accounting/useCase/GetTransactionsUseCase'
import { PointsAccountingApiClient } from '@/infrastructure/api/points/accounting/PointsAccountingApiClient'
import type { Container } from 'inversify'

export function pointsAccountingContainer(container: Container): void {
  initializeHttpClients(container)
  initializeMapper(container)
  initializeRepositories(container)
  initializePresenters(container)
  initializeUseCases(container)
}

function initializeHttpClients(container: Container): void {
  container
    .bind<IPointsAccountingApiClient>(PointsAccountingTokens.apiClient.IPointsAccountingApiClient)
    .to(PointsAccountingApiClient)
}

function initializeMapper(container: Container): void {
  container
    .bind<IBalanceMapper>(PointsAccountingTokens.mapper.IBalanceMapper)
    .to(BalanceMapper)

  container
    .bind<IPointsMapper>(PointsAccountingTokens.mapper.IPointsMapper)
    .to(PointsMapper)

  container
    .bind<IAuthorMapper>(PointsAccountingTokens.mapper.IAuthorMapper)
    .to(AuthorMapper)

  container
    .bind<IClientMapper>(PointsAccountingTokens.mapper.IClientMapper)
    .to(ClientMapper)

  container
    .bind<ITransactionDetailsMapper>(PointsAccountingTokens.mapper.ITransactionDetailsMapper)
    .to(TransactionDetailsMapper)

  container
    .bind<ITransactionMapper>(PointsAccountingTokens.mapper.ITransactionMapper)
    .to(TransactionMapper)
}

function initializeRepositories(container: Container): void {
  container
    .bind<IPointsAccountingRepository>(PointsAccountingTokens.repository.IPointsAccountingRepository)
    .to(PointsAccountingRepository)
}

function initializePresenters(container: Container): void {
  container
    .bind<IPointsPresenter>(PointsAccountingTokens.presenter.IPointsPresenter)
    .to(PointsPresenter)

  container
    .bind<IBalancePresenter>(PointsAccountingTokens.presenter.IBalancePresenter)
    .to(BalancePresenter)
}

function initializeUseCases(container: Container): void {
  container
    .bind<GetBalanceUseCase>(PointsAccountingTokens.useCase.GetBalanceUseCase)
    .to(GetBalanceUseCase)

  container
    .bind<GetTransactionsUseCase>(PointsAccountingTokens.useCase.GetTransactionsUseCase)
    .to(GetTransactionsUseCase)
}

