import { LicensingTokens } from '@/domain/DITokens'
import type { ILicenseApiClient } from '@/domain/licensing/client/contract/ILicenseApiClient'
import { AccessMapper } from '@/domain/licensing/mapper/AccessMapper'
import { ActivationMapper } from '@/domain/licensing/mapper/ActivationMapper'
import { ApplicationAccessMapper } from '@/domain/licensing/mapper/ApplicationAccessMapper'
import { ApplicationMapper } from '@/domain/licensing/mapper/ApplicationMapper'
import type { IAccessMapper } from '@/domain/licensing/mapper/contract/IAccessMapper'
import type { IActivationMapper } from '@/domain/licensing/mapper/contract/IActivationMapper'
import { IApplicationAccessMapper } from '@/domain/licensing/mapper/contract/IApplicationAccessMapper'
import type { IApplicationMapper } from '@/domain/licensing/mapper/contract/IApplicationMapper'
import type { IDeviceMapper } from '@/domain/licensing/mapper/contract/IDeviceMapper'
import type { IFeatureMapper } from '@/domain/licensing/mapper/contract/IFeatureMapper'
import type { ILicenseMapper } from '@/domain/licensing/mapper/contract/ILicenseMapper'
import type { ILinkMapper } from '@/domain/licensing/mapper/contract/ILinkMapper'
import type { IOwnerMapper } from '@/domain/licensing/mapper/contract/IOwnerMapper'
import type { IUserMapper } from '@/domain/licensing/mapper/contract/IUserMapper'
import { DeviceMapper } from '@/domain/licensing/mapper/DeviceMapper'
import { FeatureMapper } from '@/domain/licensing/mapper/FeatureMapper'
import { LicenseMapper } from '@/domain/licensing/mapper/LicenseMapper'
import { LinkMapper } from '@/domain/licensing/mapper/LinkMapper'
import { OwnerMapper } from '@/domain/licensing/mapper/OwnerMapper'
import { UserMapper } from '@/domain/licensing/mapper/UserMapper'
import type { ILicenseRepository } from '@/domain/licensing/repository/contract/ILicenseRepository'
import { LicenseRepository } from '@/domain/licensing/repository/LicenseRepository'
import { CheckApplicationsAccessUseCase } from '@/domain/licensing/useCase/CheckApplicationsAccessUseCase'
import { ListLicenseActivationsUseCase } from '@/domain/licensing/useCase/ListLicenseActivationsUseCase'
import { ListLicenseActivationsUserUseCase } from '@/domain/licensing/useCase/ListLicenseActivationsUserUseCase'
import { ListLicensesUseCase } from '@/domain/licensing/useCase/ListLicensesUseCase'
import { ManageLicenseActivationsUseCase } from '@/domain/licensing/useCase/ManageLicenseActivationsUseCase'
import { LicenseApiClient } from '@/infrastructure/api/license/LicenseApiClient'
import { Container } from 'inversify'

export function licensingContainer(container: Container): Container {
  initializeHttpClients(container)
  initializeMappers(container)
  initializeRepositories(container)
  initializeUseCases(container)

  return container
}

function initializeHttpClients(container: Container): void {
  container
    .bind<ILicenseApiClient>(LicensingTokens.apiClient.ILicenseApiClient)
    .to(LicenseApiClient)
}

function initializeMappers(container: Container): void {
  container
    .bind<IAccessMapper>(LicensingTokens.mapper.IAccessMapper)
    .to(AccessMapper)

  container
    .bind<IActivationMapper>(LicensingTokens.mapper.IActivationMapper)
    .to(ActivationMapper)

  container
    .bind<IApplicationMapper>(LicensingTokens.mapper.IApplicationMapper)
    .to(ApplicationMapper)

  container
    .bind<IDeviceMapper>(LicensingTokens.mapper.IDeviceMapper)
    .to(DeviceMapper)

  container
    .bind<IFeatureMapper>(LicensingTokens.mapper.IFeatureMapper)
    .to(FeatureMapper)

  container
    .bind<ILicenseMapper>(LicensingTokens.mapper.ILicenseMapper)
    .to(LicenseMapper)

  container
    .bind<ILinkMapper>(LicensingTokens.mapper.ILinkMapper)
    .to(LinkMapper)

  container
    .bind<IOwnerMapper>(LicensingTokens.mapper.IOwnerMapper)
    .to(OwnerMapper)

  container
    .bind<IUserMapper>(LicensingTokens.mapper.IUserMapper)
    .to(UserMapper)

  container
    .bind<IApplicationAccessMapper>(LicensingTokens.mapper.IApplicationAccessMapper)
    .to(ApplicationAccessMapper)
}

function initializeRepositories(container: Container): void {
  container
    .bind<ILicenseRepository>(LicensingTokens.repository.ILicenseRepository)
    .to(LicenseRepository)
}

function initializeUseCases(container: Container): void {
  container
    .bind<ListLicensesUseCase>(LicensingTokens.useCase.ListLicensesUseCase)
    .to(ListLicensesUseCase)

  container
    .bind<ListLicenseActivationsUseCase>(LicensingTokens.useCase.ListLicenseActivationsUseCase)
    .to(ListLicenseActivationsUseCase)

  container
    .bind<ListLicenseActivationsUserUseCase>(LicensingTokens.useCase.ListLicenseActivationsUserUseCase)
    .to(ListLicenseActivationsUserUseCase)

  container
    .bind<ManageLicenseActivationsUseCase>(LicensingTokens.useCase.ManageLicenseActivationsUseCase)
    .to(ManageLicenseActivationsUseCase)

  container
    .bind<CheckApplicationsAccessUseCase>(LicensingTokens.useCase.CheckApplicationsAccessUseCase)
    .to(CheckApplicationsAccessUseCase)
}
