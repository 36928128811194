import { StripeTokens } from '@/domain/DITokens'
import type { ICustomerPaymentMethodsApiClient } from '@/domain/stripe/customer/sub-domains/paymentMethod/client/contract/ICustomerPaymentMethodsApiClient'
import { CardMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/CardMapper'
import type { ICardMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/ICardMapper'
import type { ICustomerPaymentMethodsMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/ICustomerPaymentMethodsMapper'
import type { IPaymentMethodMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/IPaymentMethodMapper'
import {
  ISetupIntentMapper,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/ISetupIntentMapper'
import { CustomerPaymentMethodsMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/CustomerPaymentMethodsMapper'
import { PaymentMethodMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/PaymentMethodMapper'
import { SetupIntentMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/SetupIntentMapper'
import { CardPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/CardPresenter'
import type { ICardPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ICardPresenter'
import type { ICustomerPaymentMethodsPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ICustomerPaymentMethodsPresenter'
import type { IPaymentMethodPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/IPaymentMethodPresenter'
import {
  ISetupIntentPresenter,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/contract/ISetupIntentPresenter'
import { CustomerPaymentMethodsPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/CustomerPaymentMethodsPresenter'
import { PaymentMethodPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/PaymentMethodPresenter'
import { SetupIntentPresenter } from '@/domain/stripe/customer/sub-domains/paymentMethod/presenter/SetupIntentPresenter'
import type { ICustomerPaymentMethodsRepository } from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/contract/ICustomerPaymentMethodsRepository'
import { CustomerPaymentMethodsRepository } from '@/domain/stripe/customer/sub-domains/paymentMethod/repository/CustomerPaymentMethodsRepository'
import {
  CreateSetupIntentUseCase,
} from '@/domain/stripe/customer/sub-domains/paymentMethod/useCase/CreateSetupIntentUseCase'
import { GetCustomerPaymentMethodsUseCase } from '@/domain/stripe/customer/sub-domains/paymentMethod/useCase/GetCustomerPaymentMethodsUseCase'
import { CustomerPaymentMethodsApiClient } from '@/infrastructure/api/stripe/customer/paymentMethod/CustomerPaymentMethodsApiClient'
import { Container } from 'inversify'

export function paymentMethodContainer(container: Container): Container {
  initializeMappers(container)
  initializePresenters(container)
  initializeHttpClients(container)
  initializeRepositories(container)
  initializeUseCases(container)

  return container
}

function initializeHttpClients(container: Container): void {
  container
    .bind<ICustomerPaymentMethodsApiClient>(StripeTokens.PaymentTokens.apiClient.IPaymentMethodApiClient)
    .to(CustomerPaymentMethodsApiClient)
}

function initializeRepositories(container: Container): void {
  container
    .bind<ICustomerPaymentMethodsRepository>(StripeTokens.PaymentTokens.repository.IPaymentMethodRepository)
    .to(CustomerPaymentMethodsRepository)
}

function initializeMappers(container: Container): void {
  container
    .bind<ICardMapper>(StripeTokens.PaymentTokens.mapper.ICardMapper)
    .to(CardMapper)

  container
    .bind<IPaymentMethodMapper>(StripeTokens.PaymentTokens.mapper.IPaymentMethodsMapper)
    .to(PaymentMethodMapper)

  container
    .bind<ICustomerPaymentMethodsMapper>(StripeTokens.PaymentTokens.mapper.IPaymentMethodMapper)
    .to(CustomerPaymentMethodsMapper)

  container
    .bind<ISetupIntentMapper>(StripeTokens.PaymentTokens.mapper.ISetupIntentMapper)
    .to(SetupIntentMapper)
}

function initializePresenters(container: Container): void {
  container
    .bind<ICardPresenter>(StripeTokens.PaymentTokens.presenter.ICardPresenter)
    .to(CardPresenter)

  container
    .bind<IPaymentMethodPresenter>(StripeTokens.PaymentTokens.presenter.IPaymentMethodPresenter)
    .to(PaymentMethodPresenter)

  container
    .bind<ICustomerPaymentMethodsPresenter>(StripeTokens.PaymentTokens.presenter.ICustomerPaymentMethodsPresenter)
    .to(CustomerPaymentMethodsPresenter)

  container
    .bind<ISetupIntentPresenter>(StripeTokens.PaymentTokens.presenter.ISetupIntentPresenter)
    .to(SetupIntentPresenter)
}

function initializeUseCases(container: Container): void {
  container
    .bind<GetCustomerPaymentMethodsUseCase>(StripeTokens.PaymentTokens.useCase.GetPaymentMethodUseCase)
    .to(GetCustomerPaymentMethodsUseCase)

  container
    .bind<CreateSetupIntentUseCase>(StripeTokens.PaymentTokens.useCase.CreateSetupIntentUseCase)
    .to(CreateSetupIntentUseCase)
}
