import type { IOrganizationMdCsaApiClient } from '@/domain/organization/client/contract/IOrganizationMdCsaApiClient'
import type { IOrganizationDto } from '@/domain/organization/client/response/Dtos'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { MdCsaApiClient } from '@/infrastructure/api/MdCsaApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class OrganizationMdCsaApiClient extends MdCsaApiClient implements IOrganizationMdCsaApiClient {
  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  protected get baseUrl(): string {
    const baseUrl = super.baseUrl
    const domain = 'auth'

    return `${baseUrl}/${domain}/${this.version}`
  }

  public async get(): Promise<IOrganizationDto> {
    return await this.httpClient.get(`${this.baseUrl}/admin/organization`)
  }
}
