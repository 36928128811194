import { UserTokens } from '@/domain/DITokens'
import { CannotDeleteUserException } from '@/domain/user/exception/CannotDeleteUserException'
import type { IUserApiClient } from '@/domain/user/client/contract/IUserApiClient'
import { EmailTakenException } from '@/domain/user/exception/EmailTakenException'
import { EmailTakenByInactiveUserException } from '@/domain/user/exception/EmailTakenByInactiveUserException'
import { InvalidCountryIdException } from '@/domain/user/exception/InvalidCountryIdException'
import { InvalidEmailException } from '@/domain/user/exception/InvalidEmailException'
import { InvalidFirstNameException } from '@/domain/user/exception/InvalidFirstNameException'
import { InvalidInvitationContextException } from '@/domain/user/exception/InvalidInvitationContextException'
import { InvalidLastNameException } from '@/domain/user/exception/InvalidLastNameException'
import { InvalidParametersException } from '@/domain/user/exception/InvalidParametersException'
import { CannotEditEmailException } from '@/domain/user/exception/CannotEditEmailException'
import { CannotEditUserException } from '@/domain/user/exception/CannotEditUserException'
import { InvalidUserException } from '@/domain/user/exception/InvalidUserException'
import { UnauthorizedException } from '@/domain/user/exception/UnauthorizedException'
import { UserNotFoundException } from '@/domain/user/exception/UserNotFoundException'
import type { IUserMapper } from '@/domain/user/mapper/contract/IUserMapper'
import { User } from '@/domain/user/model/User'
import type { IUserRepository } from '@/domain/user/repository/contract/IUserRepository'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'
import { MalformedEmailAddressException } from '@/domain/user/exception/MalformedEmailAddressException'
import { CannotResetPasswordUserException } from '@/domain/user/exception/CannotResetPasswordUserException'
import { CannotSendInvitationUserException } from '@/domain/user/exception/CannotSendInvitationUserException'
import { InvitationAlreadyConfirmedException } from '@/domain/user/exception/InvitationAlreadyConfirmedException'
import { ForbiddenException } from '@/domain/user/exception/ForbiddenException'

@injectable()
export class UserRepository implements IUserRepository {
  constructor(
    @inject(UserTokens.apiClient.IUserApiClient)
    private readonly apiClient: IUserApiClient,
    @inject(UserTokens.mapper.IUserMapper)
    private readonly userMapper: IUserMapper,
  ) {
  }

  private static handleGetUserByIdError(error: HttpError): never {
    switch (error.message) {
      case 'INVALID_USER':
        throw new InvalidUserException()
      case 'UNAUTHORIZED':
      case 'FORBIDDEN':
      default:
        throw new UnauthorizedException()
    }
  }

  private static handleGetUserProfile(error: HttpError): never {
    switch (error.message) {
      case 'INVALID_PARAMETERS':
      default:
        throw new InvalidParametersException()
    }
  }

  private static handleInviteUserError(error: HttpError): never {
    switch (error.message) {
      case 'INVALID_PARAMETERS':
        throw new InvalidParametersException()
      case 'EMAIL_TAKEN':
        throw new EmailTakenException()
      case 'INVALID_EMAIL':
        throw new InvalidEmailException()
      case 'INVALID_FIRST_NAME':
        throw new InvalidFirstNameException()
      case 'INVALID_LAST_NAME':
        throw new InvalidLastNameException()
      case 'INVALID_COUNTRY_ID':
        throw new InvalidCountryIdException()
      case 'EMAIL_TAKEN_BY_INACTIVE_USER':
        throw new EmailTakenByInactiveUserException()
      case 'INVALID_INVITATION_CONTEXT':
        throw new InvalidInvitationContextException()
      case 'UNAUTHORIZED':
      default:
        throw new UnauthorizedException()
    }
  }

  private static handleEditUserError(error: HttpError): never {
    switch (error.message) {
      case 'INVALID_USER':
        throw new InvalidUserException()
      case 'CANNOT_EDIT_EMAIL':
        throw new CannotEditEmailException()
      case 'CAN_NOT_EDIT_USER':
        throw new CannotEditUserException()
      default:
        throw new UnauthorizedException()
    }
  }

  private static handleDeleteUserError(error: HttpError): never {
    switch (error.message) {
      case 'INVALID_USER':
        throw new InvalidUserException()
      case 'USER_NOT_FOUND':
        throw new UserNotFoundException()
      case 'UNAUTHORIZED':
        throw new UnauthorizedException()
      default:
        throw new CannotDeleteUserException()
    }
  }

  private static handleResetPasswordUserError(error: HttpError): never {
    switch (error.message) {
      case 'INVALID_PARAMETERS':
        throw new InvalidParametersException()
      case 'MALFORMED_EMAIL_ADDRESS':
        throw new MalformedEmailAddressException()
      default:
        throw new CannotResetPasswordUserException()
    }
  }

  private static handleResendInvitationUserError(error: HttpError): never {
    switch (error.message) {
      case 'INVITATION_ALREADY_CONFIRMED':
        throw new InvitationAlreadyConfirmedException()
      case 'FORBIDDEN':
        throw new ForbiddenException()
      default:
        throw new CannotSendInvitationUserException()
    }
  }

  public async getUserById(id: string): Promise<User> {
    try {
      const data = await this.apiClient.getUserById(id)

      return this.userMapper.toModel(data)
    } catch (error) {
      if (error instanceof HttpError) {
        UserRepository.handleGetUserByIdError(error)
      }

      throw error
    }
  }

  public async edit(user: User): Promise<void> {
    try {
      const request = this.userMapper.toDto(user)

      await this.apiClient.edit(user.id, request)
    } catch (error) {
      if (error instanceof HttpError) {
        UserRepository.handleEditUserError(error)
      }
      throw error
    }
  }

  public async deleteUser(id: string): Promise<void> {
    try {
      await this.apiClient.deleteUser(id)
    } catch (error) {
      if (error instanceof HttpError) {
        UserRepository.handleDeleteUserError(error)
      }
      throw error
    }
  }

  public async inviteUser(user: User): Promise<void> {
    try {
      const request = this.userMapper.toDto(user)
      await this.apiClient.inviteUser(request)
    } catch (error) {
      if (error instanceof HttpError) {
        UserRepository.handleInviteUserError(error)
      }
    }
  }

  public async resetPassword(email: string): Promise<void> {
    try {
      await this.apiClient.resetPassword(email)
    } catch (error) {
      if (error instanceof HttpError) {
        UserRepository.handleResetPasswordUserError(error)
      }
      throw error
    }
  }

  public async resendInvitation(userId: string): Promise<void> {
    try {
      await this.apiClient.resendInvitation(userId)
    } catch (error) {
      if (error instanceof HttpError) {
        UserRepository.handleResendInvitationUserError(error)
      }
      throw error
    }
  }

  public async getUserProfile(): Promise<User> {
    try {
      const data = await this.apiClient.getUserProfile()

      return this.userMapper.toModel(data)
    } catch (error) {
      if (error instanceof HttpError) {
        UserRepository.handleGetUserProfile(error)
      }
      throw error
    }
  }
}
