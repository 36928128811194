import { SharedTokens, StaticsTokens } from '@/domain/DITokens'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import { Country } from '@/domain/statics/model/Country'
import type { ICountriesPresentation } from '@/domain/statics/presentation/contract/ICountriesPresentation'
import { CouldNotGetCountriesPresentation } from '@/domain/statics/presentation/CouldNotGetCountriesPresentation'
import { CountriesPresentation } from '@/domain/statics/presentation/CountriesPresentation'
import { CountryPresentation } from '@/domain/statics/presentation/CountryPresentation'
import type { ICountryPresenter } from '@/domain/statics/presenter/contract/ICountryPresenter'
import { CountriesException } from '@/domain/statics/repository/contract/exception/CountriesException'
import type { IStaticsRepository } from '@/domain/statics/repository/contract/IStaticsRepository'
import { inject, injectable } from 'inversify'

export const DEFAULT_COUNTRY: CountryPresentation = new CountryPresentation(
  '220',
  'United States',
  'US',
  'USD',
)

@injectable()
export class GetCountriesUseCase {
  constructor(
    @inject(StaticsTokens.repository)
    private readonly staticsRepository: IStaticsRepository,
    @inject(StaticsTokens.countryPresenter)
    private readonly countryPresenter: ICountryPresenter,
    @inject(SharedTokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
  ) {
  }

  public async execute(): Promise<ICountriesPresentation> {

    try {
      const countries: Country[] = await this.staticsRepository.loadAll()
      return new CountriesPresentation(
        countries.map((country: Country) => this.countryPresenter.present(country)),
      )
    } catch (e) {
      if (e instanceof CountriesException) {
        return new CouldNotGetCountriesPresentation(
          this.localizedStringProvider.getText('shared.domainError.serverError'),
        )
      }
      throw e
    }
  }
}
