import { PointsAccountingTokens, SharedTokens } from '@/domain/DITokens'
import { CouldNotGetBalanceException } from '@/domain/points/accounting/exception/CouldNotGetBalanceException'
import { OrganizationNotFoundException } from '@/domain/points/accounting/exception/OrganizationNotFoundException'
import { PointsAccountingException } from '@/domain/points/accounting/exception/PointsAccountingException'
import { Balance } from '@/domain/points/accounting/model/Balance'
import { BalanceExceptionPresentation, BalancePresentation } from '@/domain/points/accounting/presentation/BalancePresentation'
import type { IBalancePresenter } from '@/domain/points/accounting/presenter/contract/IBalancePresenter'
import type { IPointsPresenter } from '@/domain/points/accounting/presenter/contract/IPointsPresenter'
import type { ILocalizedStringProvider } from '@/domain/shared/contract/ILocalizedStringProvider'
import { inject, injectable } from 'inversify'

@injectable()
export class BalancePresenter implements IBalancePresenter {
  constructor(
    @inject(PointsAccountingTokens.presenter.IPointsPresenter)
    private readonly pointsPresenter: IPointsPresenter,
    @inject(SharedTokens.ILocalizedStringProvider)
    private readonly localizedStringProvider: ILocalizedStringProvider,
  ) {
  }

  public present(balance: Balance): BalancePresentation {
    return new BalancePresentation(
      this.pointsPresenter.present(balance.points),
      balance.organizationId,
    )
  }

  public presentException(exception: PointsAccountingException): BalanceExceptionPresentation {
    let message: string

    if (exception instanceof OrganizationNotFoundException) {
      message = this.localizedStringProvider.getText('points.accounting.error.organizationNotFound')
    } else if (exception instanceof CouldNotGetBalanceException) {
      message = this.localizedStringProvider.getText('points.accounting.error.couldNotGetBalance')
    } else {
      message = this.localizedStringProvider.getText('unexpectedError')
    }

    return new BalanceExceptionPresentation(message)
  }
}
