import { OrganizationTokens } from '@/domain/DITokens'
import type { IOrganizationRequestDto } from '@/domain/organization/client/request/Dto'
import type { IOrganizationDto } from '@/domain/organization/client/response/Dtos'
import type { ICountryMapper } from '@/domain/organization/mapper/contract/ICountryMapper'
import type { IOrganizationMapper } from '@/domain/organization/mapper/contract/IOrganizationMapper'
import type { IUserMapper } from '@/domain/organization/mapper/contract/IUserMapper'
import { Organization } from '@/domain/organization/model/Organization'
import { inject, injectable } from 'inversify'

@injectable()
export class OrganizationMapper implements IOrganizationMapper {
  constructor(
    @inject(OrganizationTokens.mapper.IUserMapper)
    private readonly userMapper: IUserMapper,
    @inject(OrganizationTokens.mapper.ICountryMapper)
    private readonly countryMapper: ICountryMapper,
  ) {
  }

  public toModel(dto: IOrganizationDto): Organization {
    const users = dto.users.map(user => this.userMapper.toModel(user))
    const country = this.countryMapper.toModel(dto.country)

    return new Organization(
      dto.id,
      dto.name,
      dto.address,
      dto.city,
      country,
      dto.website,
      dto.phone_number,
      users,
      dto.metadata,
    )
  }

  public toDto(model: Organization): IOrganizationRequestDto {
    return {
      address: model.address,
      city: model.city,
      country_id: model.country.id,
      website: model.website,
      phone_number: model.phoneNumber,
    }
  }
}
