import { Invoice } from '@/domain/stripe/invoice/model/Invoice'
import { trackActionExecutionProgress } from '@/infrastructure/store/ActionLoadingIndicator'
import { IMyInvoicesStore } from '@/infrastructure/store/modules/contract/billing/IMyInvoiceStore'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'BillingMyInvoices',
  namespaced: true,
})
export default class MyInvoicesStore extends VuexModule implements IMyInvoicesStore {
  private _invoices: Invoice[] = []

  public get invoices(): Invoice[] {
    return this._invoices
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  @HandledAction({ commit: 'setInvoices' })
  public async listMyInvoices(callback: () => Promise<Invoice[]>): Promise<Invoice[] | void> {
    return trackActionExecutionProgress(callback, this.setIsLoading)
  }

  @Mutation
  private setInvoices(invoices: Invoice[]): void {
    this._invoices = invoices
  }

  @Mutation
  private setIsLoading(value: boolean): void {
    this._isLoading = value
  }
}
