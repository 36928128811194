import { HttpError } from '@/infrastructure/api/http/HttpError'
import { StatusCode } from '@/infrastructure/api/http/StatusCode'
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders, RawAxiosRequestHeaders } from 'axios'
import axios from 'axios'
import { injectable } from 'inversify'
import merge from 'lodash/merge'

export type TokenProvider = () => string | undefined
export type InterceptorConfiguration = Partial<{
  onRequest: (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>,
  onResponse: Partial<{
    succeed: (response: AxiosResponse) => Promise<AxiosResponse>,
    failed: (error: HttpError) => Promise<AxiosResponse>
  }>,
}>

export interface IHttpClient {
  get<T>(url: string): Promise<T>

  getPaginated<T>(url: string):  Promise<{ data: T; headers: any }>

  post<T, P>(url: string, payload?: P): Promise<T>

  put<T, P>(url: string, payload?: P): Promise<T>

  patch<T, P>(url: string, payload?: P): Promise<T>

  delete<T>(url: string): Promise<T>

  doRequest<T, P>(config: AxiosRequestConfig<P>): Promise<AxiosResponse<T>>

  addInterceptor(configuration: InterceptorConfiguration): void

  clearInterceptors(): void
}

@injectable()
export class HttpClient implements IHttpClient {
  private readonly http: AxiosInstance

  constructor(
    private readonly tokenProvider?: TokenProvider,
    private readonly config?: AxiosRequestConfig,
  ) {
    this.http = axios.create(config)
    this.http.interceptors.response.use(
      (response) => response,
      HttpClient.handleError,
    )
  }

  private static async handleError(error: AxiosError<Record<string, string>>): Promise<void> {
    const { response } = error

    if (!response) {
      return Promise.reject(new HttpError(error.code ?? 'UNKNOWN_ERROR', 500))
    }

    const { data, status } = response

    if (data?.error) {
      switch (status) {
        case StatusCode.BadRequest:
        case StatusCode.Unauthorized:
        case StatusCode.Forbidden:
        case StatusCode.NotFound:
        case StatusCode.TooManyRequests:
        case StatusCode.InternalServerError:
          throw new HttpError(
            data.error,
            status,
            response,
          )
      }
    }

    if (status >= 400 && status < 500) {
      throw new HttpError('UNEXPECTED_REQUEST_ERROR', status)
    } else if (status >= 500 && status < 600) {
      throw new HttpError('UNEXPECTED_SERVER_ERROR', status)
    } else {
      throw new HttpError('UNEXPECTED_ERROR', status)
    }
  }

  public async get<T>(url: string): Promise<T> {
    const { data } = await this.doRequest<T>({ url, method: 'GET' })

    return data
  }

  public async getPaginated<T>(url: string): Promise<{ data: T; headers: any }> {
    const response = await this.doRequest<T>({ url, method: 'GET' })

    return {
      data: response.data,
      headers: response.headers,
    }
  }

  public async post<T, P>(url: string, payload?: P): Promise<T> {
    const { data } = await this.doRequest<T, P>({ url, method: 'POST', data: payload })

    return data
  }

  public async put<T, P>(url: string, payload?: P): Promise<T> {
    const { data } = await this.doRequest<T, P>({ url, method: 'PUT', data: payload })

    return data
  }

  public async patch<T, R>(url: string, payload?: R): Promise<T> {
    const { data } = await this.doRequest<T, R>({ url, method: 'PATCH', data: payload })

    return data
  }

  public async delete<T>(url: string): Promise<T> {
    const { data } = await this.doRequest<T>({ url, method: 'DELETE' })

    return data
  }

  public addInterceptor(configuration: InterceptorConfiguration): void {
    this.http.interceptors.request.use(configuration.onRequest)
    this.http.interceptors.response.use(
      configuration.onResponse?.succeed,
      configuration.onResponse?.failed,
    )
  }

  public clearInterceptors(): void {
    this.http.interceptors.request.clear()
  }

  public async doRequest<T, R = undefined>(config: AxiosRequestConfig<R>): Promise<AxiosResponse<T>> {
    config.headers = merge(config.headers, this.prepareHeaders())

    return this.http(config)
  }

  private prepareHeaders(): RawAxiosRequestHeaders {
    const headers: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
    }

    if (this.tokenProvider) {
      headers['Authorization'] = `Bearer ${this.tokenProvider()}`
    }

    return headers
  }
}
