import type { ISubscription } from '@/domain/stripe/customer/sub-domains/subscription/model/contract/ISubscription'
import { SubscriptionItem } from '@/domain/stripe/customer/sub-domains/subscription/model/SubscriptionItem'
import { Validity } from '@/domain/stripe/customer/sub-domains/subscription/model/Validity'
import { Invoice } from '@/domain/stripe/invoice/model/Invoice'
import { StripeModel } from '@/domain/stripe/shared/model/StripeModel'

export class Subscription extends StripeModel implements ISubscription {

  public constructor(
    public readonly id: string,
    public readonly status: string,
    public readonly items: SubscriptionItem[],
    public readonly validity: Validity,
    public readonly latestInvoice: Invoice,
  ) {
    super()
  }

  public get totalQuantity(): number {
    return this.items
      .reduce((sum, current) => sum + current.quantity, 0)
  }

  public toggleAutoRenew(autoRenew: boolean) {
    this.validity.autoRenew = autoRenew
  }

  public markLatestInvoicePaid() {
    this.latestInvoice.markPaid()
    this.isSynchronized = this.isSynchronized && this.latestInvoice.isSynchronized
  }
}
