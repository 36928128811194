import { ICouponCodeApiClient } from '@/domain/stripe/couponCode/client/contract/ICouponCodeApiClient'
import type { ICouponCodeDto } from '@/domain/stripe/couponCode/client/dto/Dtos'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { StripeApiClient } from '@/infrastructure/api/stripe/StripeApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class CouponApiClient extends StripeApiClient implements ICouponCodeApiClient {
  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  public async validateCoupon(couponCode: string): Promise<ICouponCodeDto> {
    const requestUrl = `${this.baseUrl}/coupon/${couponCode}/validate`

    return await this.httpClient.get(requestUrl)
  }
}
