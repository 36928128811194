const apiClient = {
  IInvoiceApiClient: Symbol('IInvoiceApiClient'),
}

const repository = {
  IInvoiceRepository: Symbol('IInvoiceRepository'),
}

const mapper = {
  ITotalAmountMapper: Symbol('ITotalAmountMapper'),
  IPaymentIntentMapper: Symbol('IPaymentIntentMapper'),
  IPaymentMapper: Symbol('IPaymentMapper'),
  ILinkMapper: Symbol('ILinkMapper'),
  IInvoiceMapper: Symbol('IInvoiceMapper'),
  IInvoiceLinesMapper: Symbol('IInvoiceLinesMapper'),
}

const presenter = {
  IInvoicePresenter: Symbol('IInvoicePresenter'),
  ITotalAmountPresenter: Symbol('ITotalAmountPresenter'),
  IInvoiceLinePresenter: Symbol('IInvoiceLinePresenter'),
}

const useCase = {
  GetInvoiceUseCase: Symbol('GetInvoiceUseCase'),
  GetInvoicesUseCase: Symbol('GetInvoicesUseCase'),
}

export default {
  apiClient,
  repository,
  mapper,
  presenter,
  useCase,
}
