import { Permissions } from '@/domain/authenticating/authentication/presentation/UserProfilePresentation'
import { injectable } from 'inversify'
import uniq from 'lodash/uniq'

@injectable()
export class HasRequiredPermissionsUseCase {
  constructor(
    private readonly permissions: string[],
  ) {
  }

  public execute(userPermissions: Permissions): boolean {
    const userPermissionArray: string[] = uniq(Object.values(userPermissions).flat())

    return userPermissionArray.some((userPermission) =>
      this.permissions.some(permission =>
        permission.toLowerCase() === userPermission.toLowerCase(),
      ),
    )
  }
}
