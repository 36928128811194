import { Transaction } from '@/domain/points/accounting/model/Transaction'
import { trackActionExecutionProgress } from '@/infrastructure/store/ActionLoadingIndicator'
import { IPointsUsageDetailsStore, PaginationData } from '@/infrastructure/store/modules/contract/admin/IPointsUsageDetails.store'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'AdminPointsUsageDetails',
  namespaced: true,
})

export default class PointsUsageDetailsStore extends VuexModule implements IPointsUsageDetailsStore {

  private _transactions = [] as Transaction[]

  public get transactions(): Transaction[] {
    return this._transactions
  }

  private _isLoading = false

  public get isLoading(): boolean{
    return this._isLoading
  }

  private _currentPage = 1

  public get currentPage(): number {
    return this._currentPage
  }

  private _totalPages = 0

  public get totalPages(): number {
    return this._totalPages
  }

  private _totalCount = 0

  public get totalCount(): number {
    return this._totalCount
  }

  @Mutation
  public updateIsLoading(value: boolean) {
    this._isLoading = value
  }

  @Mutation
  public updateTransactions(transactions: Transaction[]): void {
    this._transactions = transactions
  }

  @Mutation
  public setPaginationData(paginationData: PaginationData): void {
    this._currentPage = paginationData.currentPage
    this._totalPages = paginationData.totalPages
    this._totalCount = paginationData.totalCount
  }

  @HandledAction({ commit: 'updateTransactions' })
  public async getTransactions(callback: () => Promise<Transaction[] | void>): Promise<Transaction[] | void> {
    return trackActionExecutionProgress(callback, this.updateIsLoading)
  }

  @Mutation
  public reset() {
    this._transactions = [] as Transaction[]
    this._isLoading = false
    this._currentPage = 1
    this._totalPages = 0
    this._totalCount = 0
  }
}
