import { OrganizationTokens } from '@/domain/DITokens'
import type { IOrganizationApiClient } from '@/domain/organization/client/contract/IOrganizationApiClient'
import type { IOrganizationMdCsaApiClient } from '@/domain/organization/client/contract/IOrganizationMdCsaApiClient'
import { CannotEditOrganizationException } from '@/domain/organization/exception/CannotEditOrganizationException'
import { InvalidPhoneNumberException } from '@/domain/organization/exception/InvalidPhoneNumberException'
import { OrganizationNameTakenException } from '@/domain/organization/exception/OrganizationNameTakenException'
import { UnauthorizedException } from '@/domain/organization/exception/UnauthorizedException'
import { ForbiddenException } from '@/domain/organization/exception/ForbiddenException'
import type { IOrganizationMapper } from '@/domain/organization/mapper/contract/IOrganizationMapper'
import { Organization } from '@/domain/organization/model/Organization'
import type { IOrganizationRepository } from '@/domain/organization/repository/contract/IOrganizationRepository'
import { HttpError } from '@/infrastructure/api/http/HttpError'
import { inject, injectable } from 'inversify'

@injectable()
export class OrganizationRepository implements IOrganizationRepository {
  constructor(
    @inject(OrganizationTokens.apiClient.IOrganizationApiClient)
    private readonly apiClient: IOrganizationApiClient,
    @inject(OrganizationTokens.apiClient.IOrganizationMdCsaApiClient)
    private readonly organizationApiClient: IOrganizationMdCsaApiClient,
    @inject(OrganizationTokens.mapper.IOrganizationMapper)
    private readonly organizationMapper: IOrganizationMapper,
  ) {
  }

  private static handleGetOrganizationError(error: HttpError): never {
    switch (error.message) {
      case 'FORBIDDEN':
        throw new ForbiddenException()
      case 'UNAUTHORIZED':
      default:
        throw new UnauthorizedException()
    }
  }

  private static handleEditOrganizationError(error: HttpError): never {
    switch (error.message) {
      case 'ORGANIZATION_NAME_TAKEN':
        throw new OrganizationNameTakenException()
      case 'INVALID_PHONE_NUMBER':
        throw new InvalidPhoneNumberException()
      case 'CANNOT_EDIT_ORGANIZATION':
        throw new CannotEditOrganizationException()
      case 'UNAUTHORIZED':
      case 'FORBIDDEN':
      default:
        throw new UnauthorizedException()
    }
  }

  public async get(): Promise<Organization> {
    try {
      const data = await this.organizationApiClient.get()

      return this.organizationMapper.toModel(data)
    } catch (error) {
      if (error instanceof HttpError) {
        OrganizationRepository.handleGetOrganizationError(error)
      }

      throw error
    }
  }

  public async edit(organization: Organization): Promise<void> {
    try {
      const request = this.organizationMapper.toDto(organization)
      await this.apiClient.edit(organization.id, request)
    } catch (error) {
      if (error instanceof HttpError) {
        OrganizationRepository.handleEditOrganizationError(error)
      }
      throw error
    }
  }
}
