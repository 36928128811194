export enum ProductType {
  Subscription = 'subscription',
  Point = 'point',
}

export class Product {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly description: string,
    public readonly metadata: Record<string, string>,
  ) {
  }

  public get isCustomPointPack(): boolean {
    return this.metadata['custom'] === 'true' ?? false
  }
}
