import { Country } from '@/domain/user/model/Country'
import { Organization } from '@/domain/user/model/Orgaization'
import { Status } from '@/domain/user/model/Status'

export interface IUserMetadata {
  permissions?: string[]
  callback_url?: string
}

export class User {
  constructor(
    public readonly id: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly email: string | null,
    public readonly status: Status,
    public readonly country: Country,
    public readonly organization: Organization,
    public readonly permissions?: Record<string, string[]>,
    public readonly metadata?: IUserMetadata,
  ) {
  }

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  public static create(user: Partial<User>): User {
    return Object.assign(Object.create(this.prototype), user)
  }

  public get identityConfirmed(): boolean {
    return !!this.status.emailConfirmationDate || !!this.status.activationDate
  }
}
