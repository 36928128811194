import type { ITotalAmountDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { ITotalAmountMapper } from '@/domain/stripe/quote/mapper/contract/ITotalAmountMapper'
import { TotalAmount } from '@/domain/stripe/quote/model/TotalAmount'
import { injectable } from 'inversify'

@injectable()
export class TotalAmountMapper implements ITotalAmountMapper {
  public toModel(totalAmount: ITotalAmountDto): TotalAmount {
    return new TotalAmount(
      totalAmount.currency,
      totalAmount.amount,
      totalAmount.interval,
    )
  }
}
