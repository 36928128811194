import {
  MySubscriptionsPresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/MySubscriptionsPresentation'
import {
  SubscriptionPresentation,
} from '@/domain/stripe/customer/sub-domains/subscription/presentation/SubscriptionPresentation'
import { trackActionExecutionProgress } from '@/infrastructure/store/ActionLoadingIndicator'
import type { IMySubscriptionsStore } from '@/infrastructure/store/modules/contract/billing/IMySubscriptionsStore'
import type {
  ISubscriptionActionStore,
} from '@/infrastructure/store/modules/contract/billing/ISubscriptionAction.store'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'BillingMySubscriptions',
  namespaced: true,
})
export default class MySubscriptionsStore extends VuexModule implements IMySubscriptionsStore, ISubscriptionActionStore {

  private _mySubscriptions = MySubscriptionsPresentation.EMPTY

  public get mySubscriptions(): MySubscriptionsPresentation {
    return this._mySubscriptions
  }

  private _isLoading = false

  public get isLoading(): boolean{
    return this._isLoading
  }

  @Mutation
  public updateIsLoading(value: boolean) {
    this._isLoading = value
  }

  private _actionInProgress = false

  public get actionInProgress(): boolean {
    return this._actionInProgress
  }

  @Mutation
  public updateActionInProgress(value: boolean) {
    this._actionInProgress = value
  }

  @Mutation
  public updateSubscription(updatedSubscription: SubscriptionPresentation): void {
    const subscriptionPresentations = (this._mySubscriptions as MySubscriptionsPresentation)?.subscriptions
    const oldSubscriptionIndex = subscriptionPresentations?.findIndex((subscription => updatedSubscription.id === subscription.id))
    if (oldSubscriptionIndex >= 0 && subscriptionPresentations) {
      subscriptionPresentations.splice(oldSubscriptionIndex, 1, updatedSubscription)
    }
  }

  @Mutation
  updateMySubscriptions(mySubscriptions: MySubscriptionsPresentation): void {
    this._mySubscriptions = mySubscriptions
  }

  @HandledAction({ commit: 'updateSubscription' })
  public async executeAction(callback: () => Promise<SubscriptionPresentation | void>): Promise<SubscriptionPresentation | void> {
    return trackActionExecutionProgress(callback, this.updateActionInProgress)
  }

  @HandledAction({ commit: 'updateMySubscriptions' })
  public async listMySubscriptions(callback: () => Promise<MySubscriptionsPresentation | void>): Promise<MySubscriptionsPresentation | void> {
    return trackActionExecutionProgress(callback, this.updateIsLoading)
  }

  @Mutation
  public reset() {
    this._mySubscriptions = MySubscriptionsPresentation.EMPTY
    this._actionInProgress = false
    this._isLoading = false
  }
}
