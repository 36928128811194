import { OrganizationTokens } from '@/domain/DITokens'
import type { IOrganizationRepository } from '@/domain/organization/repository/contract/IOrganizationRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class GetMyOrganizationUseCase {
  constructor(
    @inject(OrganizationTokens.repository.IOrganizationRepository)
    private readonly organizationRepository: IOrganizationRepository,
  ) {
  }

  public async execute() {
    return await this.organizationRepository.get()
  }
}
