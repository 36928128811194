import type { IProductDto } from '@/domain/stripe/quote/client/dto/Dtos'
import type { IProductMapper } from '@/domain/stripe/quote/mapper/contract/IProductMapper'
import { Product } from '@/domain/stripe/quote/model/Product'
import { injectable } from 'inversify'

@injectable()
export class ProductMapper implements IProductMapper {
  public toModel(product: IProductDto): Product {
    return new Product(
      product.id,
      product.name,
      product.description,
      product.metadata,
    )
  }
}
