import { License } from '@/domain/licensing/model/License'
import { IMyLicensesStore } from '@/infrastructure/store/modules/contract/dashboard/IMyLicenses.store'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'DashboardMyLicenses',
  namespaced: true,
})
export default class MyLicensesStore extends VuexModule implements IMyLicensesStore {
  private _myLicenses: License[] = []

  public get myLicenses(): License[] {
    return this._myLicenses
  }

  private _loadingLicenseActivations: Record<string, boolean> = {}

  public get loadingLicenseActivations(): Record<string, boolean> {
    return this._loadingLicenseActivations
  }

  @Mutation
  public reset(): void {
    this._myLicenses = []
  }

  @Mutation
  public updateMyLicenses(value: License[]): void {
    this._myLicenses = value
  }

  @Mutation
  public startLoadingActivations(licenseId: string): void {
    this._loadingLicenseActivations = { ...this.loadingLicenseActivations, [licenseId]: true }
  }

  @Mutation
  public stopLoadingActivations(licenseId: string): void {
    this._loadingLicenseActivations = { ...this.loadingLicenseActivations, [licenseId]: false }
  }


  @HandledAction({ commit: 'updateMyLicenses' })
  public getMyLicenses<T = License[]>(callback: () => Promise<T | void>): Promise<T | void> {
    return callback()
  }
}
