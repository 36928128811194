export class InvoiceLine {

  constructor(
    public readonly name: string,
    public readonly quantity: number,
    public readonly platform: string,
    public readonly productMetadata: Record<string, string>,
  ) {
  }
}
