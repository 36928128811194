import { Country } from '@/domain/organization/model/Country'
import { Status } from '@/domain/organization/model/Status'

export class User {
  constructor(
    public readonly id: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly email: string,
    public readonly status: Status,
    public readonly country: Country,
    public readonly permissions?: Record<string, string[]>,
  ) {
  }

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }
}
