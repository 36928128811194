import type { ISetupIntentDto } from '@/domain/stripe/customer/sub-domains/paymentMethod/client/dto/Dtos'
import type { ISetupIntentMapper } from '@/domain/stripe/customer/sub-domains/paymentMethod/mapper/contract/ISetupIntentMapper'
import { SetupIntent } from '@/domain/stripe/customer/sub-domains/paymentMethod/model/SetupIntent'
import { injectable } from 'inversify'

@injectable()
export class SetupIntentMapper implements ISetupIntentMapper {
  public toModel(dto: ISetupIntentDto): SetupIntent {
    return new SetupIntent(
      dto.id,
      dto.secret,
      dto.status,
    )
  }
}
