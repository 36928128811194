import { IAuthorizationApiClient } from '@/domain/authenticating/authorization/client/contract/IAuthorizationApiClient'
import { AuthorizationRepository } from '@/domain/authenticating/authorization/repository/AuthorizationRepository'
import type { IAuthorizationRepository } from '@/domain/authenticating/authorization/repository/contract/IAuthorizationRepository'
import { ConfirmAccessUseCase } from '@/domain/authenticating/authorization/useCase/ConfirmAccessUseCase'
import { RefreshTokenUseCase } from '@/domain/authenticating/authorization/useCase/RefreshTokenUseCase'
import { AuthorizationTokens } from '@/domain/authenticating/DITokens'
import { AuthorizationApiClient } from '@/infrastructure/api/authenticating/authorization/AuthorizationApiClient'

import type { Container } from 'inversify'

export function authorizationContainer(container: Container): Container {
  apiClients(container)
  repositories(container)
  useCases(container)

  return container
}

function apiClients(container: Container): void {
  container
    .bind<IAuthorizationApiClient>(AuthorizationTokens.apiClient.IAuthorizationApiClient)
    .to(AuthorizationApiClient)
}

function repositories(container: Container): void {
  container
    .bind<IAuthorizationRepository>(AuthorizationTokens.repository.IAuthorizationRepository)
    .to(AuthorizationRepository)
}

function useCases(container: Container): void {
  container
    .bind<RefreshTokenUseCase>(AuthorizationTokens.useCase.RefreshTokenUseCase)
    .to(RefreshTokenUseCase)

  container
    .bind<ConfirmAccessUseCase>(AuthorizationTokens.useCase.ConfirmAccessUseCase)
    .to(ConfirmAccessUseCase)
}
