import { StripeTokens } from '@/domain/DITokens'
import { ProductType } from '@/domain/stripe/quote/model/Product'
import type { IQuoteRepository } from '@/domain/stripe/quote/repository/contract/IQuoteRepository'
import { inject, injectable } from 'inversify'

@injectable()
export class ClearQuoteCacheUseCase {
  constructor(
    @inject(StripeTokens.QuoteTokens.repository.IQuoteRepository)
    private readonly quoteRepository: IQuoteRepository,
  ) {
  }

  public async execute(productType: ProductType): Promise<void> {
    await this.quoteRepository.clearCache(productType)
  }
}
