import type { IAuthorizationApiClient } from '@/domain/authenticating/authorization/client/contract/IAuthorizationApiClient'
import { TokensDto } from '@/domain/authenticating/authorization/client/response/TokensDto'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { MdCsaApiClient } from '@/infrastructure/api/MdCsaApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class AuthorizationApiClient
  extends MdCsaApiClient
  implements IAuthorizationApiClient {

  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  protected get baseUrl(): string {
    const baseUrl = super.baseUrl
    const domain = 'auth'

    return `${baseUrl}/${domain}/${this.version}`
  }

  protected get backendBaseUrl(): string {
    const baseUrl = `${process.env.VUE_APP_BACKEND_SCHEME}://${process.env.VUE_APP_BACKEND_HOST}`

    return `${baseUrl}`
  }

  public async refresh(refreshToken: string): Promise<TokensDto> {
    const url = `${this.baseUrl}/refresh_token`

    return await this.httpClient.post(url, { token: refreshToken })
  }

  public async confirmAccess(): Promise<string> {
    return await this.httpClient.get(`${this.backendBaseUrl}/confirm_access`)
  }
}
