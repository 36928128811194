import type { IActivationUserWithIdDto } from '@/domain/licensing/client/response/Dtos'
import type { IUserMapper } from '@/domain/licensing/mapper/contract/IUserMapper'
import { User } from '@/domain/licensing/model/User'
import { injectable } from 'inversify'

@injectable()
export class UserMapper implements IUserMapper {
  public toModel(dto: IActivationUserWithIdDto): User {
    return new User(
      dto.id,
      dto.name,
      dto.email,
    )
  }
}
