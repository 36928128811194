import { SubscriptionItem } from '@/domain/stripe/customer/sub-domains/subscription/model/SubscriptionItem'
import { Invoice } from '@/domain/stripe/invoice/model/Invoice'

export class SubscriptionPresentationInput {

  constructor(
    public readonly id: string,
    public readonly totalQuantity: number,
    public readonly status: string,
    public readonly latestInvoice: Invoice,
    public readonly startedAt: Date,
    public readonly validUntil: Date,
    public readonly items: SubscriptionItem[],
  ) {
  }
}
