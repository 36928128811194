import type { ICustomerApiClient } from '@/domain/stripe/customer/client/contract/ICustomerApiClient'
import type { ICustomerDto } from '@/domain/stripe/customer/client/dto/Dtos'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { StripeApiClient } from '@/infrastructure/api/stripe/StripeApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class CustomerApiClient extends StripeApiClient implements ICustomerApiClient {

  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
      httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  public async get(): Promise<ICustomerDto> {
    return this.httpClient.get(`${this.baseUrl}/customer`)
  }

  public async create(dto: ICustomerDto): Promise<ICustomerDto> {
    return this.httpClient.post(`${this.baseUrl}/customer`, dto)
  }

  public async update(dto: ICustomerDto): Promise<ICustomerDto> {
    return this.httpClient.put(`${this.baseUrl}/customer`, dto)
  }
}
