import { UnitAmountPresentation } from '@/domain/stripe/couponCode/presentation/UnitAmountPresentation'

export class CouponPresentation {

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly amountOff: UnitAmountPresentation,
  ) {
  }
}
