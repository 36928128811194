import { CouponCodePresentation } from '@/domain/stripe/couponCode/presentation/CouponCodePresentation'
import { trackActionExecutionProgress } from '@/infrastructure/store/ActionLoadingIndicator'
import type { ICouponCodeStore } from '@/infrastructure/store/modules/contract/shop/ICouponCode.store'
import { HandledAction } from '@/infrastructure/utils/extensions/vuex'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  name: 'ShopCouponCode',
  namespaced: true,
})
export default class CouponCodeStore extends VuexModule implements ICouponCodeStore {
  private _code = ''

  public get code(): string {
    return this._code
  }

  private _couponCode = CouponCodePresentation.EMPTY

  public get couponCode(): CouponCodePresentation {
    return this._couponCode
  }

  private _isApplyButtonDisabled = false

  public get isApplyButtonDisabled(): boolean {
    return this._isApplyButtonDisabled
  }

  private _actionInProgress = false

  public get actionInProgress(): boolean {
    return this._actionInProgress
  }

  @Mutation
  public updateCode(code: string): void {
    this._code = code
  }

  @Mutation
  public updateActionInProgress(value: boolean) {
    this._actionInProgress = value
  }

  @Mutation
  public updateCouponCode(couponCode: CouponCodePresentation): void {
    this._couponCode = couponCode
  }

  @Mutation
  public enableApplyButton(): void {
    this._isApplyButtonDisabled = false
  }

  @Mutation
  public disableApplyButton(): void {
    this._isApplyButtonDisabled = true
  }

  @HandledAction({ commit: 'updateCouponCode' })
  public async validateCouponCode<T>(callback: () => Promise<T | void>): Promise<T | void> {
    return trackActionExecutionProgress(callback, this.updateActionInProgress)
  }
}
