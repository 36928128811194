import type { IDownloadApiClient } from '@/domain/download/client/contract/IDownloadApiClient'
import type { IApplicationDownloadDto } from '@/domain/download/client/dto/Dtos'
import { ApiTokens } from '@/infrastructure/api/DITokens'
import type { IHttpClient } from '@/infrastructure/api/http/HttpClient'
import { VersionedApiClient } from '@/infrastructure/api/VersionedApiClient'
import { inject, injectable } from 'inversify'

@injectable()
export class DownloadApiClient extends VersionedApiClient implements IDownloadApiClient {

  constructor(
    @inject(ApiTokens.authenticatedHttpClient)
    protected readonly httpClient: IHttpClient,
  ) {
    super(httpClient)
  }

  protected get baseUrl(): string {
    return `${process.env.VUE_APP_BACKEND_SCHEME}://${process.env.VUE_APP_BACKEND_HOST}/v1/downloads`
  }

  public async getLatestVersion(softwareName: string): Promise<IApplicationDownloadDto> {
    return await this.httpClient.get(`${this.baseUrl}/${softwareName}`)
  }

  public async getAvailableApplications(): Promise<IApplicationDownloadDto[]> {
    return await this.httpClient.get(`${this.baseUrl}`)
  }
}
