import type { IProductDto } from '@/domain/stripe/customer/sub-domains/subscription/client/dto/Dtos'
import SubscriptionTokens from '@/domain/stripe/customer/sub-domains/subscription/DITokens'
import type { IProductMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/IProductMapper'
import type { IPriceMapper } from '@/domain/stripe/customer/sub-domains/subscription/mapper/contract/IPriceMapper'
import { Product } from '@/domain/stripe/customer/sub-domains/subscription/model/Product'
import { inject, injectable } from 'inversify'

@injectable()
export class ProductMapper implements IProductMapper {
  constructor(
    @inject(SubscriptionTokens.mapper.IProductPriceMapper)
    private readonly productPriceMapper: IPriceMapper,
  ) {
  }

  public toModel(dto: IProductDto): Product {
    return new Product(
      dto.id,
      dto.description,
      dto.name,
      this.productPriceMapper.toModel(dto.price),
    )
  }

  public toDto(model: Product): IProductDto {
    return {
      id: model.id,
      description: model.description,
      name: model.name,
      price: this.productPriceMapper.toDto(model.price),
    }
  }
}
